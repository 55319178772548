import { Tabs } from 'antd';
import FacilityQuarterlyReport from './reports/FacilityQuarterlyReport';
import FacilityAnalyticsReport from './reports/FacilityAnalytics';
import EmailLogs from './activities/EmailLogs';
import AuditLogs from './activities/AuditLogs';
import PaymentReports from './payments/PaymentReport';

//import './style.css';

export default function ReportingScreen(props) {
  const permissions = props.props.userPermission?.map((i) => {
    return i.toLowerCase();
  });

  const canViewActivityReports = permissions.includes('activity_reports');
  const canViewMonitoringReports = permissions.includes('monitoring_reports');
  const canViewPaymentReports = permissions.includes('payment_reports');

  const items1 = [
    {
      key: '1',
      label: 'Quarterly Report',
      children: <FacilityQuarterlyReport />,
    },
    {
      key: '2',
      label: 'Analytics Report',
      children: <FacilityAnalyticsReport />,
    },
  ];

  const items2 = [
    {
      key: '1',
      label: 'QA/QC Payments',
      children: <PaymentReports />,
    },
  ];

  const items3 = [
    {
      key: '1',
      label: 'Audit Log',
      children: <AuditLogs />,
    },
    {
      key: '2',
      label: 'Email log',
      children: <EmailLogs />,
    },
  ];

  const menus = [
    {
      key: '1',
      label: 'Monitoring Reports',
      children: <Tabs tabPosition="left" defaultActiveKey="1" items={items1} />,
      visible: canViewActivityReports,
    },
    {
      key: '2',
      label: 'Payment Reports',
      children: <Tabs tabPosition="left" defaultActiveKey="1" items={items2} />,
      visible: canViewMonitoringReports,
    },
    {
      key: '3',
      label: 'Activity Reports',
      children: <Tabs tabPosition="left" defaultActiveKey="1" items={items3} />,
      visible: canViewPaymentReports,
    },
  ];

  const topTabs = menus?.map((i) => {
    if (i.visible) {
      return i;
    }
  });

  return (
    <div style={{ border: '0px solid gray' }}>
      <div style={{ padding: '1rem 2rem' }}>
        {/* <Tabs tabPosition="left" defaultActiveKey="1" items={items} onChange={onChange} /> */}
        <Tabs tabPosition="top" defaultActiveKey="1" items={topTabs} centered />
      </div>
    </div>
  );
}
