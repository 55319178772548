import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GraphSimpleDonutChart from '../../../common/GraphSimpleDonutChart';
import { getDashboardFacilityCategory } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';

export default function CategoryAnalyticsRegulatorView({ stateId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [stateId]);

  const fetchData = () => {
    setLoading(true);
    getDashboardFacilityCategory('', stateId, '')
      .then((response) => {
        setLoading(false);
        const responseData = response.result.data;
        const m = responseData?.map((item) => ({
          name: item.categoryName,
          count: item.facilityCount,
        }));
        setData(m);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleDisplay = () => {
    if (loading) {
      return <LoaderSkeleton />;
    }

    if (!loading && data.length > 0) {
      return <GraphSimpleDonutChart data={data} />;
    }
  };
  return (
    <Card>
      <Header>
        <Title>
          <TitlePrimary>Industries by Category</TitlePrimary>
          <TitleSecondary>Percentage of industries according to category</TitleSecondary>
        </Title>
      </Header>
      {handleDisplay()}
      {/* <GraphSimplePieChart data={data} /> */}
    </Card>
  );
}

const Card = styled.div`
  border: 1px solid #c4c4c4;
  padding: 0.5rem;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
`;

const Title = styled.div`
  color: #898282;
  flex-grow: 1;
`;

const TitlePrimary = styled.div`
  color: #5f6368;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
`;

const TitleSecondary = styled.div`
  margin-top: -5px;
`;
