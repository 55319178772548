import React, { useContext } from 'react';
import styled from 'styled-components';
import NavUser from './NavUser';
import UserContextProvider from '../../store/UserContext';
import { AppContext } from '../../store/AppContext';

const DashboardHeader = ({ className, screenName, previousPath }) => {
  // console.log('window.liveUrl', window.LIVE_URL);
  // console.log('window base url', window.BASE_URL);

  const { state } = useContext(AppContext);
  const rolename = state?.auth?.result?.data?.roleName;
  //console.log(state?.auth?.result?.data?.roleName);

  const liveEnv = window.LIVE_URL === window.BASE_URL;

  return (
    <header className={className}>
      <h3 className="ScreenName">{screenName}</h3>
      <UserContextProvider>
        {liveEnv ? '' : <span className="environment">TEST ENVIRONMENT</span>}
        <div>{rolename}</div>
        <NavUser />
      </UserContextProvider>
    </header>
  );
};

export default styled(DashboardHeader)`
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(144, 164, 183, 0.16);
  // padding: 12px 32px;
  padding: 12px 32px 12px 32px;
  .ScreenName {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-family-bold);
    color: #2e384d;
  }
  .environment {
    text-transform: capitalize;
    width: 70%;
    text-align: right;
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-bold);
    color: var(--inactive-red);
  }
`;
