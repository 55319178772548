import React from 'react';
import { Modal } from 'antd';
import AddMeasurementForm from './AddMeasurementForm';
import '../style.css';

const AddMeasurementModal = ({ open, close, reloadMeasurements }) => {
  return (
    <Modal
      visible={open}
      onCancel={close}
      footer={false}
      title="Setup Measurement"
      className="add__measurement__modal"
    >
      <AddMeasurementForm closeModal={close} reload={reloadMeasurements} />
    </Modal>
  );
};

export default AddMeasurementModal;
