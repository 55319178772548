import React, { useState, useEffect } from 'react';
// import { Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';
import EmptyContent from '../../../components/common/EmptyContent';
import LoadingContent from '../../../components/common/LoadingContent';
import Button from '../../../components/common/Button';
// // import PageHeader from 'components/common/PageHeader';
import PageContent from '../../../components/common/PageContent';
import { ReactComponent as FacilitiesIcon } from '../../../assets/img/facilities.svg';
// // import SearchInput from 'components/common/SearchInput';

// import { openNotification } from 'helpers/notification';
import '../../../shared.css';
import '../facilities/index.css';

export default function Facilities({ props }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setLoadingTimer = setTimeout(() => setLoading(false), 1000);
    return () => {
      clearTimeout(setLoadingTimer);
    };
  }, [loading]);

  if (loading)
    return (
      <div className="spin-fullpage">
        <LoadingContent pageLoading={true} />
      </div>
    );

  function goHome() {
    props.history.push('/#/');
    // return <Redirect to="/" />;
  }

  return (
    <PageContent>
      <>
        <Row type="flex" justify="center" align="middle" className="text-center mt-2rem">
          <Col
            xs={{
              span: 16,
            }}
            lg={{
              span: 12,
            }}
          >
            <FacilitiesIcon className="icon" />
          </Col>
        </Row>

        <div>
          <Row type="flex" justify="center" align="middle" className="text-center">
            <Col
              xs={{
                span: 12,
              }}
              lg={{
                span: 8,
              }}
            >
              <EmptyContent className="no-content">
                <h1 className="no-content-header">Oops!</h1>
                <p className="no-content-text">Nothing is here</p>
                <div className="buttons">
                  <Button type="secondary" onClick={goHome}>
                    GO TO HOME
                  </Button>
                </div>
              </EmptyContent>
            </Col>
          </Row>
        </div>
      </>
    </PageContent>
  );
}
