import { Collapse, Divider, Pagination, Radio, Select, Skeleton, Space, Table } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useEffect, useState } from 'react';
import { getEdmDashboardViolations, getResultData } from '../../../../helpers/api';
import { parseString3, properCase } from '../../../../helpers/utils';
import LoaderSkeleton from '../../../common/LoaderSkeleton';

export default function ViolatorsEdmView() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limitType, setLimitType] = useState('Nesrea');
  const [defaultActive, setDefaultActive] = useState(data[0]?.key ?? '1');

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(3);

  

  const pageSize = 6;

  useEffect(() => {
    fetch();
  }, [limitType]);

  const fetch = () => {
    setLoading(true);
    getEdmDashboardViolations(limitType)
      .then((response) => {
        setLoading(false);
        const responseData = response.result.data;
        setData(responseData);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const onChangeLimitType = (e) => {
    setLimitType(e);
  };

  const renderNesreaTabularDisplay = (parameterItem) => {
    const columns = [
      {
        title: 'Parameter',
        key: 'parameterName',
        dataIndex: 'parameterName',
        render: (_, record) => {
          return (
            <div>
              <div>{record.parameterName}</div>
            </div>
          );
        },
      },
      {
        title: 'Unit',
        key: 'parameterUnit',
        dataIndex: 'parameterUnit',
        render: (_, record) => {
          return (
            <div>
              <div>{parseString3(record.parameterUnit)}</div>
            </div>
          );
        },
      },
      {
        title: 'Result (Normal) ',
        key: 'normal',
        dataIndex: 'normal',
        render: (_, record) => {
          return (
            <div>
              <div>{record.normal}</div>
            </div>
          );
        },
      },
      {
        title: 'Result (QAQC) ',
        key: 'qaqc',
        dataIndex: 'qaqc',
        render: (_, record) => {
          return (
            <div>
              <div>{record.qaqc}</div>
            </div>
          );
        },
      },
      {
        title: 'Approved Limit ',
        key: 'nesrea',
        dataIndex: 'nesrea',
        render: (_, record) => {
          return (
            <div>
              <div>{record.nesrea}</div>
            </div>
          );
        },
      },
    ];

    return <Table columns={columns} dataSource={parameterItem} pagination={false} size="small" />;
  };

  const renderWorldBankTabularDisplay = (parameterItem) => {
    const columns = [
      {
        title: 'Parameter',
        key: 'parameterName',
        dataIndex: 'parameterName',
        render: (_, record) => {
          return (
            <div>
              <div>{record.parameterName}</div>
            </div>
          );
        },
      },
      {
        title: 'Unit',
        key: 'parameterUnit',
        dataIndex: 'parameterUnit',
        render: (_, record) => {
          return (
            <div>
              <div>{parseString3(record.parameterUnit)}</div>
            </div>
          );
        },
      },
      {
        title: 'Result (Normal) ',
        key: 'normal',
        dataIndex: 'normal',
        render: (_, record) => {
          return (
            <div>
              <div>{record.normal}</div>
            </div>
          );
        },
      },
      {
        title: 'Result (QAQC) ',
        key: 'qaqc',
        dataIndex: 'qaqc',
        render: (_, record) => {
          return (
            <div>
              <div>{record.qaqc}</div>
            </div>
          );
        },
      },
      {
        title: 'Approved Limit ',
        key: 'worldBank',
        dataIndex: 'worldBank',
        render: (_, record) => {
          return (
            <div>
              <div>{record.worldBank}</div>
            </div>
          );
        },
      },
    ];

    return <Table columns={columns} dataSource={parameterItem} pagination={false} size="small" />;
  };

  const handleDisplay = () => {
    if (loading) {
      return <LoaderSkeleton />;
    }

    if (!loading && data.length > 0) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <div style={{ border: '0px solid gray', height: '400px', overflowY: 'scroll' }}>
        {data?.map((item) => (
          <div style={{ marginBottom: '5px', background: 'gray' }}>
            <Collapse
              accordion
              key={item.facilityId}
              style={violatorContent}
              defaultActiveKey={[data[0]?.key ?? '1']}
            >
              <CollapsePanel
                header={
                  <div style={violatorContentHeader}>
                    <div style={violatorContentTitle}>{item.facilityName}</div>
                    <div style={{ color: '#FA1010' }}>{item.violationCount} Violations</div>
                  </div>
                }
                key={item.key}
              >
                <div style={{ height: '200px', overflowY: 'scroll' }}>
                  {item.measurements.map((measurementItem) => (
                    <div>
                      <div style={{ border: '1px solid #E5E5E5' }}>
                        <div
                          key={measurementItem.measurementId}
                          style={{
                            backgroundColor: '#F2F2F2',
                            padding: '0.5rem 1rem',
                            border: '1px solid #E5E5E5',
                          }}
                        >
                          {properCase(measurementItem.measurementName)}
                        </div>
                        <div>
                          {limitType === 'Nesrea'
                            ? renderNesreaTabularDisplay(measurementItem.parameters)
                            : renderWorldBankTabularDisplay(measurementItem.parameters)}
                        </div>
                      </div>
                      <br />
                    </div>
                  ))}
                </div>
              </CollapsePanel>
            </Collapse>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={card}>
      <div style={header}>
        <div style={title}>
          <div style={titlePrimary}>Violations</div>
          <div style={titleSecondary}>Emission violations</div>
        </div>
        <div>
          <Space>
            <Radio.Group value={limitType} onChange={(e) => onChangeLimitType(e.target.value)}>
              <Radio.Button value="Nesrea">Nesrea</Radio.Button>
              <Radio.Button value="World">World Bank</Radio.Button>
            </Radio.Group>
          </Space>
        </div>
      </div>
      <br />
      <div>{handleDisplay()}</div>
      <br />
      <div>
        {/* <AppPagination
          defaultCurrent={pageNum}
          total={data?.length}
          onPageChange={(pageNumber) => {
            console.log(1);
          }}
        /> */}
        {/* <Pagination
          defaultCurrent={offset}
          total={data?.length}
          onChange={(pageNum) => {
            const lim = offset + pageNum;
            console.log('LIM', lim);

            const start = (offset + pageNum) * 3;
            //setOffset(start);
            console.log('START', start);

            const end = (pageNum + 1) * 3;
            //setLimit(end);
            console.log('END', end);

            // setOffset(offset * pageNum);
            // setLimit((prev) => prev * pageNum);
          }}
        /> */}
      </div>
    </div>
  );
}

const card = {
  border: '1px solid #c4c4c4',
  borderRadius: '10px',
  padding: '1rem',
  fontFamily: 'sans-serif',
  // height: '100%',
};

const header = {
  display: 'flex',
  alignItems: 'center',
};

const title = {
  color: '#898282',
  flexGrow: '1',
};

const titlePrimary = {
  color: '#5f6368',
  fontSize: '1.5em',
  fontWeight: 'bold',
};

const titleSecondary = {
  marginTop: '-5px',
};

const violatorContent = {
  backgroundColor: '#f2f2f2',
};

const violatorContentHeader = {
  display: 'flex',
  border: '0px solid gray',
  alignItems: 'center',
};

const violatorContentTitle = {
  display: 'flex',
  flexGrow: 1,
  border: '0px solid gray',
};

const results = [
  {
    key: '1',
    facilityName: 'Amber Prime Plastic Nigeria',
    facilityId: '1',
    ViolationCount: 10,
    measurements: [
      {
        measurementId: '1',
        measurementName: 'Ambient Air Quality',
        parameters: [
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
        ],
      },
      {
        measurementId: '1',
        measurementName: 'Ambient Noise Quality',
        parameters: [
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
        ],
      },
    ],
  },

  {
    key: '2',
    facilityName: 'Amber Prime Plastic Nigeria',
    facilityId: '2',
    ViolationCount: 20,
    measurements: [
      {
        measurementId: '1',
        measurementName: 'Ambient Air Quality',
        parameters: [
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
        ],
      },
      {
        measurementId: '1',
        measurementName: 'Ambient Noise Quality',
        parameters: [
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
        ],
      },
    ],
  },
  {
    key: '3',
    facilityName: 'Amber Prime Plastic Nigeria',
    facilityId: '3',
    ViolationCount: 6,
    measurements: [
      {
        measurementId: '1',
        measurementName: 'Ambient Air Quality',
        parameters: [
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
        ],
      },
      {
        measurementId: '1',
        measurementName: 'Ambient Noise Quality',
        parameters: [
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
          {
            parameterId: '1',
            parmeterName: 'C02',
            siUnit: 'mh',
            attainedLimit: '0.6',
            approvedLimit: '0.4',
          },
        ],
      },
    ],
  },
];
