import React, { useState } from 'react';
import { Form, message, Row, Col, InputNumber } from 'antd';
import Input from '../../../../components/common/AntInput';
import Button from '../../../../components/common/Button';
import { updateParameter } from '../../../../helpers/api';

const EditParamterForm = ({
  closeModal,
  unit,
  id,
  siUnit,
  //worldBank,
  //fmLimit,

  nesrea,
  world,
  nesreaLowerLimit,
  nesreaUpperLimit,
  worldLowerLimit,
  worldUpperLimit,

  reload,
}) => {
  const [loading, setLoading] = useState(false);
  const initValues = {
    unit,
    siUnit,
    world,
    nesrea,
    nesreaLowerLimit,
    nesreaUpperLimit,
    worldLowerLimit,
    worldUpperLimit,
  };


  const handleEditParameter = (values) => {
    setLoading(true);
    updateParameter(id, values)
      .then((res) => {
        message.success(`${name} parameter updated successfully!`);
        setLoading(false);
        reload();
        closeModal();
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };

  const handleContent = () => {
    if (!unit) return <div>Loading</div>;
    else return content();
  };

  const content = () => {
    return (
      <Form
        layout="vertical"
        hideRequiredMark={true}
        className="edit__parameter__form"
        initialValues={initValues}
        onFinish={handleEditParameter}
      >
        <Row gutter={10}>
          <Col lg={12}>
            <Form.Item
              name="unit"
              label="Parameter Name"
              rules={[{ required: true, message: 'Please enter Parameter name' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="siUnit"
              label="SI Unit"
              rules={[{ required: true, message: 'SI Unit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="nesrea"
              label="NESREA Limit"
              rules={[{ required: true, message: 'NESREA Limit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="world"
              label="World Bank Limit"
              rules={[{ required: true, message: 'World Bank limit is required' }]}
            >
               <Input />
            </Form.Item>
          </Col>

          {/* <Col lg={6}>
            <Form.Item
              name="nesreaLowerLimit"
              label="NESREA Limit (lower)"
              rules={[{ required: true, message: 'NESREA Lower limit is required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              name="nesreaUpperLimit"
              label="NESREA Limit (upper)"
              rules={[{ required: true, message: 'NESREA Upper limit is required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              name="worldLowerLimit"
              label="World Bank Limit (lower)"
              rules={[{ required: true, message: 'World Bank Lower limit is required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              name="worldUpperLimit"
              label="World Bank Limit (upper)"
              rules={[{ required: true, message: 'World Bank Upper limit is required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col> */}
        </Row>
        <Form.Item className="mt-20">
          <Button
            type="primary"
            appearance="default"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
          <Button type="secondary" onClick={closeModal} disabled={loading} className="ml-20">
            Cancel
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return handleContent();
};

export default EditParamterForm;
