import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../../../components/common/Layout';
import PageContainer from '../../../components/common/PageContainer';
import PageHeader from '../../../components/common/PageHeader';
import PageContent from '../../../components/common/PageContent';
import BreadCrumb from '../../../components/common/BreadCrumb';
import Button from '../../../components/common/Button';
import RoleList from './RoleList';
import { Row, Col } from 'antd';
import CreateRoleModal from './CreateRole/RoleModal';
import { ReactComponent as PlusIcon } from '../../../assets/img/plus.svg';

const UserRoles = ({ className }) => {
  const [RoleModal, setRoleModal] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const showModal = () => {
    setRoleModal(true);
    setMounted(true);
  };
  const closeModal = () => {
    setRoleModal(false);
    setMounted(false);
  };
  return (
    <Layout headerTitle="Settings">
      {isMounted ? (
        <CreateRoleModal open={RoleModal} close={closeModal} title="Create New Role" />
      ) : null}
      <PageContainer>
        <div style={{ marginBottom: '10px !important' }} className="fac-breadcrumb">
          <Row style={{ marginBottom: '10px !important' }}>
            <Col lg={{ span: 20 }} offset={1} style={{ marginBottom: '10px !important' }}>
              <BreadCrumb
                previousPath="/settings"
                currentView="User Roles"
                previousView="Settings"
              />
            </Col>
          </Row>
        </div>

        <PageHeader split={true} className="settings-header__main">
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
            type="flex"
            align="start"
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Col span={8} style={{ flexFlow: 'column' }}>
              <h3 className="heading mb-20" style={{ alignSelf: 'flex-start' }}>
                User Roles
              </h3>
              <p style={{ alignSelf: 'flex-start' }}>
                Roles define what permissions are granted to accounts.
              </p>
            </Col>
            <Col span={8} className="text-right">
              <Button appearance="default" type="primary" onClick={showModal}>
                <PlusIcon />
                New Role
              </Button>
            </Col>
          </Row>
        </PageHeader>

        <PageContent>
          <div className="main-page__body settings-roles">
            <Row
              gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
              type="flex"
              align="start"
              style={{ width: '100%' }}
            >
              <Col span={24}>
                <RoleList />
              </Col>
            </Row>
          </div>
        </PageContent>
      </PageContainer>
    </Layout>
  );
};

export default styled(UserRoles)`
  .heading {
    margin-top: 55px;
    font-family: var(--font-family);
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 1rem !important;
    }
    .text-right {
      text-align: right;
    }
    button {
      font-weight: 500;
    }
    p {
      color: #828ea9;
      font-size: 14px;
    }
  }
`;
