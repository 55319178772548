import { Col, Row, Select, Spin } from 'antd';
import ViolatorsEdmView from './edm/ViolatorsEdmView';
import PendingPaymentsEdmView from './edm/PendingPayentsEdmView';
import ComparativeAnalyticsEdmView from './edm/ComparativeAnalyticsEdmView';
import { useContext, useEffect, useState } from 'react';
import { getStateLookup, getStates } from '../../../helpers/api';
import { LoadingOutlined } from '@ant-design/icons';
import PageContent from '../../common/PageContent';
import { AppContext } from '../../../store/AppContext';
import StatCardsRegulatorView from './regulator/StatCardsRegulatorView';
import SectorAnalyticsRegulatorView from './regulator/SectorAnalyticsRegulatorView';
import CategoryAnalyticsRegulatorView from './regulator/CategoryAnalyticsRegulatorView';
import LgaSectorAnalyticsRegulatorView from './regulator/LgaSectorAnalyticsRegulatorView';

export default function RegulatorDashboard() {
  const { state } = useContext(AppContext);
  const loggedInFirstName = state?.auth?.result?.data?.firstname;
  const [stateDropdownData, setStateDropdownData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [lgaData, setLgaData] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState('');

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = () => {
    setLoadingState(true);
    getStateLookup()
      .then((response) => {
        setLoadingState(false);
        const responseData = response.result.data;
        const m = responseData?.map((item) => ({
          label: item.stateName,
          value: item.stateId,
        }));
        setStateData(responseData);
        setStateDropdownData(m);
        // setLgaData(stateData.lga);
      })
      .catch((error) => {
        setLoadingState(false);
        console.log(error);
      });
  };

  const handleChangeState = (stateId) => {
    if (stateId === undefined) setSelectedStateId('');
    else {
      const selectedState = stateData?.filter((x) => x.stateId === stateId)[0];
      const lga = selectedState?.lga;
      setSelectedStateId(stateId);
      setLgaData(lga);
    }
  };

  const renderStatesDropdown = () => {
    if (loadingState) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Loading States
        </div>
      );
    }

    if (!loadingState && stateDropdownData?.length > 0) {
      return (
        <Select
          showSearch
          allowClear
          placeholder="All States"
          options={stateDropdownData}
          style={{
            width: 200,
          }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={(stateId) => {
            handleChangeState(stateId);
          }}
        />
      );
    }
  };

  return (
    <PageContent>
      <div style={{ padding: '1rem 2rem' }}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              fontWeight: 'bolder',
              fontSize: '2em',
              color: '#5F6368',
            }}
          >
            Hi {loggedInFirstName}
          </div>
          <div>{renderStatesDropdown()}</div>
        </div>
        <br />
        <StatCardsRegulatorView stateId={selectedStateId} />
        <br />

        <Row gutter={20}>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <SectorAnalyticsRegulatorView stateId={selectedStateId} />
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <CategoryAnalyticsRegulatorView stateId={selectedStateId} />
          </Col>
        </Row>
        <br />
        <LgaSectorAnalyticsRegulatorView stateId={selectedStateId} lgaList={lgaData} />
        <br />
        <br />
      </div>
    </PageContent>
  );
}
