import React, { useState, useContext, useEffect } from 'react';
import { Layout, Row, Col, Collapse, Radio, Breadcrumb } from 'antd';
import LoadingContent from '../../../../../components/common/LoadingContent';
import ReportCompareTable from '../../../../../components/ReportCompareTable';
import { FacilityContext } from '../../context';
import { getQuarterWithData, getBiggestSamplePoint, extractCompareData } from '../../helpers';

const { Content } = Layout;
const { Panel } = Collapse;

const avgOptions = ['fmEnv', 'worldBank'];
const avgRadioOptions = [
  { label: 'FM Env', value: 'fmEnv' },
  { label: 'World Bank', value: 'worldBank' },
];

export default function index() {
  const context = useContext(FacilityContext);
  const {
    reports,
    setLoading,
    results,
    samplePoints,
    currentSamplePoint,
    currentParameters,
    currCompareResult,
    allParameters,
    compareParameters,
    avgCompareResult,
    allSamplePointRecordData,
  } = context;

  const [reportsLoading, setReportsLoading] = useState(false);
  const [reportsError, setReportsError] = useState(false);
  const [noReport, setNoReport] = useState(false);
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);
  // const [currLocalParameters, setCurrLocalParameters] = useState('');
  const [currLocalSamplePoint, setCurrLocalSamplePoint] = useState(currentSamplePoint);
  const [avgLocalCompareResult, setAvgLocalCompareResult] = useState(avgCompareResult);
  const [currLocalCompareResult, setCurrLocalCompareResult] = useState(currCompareResult);
  const [compareLocalParameters, setCompareLocalParameters] = useState(currCompareResult);
  // const [currLocalSamplePointRecordData, setCurrLocalSamplePointRecordData] = useState(
  //   currentSamplePointRecordData
  // );

  const biggestSamplePoint = getBiggestSamplePoint(samplePoints);
  let biggestSamplePointArray = [];
  if (biggestSamplePoint && Object.keys(biggestSamplePoint))
    biggestSamplePointArray = biggestSamplePoint[Object.keys(biggestSamplePoint)[0]];

  useEffect(() => {
    try {
      if (!currLocalCompareResult) setReportsLoading(true);
      let qtrWithData = getQuarterWithData(
        allSamplePointRecordData[currLocalSamplePoint.samplePointId]
      );
      // currLocalSamplePointRecordData
      // if (samplePoints && samplePoints[0]) {
      //   let keyr;
      //   keyr = Object.keys(samplePoints[0])[0];
      //   getNextCompareData(samplePoints[0][keyr][0].samplePointId);
      // }

      if (qtrWithData) {
        if (samplePoints && samplePoints.length >= qtrWithData.length) {
          getNextCompareData(currLocalSamplePoint.samplePointId);
        }
      } else if (!qtrWithData) {
        // setNoReport(true);
      }
      setReportsLoading(false);
    } catch (err) {
      console.log('the error', err);
      setReportsLoading(false);
      setReportsError({ msg: err });
    }
    return;
  }, [currLocalSamplePoint.samplePointId]);

  console.log('currLocalSamplePoint', currLocalSamplePoint);
  console.log('avgLocalCompareResult', avgLocalCompareResult);
  console.log('currLocalCompareResult', currLocalCompareResult);
  console.log('compareLocalParameters', compareLocalParameters);
  // console.log('currLocalSamplePointRecordData', currLocalSamplePointRecordData);

  function getNextCompareData(samplePtId = currLocalSamplePoint.samplePointId, field = 'all') {
    try {
      let result = extractCompareData({ samplePtId, field, results });
      if (result && result.avgData) {
        setAvgLocalCompareResult(result.avgData);
        setCurrLocalCompareResult(result.tabledata);

        const paramKeys = Object.keys(allParameters[samplePtId]);
        let matchingParams = [];
        if (paramKeys && paramKeys.length > 0) {
          matchingParams = allParameters[samplePtId][paramKeys[0]];
        }
        setCompareLocalParameters(matchingParams);
      } else {
        setCompareLocalParameters('');
        setCurrLocalCompareResult([]);
      }
    } catch (err) {
      console.log('error in generating compare data', err);
      return;
    }
  }

  const renderNo = (something) => {
    let returning = `Oops`;
    switch (something) {
      case 'report':
        returning += ', No report generated for this facility yet!';
        break;

      default:
        returning += ', Nothing to show.';
        break;
    }
    return <p>{returning}</p>;
  };

  const changeAvgEnv = (e) => {
    setAvgEnv(e.target.value);
  };

  if (noReport) {
    return (
      <Content style={{ margin: '0 16px 0', overflow: 'initial' }} key="1">
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
          key="1"
        >
          {renderNo('report')}
        </div>
      </Content>
    );
  }

  function panelContent(index) {
    // const { overallAverage, overallAverage_QAQC } = records.data.data;
    const content = [];

    if (!compareLocalParameters || compareLocalParameters.length === 0)
      return (
        <Col key={`${index}-${index}-0`}>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{ marginTop: '0.3rem', textAlign: 'left' }}
            type="flex"
            justify="start"
            align="middle"
          >
            <LoadingContent modal={true} />
          </Row>
        </Col>
      );

    const newCol = (
      <Row
        key={`${index}-${index}-0`}
        gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
        type="flex"
        justify="space-between"
        align="middle"
        style={{ flexWrap: 'nowrap' }}
      >
        <Col className="comparison-table">
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{ marginTop: '0.24rem', textAlign: 'left' }}
            type="flex"
            justify="start"
            align="middle"
          >
            <ReportCompareTable
              data={currLocalCompareResult}
              setData={() => {}}
              parameters={compareLocalParameters}
              published={true}
              loading={!currLocalCompareResult}
              type="facilityView"
              avg={{
                qaqcAvg: avgLocalCompareResult ? avgLocalCompareResult[0][avgEnv] : null,
                conAvg: avgLocalCompareResult ? avgLocalCompareResult[0][avgEnv] : null,
              }}
            />
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}
            style={{
              marginTop: '0.4rem',
              textAlign: 'left',
            }}
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col style={{ alignSelf: 'start', paddingLeft: '0' }}>
              <span className="qaqc-underneath">QA/QC Report</span>
              <span className="cons-underneath">Consultant's Report</span>
            </Col>
            <Col style={{ alignSelf: 'end', paddingRight: '0' }}>
              <span className="average-underneath-b">Below Average</span>
              <span className="average-underneath-a">Above Average</span>
            </Col>
          </Row>
        </Col>
        <Col span={1} className="switch-average-type">
          <p style={{ overflow: 'ellipsis' }}>Benchmark with</p>
          <Radio.Group options={avgRadioOptions} onChange={changeAvgEnv} value={avgEnv} />
        </Col>
      </Row>
    );

    content.push(newCol);

    return content;
  }

  function changeCurrentSamplePoint(sptId) {
    if (biggestSamplePointArray && biggestSamplePointArray.length > 0) {
      const matchingSpt = biggestSamplePointArray.filter((bgSpt) => bgSpt.samplePointId === sptId);
      if (matchingSpt && matchingSpt.length > 0) {
        setCurrLocalSamplePoint(matchingSpt[0]);
      }
    }
  }

  function nextSptCompare(spts, index) {
    if (index !== 0 && !index) return;
    const theSptId = spts[index].samplePointId;
    changeCurrentSamplePoint(theSptId);
    // getNextCompareData(theSptId);
  }

  return (
    <Content style={{ margin: '0 16px 0', overflow: 'initial' }} key="1">
      <div
        className="site-layout-background facility-view__reports"
        style={{ padding: 24, textAlign: 'center' }}
        key="1"
      >
        {reportsLoading && !reportsError && <LoadingContent modal={true} />}
        {reportsError && !reportsLoading && <p> Something went wrong</p>}
        {!reportsError && !reportsLoading && (
          <>
            <Row
              gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
              type="flex"
              justify="start"
              align="middle"
            >
              <Col>
                <Collapse
                  accordion
                  bordered={false}
                  defaultActiveKey={['0']}
                  onChange={(index) => nextSptCompare(biggestSamplePointArray, index)}
                  className="compare-accordion facility-view__accordion"
                  expandIconPosition="right"
                >
                  {biggestSamplePointArray &&
                    biggestSamplePointArray.length > 0 &&
                    biggestSamplePointArray.map((spt, index) => (
                      <Panel
                        header={
                          <span>
                            {spt.samplePointName}{' '}
                            <p
                              style={{
                                display: 'inline',
                                fontWeight: 'normal',
                                textOverflow: 'ellipsis',
                              }}
                            >{`(${spt.measurementName})`}</p>
                          </span>
                        }
                        key={`${index}`}
                      >
                        {panelContent(index)}
                      </Panel>
                    ))}
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Content>
  );
}
