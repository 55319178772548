import React, { useState, useEffect } from 'react';
import { Row, Col, Popconfirm, message, Breadcrumb } from 'antd';
import {
  CloseCircleOutlined,
  LoadingOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { getLga, getStates, createLga, deleteLga, searchLga } from '../../../../../helpers/api';
import { Link } from 'react-router-dom';
import Layout from '../../../../../components/common/Layout';
import Table from '../../../../../components/common/Table';
import Button from '../../../../../components/common/Button';
import Select from '../../../../../components/common/Select';
import LoadingContent from '../../../../../components/common/LoadingContent';
import PageHeader from '../../../../../components/common/PageHeader';
import PageContent from '../../../../../components/common/PageContent';
import EditLgaModal from './EditLga';
import { openNotification } from '../../../../../helpers/notification';
import { convertDMS } from '../../../../../helpers/utils';

import './lga.css';
import AddLgaModal from './AddLga';

const { Option } = Select;

const LGA = ({ className }) => {
  const [lgList, setLgList] = useState(null);
  const [statesList, setStatesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLgModal, setAddLgModal] = useState(false);
  const [stateFilter, setStateFilter] = useState('');

  const [reload, setReload] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState('');
  const [selectedState, setselectedState] = useState('');
  const [stateName, setStateName] = useState('');
  const [stateId, setStateId] = useState('');
  const [selectedLgId, setSelectedLgId] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [lgaName, setLgaName] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    refreshLg();
    getStateOptions();
  }, []);

  useEffect(() => {
    if (reload) {
      refreshLg();
    }
  }, [reload]);

  // FETCH LGA DATA
  const refreshLg = () => {
    setLoading(true);
    getLga()
      .then((res) => {
        setLgList(res.result.data);
        setLoading(false);
        setReload(false);
      })
      .catch((error) => {
        setLoading(false);
        setReload(false);
        return openNotification({
          type: 'error',
          title: 'Error getting local govt.',
          message: error,
        });
      });
  };

  // CLOSE CREATE LGA MODAL
  const closeLgModal = () => {
    setAddLgModal(false);
  };
  // CLOSE EDIT LGA MODAL
  const closeEditModal = () => {
    setEditModalVisible(false);
  };
  const reloadLga = () => {
    setReload(true);
  };

  // FETCH LIST OF ALL STATES
  const getStateOptions = () => {
    getStates()
      .then((res) => {
        setStatesList(res.result.data);
      })
      .catch((error) => {
        return openNotification({
          type: 'error',
          title: 'Error getting states',
          message: error.message,
        });
      });
  };

  const getEditableLgaStateData = (value) => {
    const filteredState = statesList.filter(
      (state) => state.stateName.trim().toLowerCase() === value.trim().toLowerCase()
    )[0];
    if (filteredState) {
      setSelectedStateId(filteredState.stateId);
    }
  };

  const preFetchLgaData = (id) => {
    const lgaData = lgList.filter((lga) => lga.lgaId === id)[0];
    if (lgaData) {
      console.log(lgaData.stateName);
      getEditableLgaStateData(lgaData.stateName);
      setStateName(lgaData.stateName);
      setStateId(lgaData.stateId);
      setLgaName(lgaData.lgaName);
      setSelectedLgId(lgaData.lgaId);
    }
  };

  const editLGA = (ev) => {
    setEditModalVisible(true);
    preFetchLgaData(ev.target.id);
  };

  // CONFIRM DELETE SELECTED LGA
  const confirmDeleteLga = (e, id) => {
    deleteLGA(id);
  };
  // HANDLE DELETE SELECTED LGA
  const deleteLGA = (lgaId) => {
    deleteLga(lgaId)
      .then((res) => {
        // message.success('Delete Successful!');
        setReload(true);
        return openNotification({
          type: 'success',
          title: 'Delete Successful!',
          message: 'Local Government deleted Successfully!',
        });
      })
      .catch((error) => {
        // message.error(error);
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error,
        });
      });
  };

  const columns = [
    {
      title: 'Local Government Name',
      dataIndex: 'lgaName',
      key: 'lgaName',
    },
    {
      title: 'State',
      dataIndex: 'stateName',
      key: 'stateName',
    },
    {
      title: 'Coordinates',
      dataIndex: 'coordinates',
      render: (records) => (
        <span>
          {records && records.latitude && records.longitude
            ? convertDMS(records.latitude, records.longitude)
            : ''}
        </span>
      ),
    },

    {
      title: '',
      dataIndex: 'lgaId',
      key: 'lgaId',
      render: (lgaId) => (
        <>
          <Button className="transparent__bg" id={lgaId} onClick={editLGA}>
            <EditOutlined /> Edit
          </Button>

          <Popconfirm
            title="Are you sure delete this local government ?"
            onConfirm={(e) => confirmDeleteLga(e, lgaId)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="text-danger">
              <CloseCircleOutlined /> Delete
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const displayStateOptions = () => {
    if (statesList.length > 0) {
      return statesList.map((states) => {
        return (
          <Option key={states.stateId} value={states.stateId}>
            {states.stateName}
          </Option>
        );
      });
    }
    return null;
  };

  // GET FILTER VALUE
  const handleSearch = (ev, id) => {
    setStateFilter(ev);
    filterLga(ev);
  };

  // FILTER LGA LIST USING FILTER TERM
  const filterLga = (filterVal) => {
    const filteredLga = lgList.filter((lga) => lga.stateName === filterVal);
    if (filteredLga) {
      setSearchResults(filteredLga);
    }
  };

  const keyedData = lgList;
  keyedData && keyedData.length && keyedData.forEach((dt) => (dt['key'] = dt.lgaName));

  return (
    <Layout headerTitle="Local Government">
      <div className="breadcrumb__container ml-2rem mt-1rem">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/settings">Settings</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/settings/lookups">Lookups</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>State Lookup</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {addLgModal && (
        <AddLgaModal
          open={addLgModal}
          close={closeLgModal}
          stateList={statesList}
          reloadLga={reloadLga}
        />
      )}
      {editModalVisible && (
        <EditLgaModal
          open={editModalVisible}
          close={closeEditModal}
          lgaName={lgaName}
          stateName={stateName}
          lgId={selectedLgId}
          stateId={stateId}
          stateList={statesList}
          reloadLga={reloadLga}
        />
      )}
      <PageHeader split={true} className="no-padding-x">
        <Row
          // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="start"
          justify="space-between"
          className="pl-2rem header-span100"
          style={{ width: '100%' }}
        >
          <Col span={20} style={{ flexFlow: 'column' }}>
            <Row className="mb-1rem">
              <Col span={20} style={{ flexFlow: 'column' }}>
                <h3 className="heading mb-20" style={{ alignSelf: 'flex-start' }}>
                  Local Government Lookups
                </h3>
                <p style={{ alignSelf: 'flex-start' }}>
                  This lookup contains all the local government areas that are currently registered
                  in the system.
                </p>
                <p style={{ alignSelf: 'flex-start' }}>
                  You can edit, delete and create new local government areas.
                </p>
              </Col>
            </Row>
            <Row
              type="flex"
              align="start"
              justify="space-between"
              // style={{ width: '100%' }}
            >
              <Col span={4} className="text-left">
                <Button
                  appearance="default"
                  type="primary"
                  style={{ marginLeft: '0px !important' }}
                  onClick={() => setAddLgModal(true)}
                  nomargin
                >
                  <PlusOutlined />
                  New LGA
                </Button>
              </Col>
              <Col lg={6}>
                <Row type="flex" justify="end">
                  <Col lg={10}>
                    <h3>Filter By:</h3>
                  </Col>
                  <Col lg={14}>
                    <Select
                      showSearch
                      placeholder="Select a state"
                      optionFilterProp="children"
                      onChange={handleSearch}
                      style={{ width: '100%' }}
                    >
                      <Option value="all">-- ALL STATES --</Option>
                      {statesList.map((states) => {
                        return (
                          <Option key={states.stateId} value={states.stateName}>
                            {states.stateName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeader>

      <PageContent>
        <Row>
          <Col md={20} lg={{ span: 20 }}>
            <div className="lga__lookup">
              {loading ? (
                <LoadingContent pageLoading={true} />
              ) : (
                <div>
                  <Table
                    dataSource={
                      stateFilter !== '' && stateFilter !== 'all' ? searchResults : keyedData
                    }
                    columns={columns}
                    pagination={true}
                    rowClassName="lga__lookup__row"
                    paddedTable={true}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </PageContent>
    </Layout>
  );
};

export default LGA;
