import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Row, Col, Tabs } from 'antd';
import { PlusOutlined, MoreOutlined, IeSquareFilled } from '@ant-design/icons';
import Table from '../../../components/common/Table';
import Menu from '../../../components/common/Menu';
import Select from '../../../components/common/Select';
import Dropdown from '../../../components/common/Dropdown';
import CreateUserModal from './CreateUserModal';
import Button from '../../common/Button';
import * as moment from 'moment';
import PageHeader from '../../../components/common/PageHeader';
import PageContent from '../../../components/common/PageContent';
import { getPendingUsers, getUsers } from '../../../helpers/api';
import { properCase } from '../../../helpers/utils';
import { openNotification } from '../../../helpers/notification';
import UserContextProvider from '../../../store/UserContext';
import { AppContext } from '../../../store/AppContext';

import SearchInput from '../../common/SearchInput';

import UpdateUser from './UpdateUser/UpdateUser';
import './Users.css';

const { TabPane } = Tabs;
const { Option } = Select;

const tableStyle = {
  marginBottom: '0.8rem',
  padding: '24px 30px',
  marginTop: '-1.5rem',
};

const Users = ({ className, users, refetchUsersData }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [pendingUsers, setPendingUsers] = useState(null);
  const [searchResults, setSearchResults] = useState(users);
  const [updateModalVisible, setupdateModalVisible] = useState(false);
  const [selectedUserId, setselectedUserId] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserEmail, setselectedUserEmail] = useState('');
  const [selectedUserCategory, setselectedUserCategory] = useState('');
  const [selecteduserRole, setselecteduserRole] = useState('');
  const [selectedAreaPermissons, setselectedAreaPermissons] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [search, setSearch] = useState([]);

  const { state } = useContext(AppContext);

  const { userPermission, userCategory } = state.auth.result.data;

  const userPerm = userPermission?.flatMap((permission) =>
    permission.permissionActivity?.map((activity) => activity.privilegeCode.toLowerCase())
  );

  const canCreateUser = userPerm.includes('user_invite');

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  // useEffect(() => {
  //   if (selectedUserId) {
  //   }
  // }, [selectedUserId]);

  const openModal = () => {
    setvisible(true);
  };

  const closeModal = () => {
    setvisible(false);
  };

  const pendingColumn = [
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Date Created',
      dataIndex: 'entryDate',
      key: 'entryDate',
      render: (entryDate) => <span>{moment(entryDate.split('T')[0]).format('Do-MMM-YYYY')}</span>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => <span className="user__role">{role.toLowerCase()}</span>,
      onFilter: (value, record) => (roleFilter ? record.role === roleFilter : record),
      filterDropdownVisible: true,
      filteredValue: [roleFilter],
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'inviteStatus',
      render: (inviteStatus) => (
        <span className="pending__status">{inviteStatus.toLowerCase()}</span>
      ),
    },
  ];

  const handleChange = (key) => {
    console.log(key);
  };

  const fetchPendingUsers = () => {
    getPendingUsers()
      .then((res) => {
        if (res.result.status.code === '03') {
          setPendingUsers(res.result.data);
        } else {
          setPendingUsers(res.result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showUpdateUserModal = (selUserId = selectedUserId) => {
    setupdateModalVisible(true);
    const userData = users.filter((user) => user.userId === selUserId);
    setSelectedUserData(userData);

    if (userData && userData.length > 0) {
      setSelectedUserName(userData[0].username);
      setselectedUserEmail(userData[0].email);
      setselecteduserRole(userData[0].role);
      setselectedUserCategory(userData[0].userCategory);
      setselectedAreaPermissons(userData[0].userAreaPermissions);
    }
  };

  const closeUpdateUserModal = () => {
    setupdateModalVisible(false);
  };

  const menu = (
    <Menu className="user-overflow__menu">
      <Menu.Item
        key="0"
        onClick={() => {
          showUpdateUserModal();
        }}
      >
        User Profile
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Reg No',
      dataIndex: 'userCode',
      key: 'userCode',
      ellipsis: true,
      width: '80px',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'firstname',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      width: '15%',
      ellipsis: true,
    },
    {
      title: 'Date Created',
      dataIndex: 'entryDate',
      key: 'entryDate',
      width: '15%',
      ellipsis: true,
      render: (entryDate) => <span>{moment(entryDate.split('T')[0]).format('Do-MMM-YYYY')}</span>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '15%',
      ellipsis: true,
      render: (role) => <span>{role.toLowerCase()}</span>,
      onFilter: (_, record) => (roleFilter ? record.role === roleFilter : record),
      filterDropdownVisible: true,
      filteredValue: [roleFilter],
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      ellipsis: true,
      render: (status) =>
        status === 'ACTIVE' ? (
          <span className="active__status">{properCase(status)}</span>
        ) : (
          <span className="pending__status">{properCase(status)}</span>
        ),
    },
    {
      title: '',
      dataIndex: 'userId',
      key: 'userId',
      fixed: 'right',
      width: '5%',
      // ellipsis: true,
      render: (userId) => {
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <a
              className="ant-dropdown-link update__action"
              type="button"
              href="/#"
              id={userId}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setselectedUserId(userId);
              }}
              onMouseEnter={() => setselectedUserId(userId)}
            >
              <MoreOutlined className="icon-more-outline" id={userId} />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const searchUsersTable = (ev) => {
    setLoading(true);
    const searchValue = ev;
    const max = 100000;
    const params = `?search=${searchValue}&Start=0&Stop=${max}`;
    getUsers(params)
      .then((res) => {
        setSearchResults(res.result.data);
      })
      .catch((error) => {
        if (error == undefined) {
          setSearchResults(error);
        }
      });
  };

  const displayPendingUsers = () => {
    if (pendingUsers === null) {
      return null;
    } else {
      if (pendingUsers.length === 0) {
        return (
          <Table
            dataSource={[]}
            columns={pendingColumn}
            paddedTable={true}
            pagination={true}
            rowClassName={'user-table__rows'}
          />
        );
      } else {
        const keyedData = pendingUsers;
        keyedData.forEach((dt) => (dt['key'] = dt.email));
        return (
          <Table
            dataSource={keyedData}
            columns={pendingColumn}
            pagination={true}
            paddedTable={true}
            className="pending__users"
            rowClassName={'user-table__rows'}
          />
        );
      }
    }
  };

  const displaySearchResults = () => {
    if (searchResults === undefined) {
      return (
        <Table
          dataSource={[]}
          columns={columns}
          paddedTable={true}
          pagination={false}
          // style={tableStyle}
          rowClassName={'user-table__rows'}
        />
      );
    } else {
      const keyedData = searchResults;
      keyedData.forEach((dt) => (dt['key'] = dt.userCode));
      return (
        <Table
          dataSource={keyedData}
          paddedTable={true}
          columns={columns}
          pagination={true}
          // style={tableStyle}
          rowClassName={'user-table__rows'}
        />
      );
    }
  };

  const filterOnChange = (val, which) => {
    switch (which) {
      case 'role':
        setRoleFilter(String(val).trim());
        break;
      default:
        break;
    }
  };

  const filterOnSearch = (val, which) => {
    switch (which) {
      case 'role':
        setRoleFilter(String(val).trim());
        break;
      default:
        break;
    }
  };

  const displayOptions = (list, which) => {
    let key = list;
    let { [key]: name } = { users };

    if (name && name.length > 0) {
      const uniques = [...new Set(name.map((item) => item[which]))];

      uniques.sort((a, b) => {
        if (typeof a !== 'object') return a > b ? 1 : b > a ? -1 : 0;
        return a[which].localeCompare(b[which]);
      });

      return uniques.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      });
    }
  };
  const keyedUsersData = users;
  keyedUsersData.forEach((dt) => (dt['key'] = dt.userCode));
  return (
    <div className={className}>
      <UserContextProvider>
        <CreateUserModal
          open={visible}
          closeCreateUserModal={() => setvisible(false)}
          refetch={refetchUsersData}
          close={closeModal}
          className="user-create__modal"
        />
        {updateModalVisible && (
          <UpdateUser
            open={updateModalVisible}
            close={closeUpdateUserModal}
            name={selectedUserName}
            userId={selectedUserId}
            email={selectedUserEmail}
            userCategory={selectedUserCategory}
            areaPermit={selectedAreaPermissons}
            users={users}
            userData={selectedUserData}
          />
        )}
      </UserContextProvider>
      <PageHeader className="user__header__main">
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="end"
          justify="end"
          style={{ width: '100%' }}
        >
          <Col style={{ align: 'start', justify: 'start', flex: 'auto' }}>
            {canCreateUser && (
              <div className="buttons left create-user-btn">
                <Button appearance="blue" size="small" onClick={openModal}>
                  <PlusOutlined /> New User
                </Button>
              </div>
            )}
          </Col>
          <Col>
            <Select
              showSearch
              placeholder="Filter by Role"
              style={{ width: '12.8em' }}
              optionFilterProp="children"
              onChange={(value) => filterOnChange(value, 'role')}
              onSearch={(value) => filterOnSearch(value, 'role')}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">All Roles</Option>
              {displayOptions('users', 'role')}
            </Select>
            <SearchInput placeholder="Search Users" searchTable={searchUsersTable} />
          </Col>
        </Row>
      </PageHeader>
      <PageContent>
        <div className="users-table">
          {loading ? (
            displaySearchResults()
          ) : (
            <Tabs
              defaultActiveKey="1"
              onchange={handleChange}
              className="users__tab"
              style={tableStyle}
            >
              <TabPane tab="Active " key="1">
                <div className="table-responsive">
                  <Table
                    dataSource={searchResults}
                    columns={columns}
                    paddedTable={true}
                    pagination={true}
                    className="all__users__table"
                    rowClassName={'user-table__rows'}
                  />
                </div>
              </TabPane>
              <TabPane tab="Pending " key="2">
                <div className="pending__users">{displayPendingUsers()}</div>
              </TabPane>
            </Tabs>
          )}
        </div>
      </PageContent>
    </div>
  );
};

export default styled(Users)`
  .text-right {
    text-align: right;
  }

  .user__role {
    text-transform: capitalize;
  }
`;
