import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import LoadingContent from '../components/common/LoadingContent';
import Layout from '../components/common/Layout';
import PageContainer from '../components/common/PageContainer';
import PageContent from '../components/common/PageContent';
import EmptyContent from '../components/common/EmptyContent';
import { ReactComponent as UsersIcon } from '../assets/img/users.svg';
import Button from '../components/common/Button';
import PageHeader from '../components/common/PageHeader';
import SearchInput from '../components/common/SearchInput';
import CreateUserModal from '../components/screens/users/CreateUserModal';
import { ReactComponent as PlusIcon } from '../assets/img/plus.svg';
import UsersList from '../components/screens/users/UsersList';
import { getUsers } from '../helpers/api';
import UserContextProvider from '../store/UserContext';
import '../components/screens/users/Users.css';

export default (props) => {
  const [visible, setVisible] = useState(false);
  const [Users, setUsers] = useState<any>(null);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchResults, setsearchResults] = useState(null);

  const { userPermission } = props;
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];
  const canCreateUser = userPerm.includes('user_invite');

  useEffect(() => {
    refreshUsers();
  }, []);

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  const refreshUsers = async () => {
    setLoading(true);
    getUsers()
      .then((res) => {
        if (res.result.status.code === '00') {
          setLoading(false);
          setUsers(res.result.data);
        } else {
          setLoading(true);
        }
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
        setError(true);
        // return openNotification({
        //   type: 'error',
        //   title: 'Error getting users',
        //   message: error,
        // });
      });
  };

  function refreshPage() {
    window.location.reload();
  }
  function refetchUsersData() {
    refreshUsers();
  }

  const displayUserTable = () => {
    if (!error && Users == null) {
      return null;
    } else if (!error && Users?.length === 0) {
      return (
        <div className="empty-state">
          <PageHeader>
            <div className="left">
              <SearchInput placeholder="Search Users" className='' searchTable={""}/>
            </div>
          </PageHeader>
          <PageContent>
            <Row type="flex" justify="center" align="middle" className="text-center">
              <Col xs={{ span: 16 }} lg={{ span: 12 }}>
                <UsersIcon className="icon" />
              </Col>
            </Row>

            <div>
              <Row type="flex" justify="center" align="middle" className="text-center">
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <EmptyContent className="no-content">
                    <h1 className="no-content-header">You have no users yet !</h1>
                    <p className="no-content-text">
                      There are currently no users in the IEMS platform. Click the button below to
                      start creating new users.
                    </p>
                    <div className="buttons">
                      {canCreateUser && (
                        <Button appearance="blue" size="small" onClick={openModal}>
                          <PlusIcon /> New User
                        </Button>
                      )}
                    </div>
                  </EmptyContent>
                </Col>
              </Row>
            </div>
          </PageContent>
        </div>
      );
    } else if (!error && Users.length > 0) {
      return <UsersList users={Users} refetchUsersData={refetchUsersData} />;
    }
  };
  return (
    <Layout headerTitle="users">
      <PageContainer>
        <UserContextProvider>
          <CreateUserModal open={visible} close={closeModal} />
        </UserContextProvider>
        {!error && Loading ? (
          <div className="spin-fullpage">
            <LoadingContent pageLoading={true} />
          </div>
        ) : (
          displayUserTable()
        )}
        {error && (
          <>
            <PageHeader split={true} className="user-header__main" />
            <PageContent>
              <Row type="flex" justify="center" align="middle" className="text-center">
                <Col
                  xs={{
                    span: 16,
                  }}
                  lg={{
                    span: 12,
                  }}
                >
                  <UsersIcon
                    className="icon"
                    style={{ width: '240px', height: '240px', opacity: '0.2' }}
                  />
                </Col>
              </Row>

              <div>
                <Row type="flex" justify="center" align="middle" className="text-center">
                  <Col
                    xs={{
                      span: 12,
                    }}
                    lg={{
                      span: 8,
                    }}
                  >
                    <EmptyContent className="no-content">
                      <h1 className="no-content-header">Something's wrong!</h1>
                      <p className="no-content-text">We are having issues loading users</p>
                      <div className="buttons">
                        <Button onClick={refreshPage} type="secondary">
                          TRY AGAIN
                        </Button>
                      </div>
                    </EmptyContent>
                  </Col>
                </Row>
              </div>
            </PageContent>
          </>
        )}
      </PageContainer>
    </Layout>
  );
};
