import { format, getQuarter } from 'date-fns';
import parseString from 'react-html-parser';

const formatDate = format;

const avgCalc = (nums) => {
  return nums.reduce((a, b) => a + b) / nums.length;
};

const mergeArrays = (arrays) => {
  let merge = [];
  if (arrays && arrays.length > 0) {
    arrays.map((arr) => {
      merge = [...merge, ...arr];
    });
  }
  return [...new Set(merge)];
};

const getErrorDesc = (err) => {
  if (!err) return false;
  if (typeof err === 'string') return err;
  else {
    if (err && err.result && err.result.status && err.result.status.desc) {
      let errDesc = err.result.status.desc;
      if (typeof errDesc === 'string') return errDesc;
    }
    return false;
  }
};

const datelize = (ddd) => {
  let resp;
  switch (Number(ddd) || 1) {
    case 1:
      resp = '1st';
      break;
    case 2:
      resp = '2nd';
      break;
    case 3:
      resp = '3rd';
      break;
    default:
      resp = `${ddd}th`;
      break;
  }
  return resp;
};

const parseString2 = (str) => {
  let returnVal;
  switch (str) {
    case '&deg;C':
      returnVal = '°C';
      break;
    case 'O<sub>2<sub>':
      returnVal = 'O₂';
      break;
    case 'O<sub>3<sub>':
      returnVal = 'O₃';
      break;
    case 'CO<sub>2<sub>':
      returnVal = 'CO₂';
      break;
    case 'NO<sub>2<sub>':
      returnVal = 'NO₂';
      break;
    case 'SO<sub>2<sub>':
      returnVal = 'SO₂';
      break;
    case 'NO<sub>X<sub>':
      returnVal = 'NOx';
      break;
    case 'H<sub>2<sub>S':
      returnVal = 'H₂S';
      break;
    case 'H<sub>2</sub>S':
      returnVal = 'H₂S';
      break;
    case 'NH<sub>3<sub>':
      returnVal = 'NH₃';
      break;

    default:
      returnVal = str;
      break;
  }
  return returnVal;
};

const parseString3 = (str) => {
  let odds = [
    '&deg;C',
    'O<sub>2<sub>',
    'O<sub>3<sub>',
    'CO<sub>2<sub>',
    'NO<sub>2<sub>',
    'SO<sub>2<sub>',
    'NO<sub>X<sub>',
    'H<sub>2<sub>S',
    'H<sub>2</sub>S',
    'NH<sub>3<sub>',
  ];

  // order matters, put the more precise on below
  const oddsObj = {
    '&deg;': '°',
    '&deg;C': '°C',
    'O&amp;G': 'O&G',
    '&amp;': '&',
    '<sup>O</sup>C': '°C',
    '<sup>O<sup>C': '°C',
    '<sup>0</sup>C': '°C',
    '<sup>0<sup>C': '°C',
    '<sub>2<sub>': '₂',
    '<sub>2</sub>': '₂',
    'O<sub>2<sub>': 'O₂',
    '<sub>3<sub>': '₃',
    '<sub>3</sub>': '₃',
    'O<sub>3<sub>': 'O₃',
    'O<sub>3</sub>': 'O₃',
    'CO<sub>2<sub>': 'CO₂',
    'CO<sub>2</sub>': 'CO₂',
    'NO<sub>2<sub>': 'NO₂',
    'NO<sub>2</sub>': 'NO₂',
    'SO<sub>2<sub>': 'SO₂',
    'SO<sub>2</sub>': 'SO₂',
    '<sub>X<sub>': 'NOx',
    'NO<sub>X<sub>': 'NOx',
    'NO<sub>X</sub>': 'NOx',
    'H<sub>2<sub>S': 'H₂S',
    'H<sub>2</sub>S': 'H₂S',
    'NH<sub>3<sub>': 'NH₃',
    'NH<sup>3</sup>': 'NH₃',
    'M<sup>1</sup>': 'M¹',
    'M<sup>10</sup>': 'M¹°',
    'M<sup>1<sup>': 'M¹',
    'M<sup>10<sup>': 'M¹°',
    '<sup>2': '²',
    '<sup>2<sup>': '²',
    '<sup>2</sup>': '²',
    '<sup>3<sup>': '³',
    '<sup>3</sup>': '³',
    '<sup>3': '³',
    'µg/m<sup>3': 'μg/m³',
  };

  let retStr = str;

  Object.keys(oddsObj).map((oddObj) => {
    if (str.includes(oddObj)) {
      retStr = str.replace(oddObj, oddsObj[oddObj]);
    }
  });
  return retStr;
};

const parseString4 = (str) => {
  var chars =
      '+-=()0123456789ABDEGHIJKLMNOPRTUVWaÆᴂɐɑɒBbcɕDdðEeƎəɛɜɜfGgɡɣhHɦIiɪɨᵻɩjJʝɟKklLʟᶅɭMmɱNnɴɲɳŋOoɔᴖᴗɵȢPpɸrRɹɻʁsʂʃTtƫUuᴜᴝʉɥɯɰʊvVʋʌwWxyzʐʑʒꝯᴥβγδθφχнნʕⵡ',
    sup =
      '⁺⁻⁼⁽⁾⁰¹²³⁴⁵⁶⁷⁸⁹ᴬᴮᴰᴱᴳᴴᴵᴶᴷᴸᴹᴺᴼᴾᴿᵀᵁⱽᵂᵃᴭᵆᵄᵅᶛᴮᵇᶜᶝᴰᵈᶞᴱᵉᴲᵊᵋᶟᵌᶠᴳᵍᶢˠʰᴴʱᴵⁱᶦᶤᶧᶥʲᴶᶨᶡᴷᵏˡᴸᶫᶪᶩᴹᵐᶬᴺⁿᶰᶮᶯᵑᴼᵒᵓᵔᵕᶱᴽᴾᵖᶲʳᴿʴʵʶˢᶳᶴᵀᵗᶵᵁᵘᶸᵙᶶᶣᵚᶭᶷᵛⱽᶹᶺʷᵂˣʸᶻᶼᶽᶾꝰᵜᵝᵞᵟᶿᵠᵡᵸჼˤⵯ';
  var subChars = '0123456789aeox()+-=',
    sub = '₀₁₂₃₄₅₆₇₈₉ₐₑₒₓ₍₎₊₋₌';

  const supVal = str.replace(/<sup[^>]*>(.*?)<\/sup>/gi, function(x) {
    let newStr = '',
      txt = x
        .toString()
        .replace(/<\/?sup[^>]*>+/g, '')
        .trim();

    for (var i = 0; i < txt.length; i++) {
      var n = chars.indexOf(txt[i]);
      newStr += n != -1 ? sup[n] : txt[i];
    }
    return newStr;
  });
  const subVal = supVal.replace(/<sub[^>]*>(.*?)<\/sub>/gi, function(x) {
    let newStr = '',
      txt = x
        .toString()
        .replace(/<\/?sub[^>]*>+/g, '')
        .trim();

    for (var i = 0; i < txt.length; i++) {
      var n = subChars.indexOf(txt[i]);
      newStr += n != -1 ? sub[n] : txt[i];
    }
    return newStr;
  });
  return subVal;
};

// incomplete and not in use
const parseStringToHtml = (str) => {
  const uri_dec = decodeURIComponent(str);
  const toRender = `
	<div id="edit-symbol">${uri_dec}</div>
`;
  return toRender;
};

// Generate random color
const generateRadomColor = () => {
  const xxx = Math.random() * 255;
  const yyy = Math.random() * 255;
  const zzz = Math.random() * 255;
  return `rgba(${xxx}, ${yyy}, ${zzz}, 0.4)`;
};

const setFormatValue = (theLoc) => {
  const re = /^-?\d*\.?\d*$/;

  if (theLoc === '') {
    return theLoc;
  } else {
    if (re.test(theLoc)) {
      const negStart = /^-?/;
      if (negStart.test(theLoc)) return theLoc.replace(/^-0+/, '-0');
      return theLoc.replace(/^0+/, '0');
    }
    return isNaN(parseFloat(theLoc)) ? '' : parseFloat(theLoc);
  }
};

const properCase = (str) => {
  if (str && typeof str === 'string' && str.trim().length === 1) {
    return String(str)
      .trim()
      .toUpperCase();
  }
  if (str && typeof str === 'string') {
    return str
      .trim()
      .split(' ')
      .map((w) => w[0] && w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ');
  }
  return str;
};

const toFixed = (num, precision = 2) => {
  if (!String(num).includes('.')) return num;
  if (num && Number(num)) return Number(num).toFixed(precision);
  return num;
};

const addUrlParam = (url, param) => {
  if (!param) return url;
  if (url.includes('?')) return `${url}&${param}`;
  return `${url}?${param}`;
};

const numeralize = (str) => {
  const num = { first: '1st', second: '2nd', third: '3rd', fourth: '4th' };
  if (str) {
    return num[str.split(' ')[0].toLowerCase()];
  }
  return str;
};

const normalizeObjectCase = (obj) => {
  const doNotNormalize = [
    'id',
    'sectorId',
    'lgaId',
    'regionId',
    'stateId',
    'productId',
    'rawMaterialId',
    'wasteId',
  ];
  const newObj = {};
  if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (doNotNormalize.includes(key)) {
        newObj[key] = obj[key];
      } else newObj[key] = properCase(obj[key]);
    });
    return newObj;
  }
  if (typeof obj === 'string') {
    return properCase(obj);
  }
  return obj;
};

const isLatitude = (lat) => {
  if (lat && lat.loc) lat = lat.loc;
  return isFinite(lat) && Math.abs(lat) <= 90;
};

const isLongitude = (lng) => {
  if (lng && lng.loc) lng = lng.loc;
  return isFinite(lng) && Math.abs(lng) <= 180;
};

const checkLatitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.reject('Latitude is required');
  if (!val) return Promise.reject('Latitude is required');
  if (isLatitude(val)) {
    return Promise.resolve();
  }

  return Promise.reject('Latitude must be between -90 and 90 degrees!');
};

const checkLongitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.reject('Longitude is required');
  if (!val) return Promise.reject('Longitude is required');

  if (isLongitude(val)) {
    return Promise.resolve();
  }
  return Promise.reject('Longitude must be between -180 and 180 degrees!');
};

const optionalCheckLatitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.resolve();
  if (!val) return Promise.resolve();
  if (isLatitude(val)) {
    return Promise.resolve();
  }

  return Promise.reject('Latitude must be between -90 and 90 degrees!');
};

// different from the one in utils
// cos latitude isn't compulsory in this step
const optionalCheckLongitude = (rule, value) => {
  let val;
  if (value) {
    if (typeof value === 'object') {
      if ('loc' in value && value.loc !== '') val = value.loc;
    } else {
      val = value;
    }
  } else return Promise.resolve();
  if (!val) return Promise.resolve();

  if (isLongitude(val)) {
    return Promise.resolve();
  }
  return Promise.reject('Longitude must be between -180 and 180 degrees!');
};

const toDegreesMinutesAndSeconds = (coordinate) => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + ' ' + minutes + ' ' + seconds;
};

const convertDMS = (lat, lng) => {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? 'N' : 'S';

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return latitude + ' ' + latitudeCardinal + ' - ' + longitude + ' ' + longitudeCardinal;
};

const generateReport = (report, parameters, which) => {
  console.log("report",report)
  console.log("parameter",parameters)
  console.log("which",which)
  try {
    if (!which) throw new Error('Must specify report container');
    var $ = window.$;
    var telerikReportViewer = window.telerikReportViewer;
    return new Promise((resolve, reject) => {
      const reportViewer = $(which).data('telerik_ReportViewer');
      console.log("reportViewer", reportViewer)
      if (reportViewer) {
        reportViewer.reportSource({ report, parameters });
        reportViewer.bind(telerikReportViewer.Events.PAGE_READY, (e) => resolve(e));
        reportViewer.bind(telerikReportViewer.Events.ERROR, (e) => reject(e));
      } else {
        $(which).telerik_ReportViewer({
          serviceUrl: 'https://localhost:44323/TestReport.html',
          //serviceUrl: 'https://report-api.iems.ng/api/iemsreports/',
          //serviceUrl: 'https://iemsreport.lubred.net/api/iemsreports/',
          reportSource: { report, parameters },
          scaleMode: 'FIT_PAGE_WIDTH',
          viewMode: 'PRINT_PREVIEW',
          pageReady: (e) => resolve(e),
          error: (e) => reject(e),
        });
      }
    });
  } catch (error) {
    console.log('Failed to generate report', error);
  }
};

const findItemInListOfObjects = (list = [], id, key, itemType) => {
  if (!list.length || !id || !key) {
    return false;
    // throw new Error('Pass an array of items, the item to search and the search key');
  }
  let itemFound = false;
  let flter;
  if (itemType === 'string')
    flter = list.filter(
      (lst) => lst[key] && lst[key].trim().toLowerCase() === id.trim().toLowerCase()
    );
  else
    flter = list.filter(
      (lst) => lst[key] && lst[key].trim().toLowerCase() === id.trim().toLowerCase()
    );

  if (flter && flter[0]) itemFound = flter[0];
  return itemFound;
};

function copyTextToClipboard(text) {
  let textArea = document.createElement('textarea');

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    let msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

const isEmpty = (value) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

const getQuarterWithData = (recordData) => {
  let quarterWithData = null;
  if (recordData && recordData.length > 0) {
  } else if (recordData && typeof recordData === 'object') {
    const keys = Object.keys(recordData);
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        if (recordData[keys[i]] && recordData[keys[i]].length > 0) {
          if (!quarterWithData) quarterWithData = [];
          if (!quarterWithData.includes(keys[i])) quarterWithData.push(keys[i]);
        }
      }
      if (quarterWithData) quarterWithData.sort((a, b) => (a > b ? 1 : -1));
    }
  }
  return quarterWithData;
};

const mergeSpt = (bigSpt = [], newSpt) => {
  let biggerSpt = bigSpt;
  if (bigSpt && newSpt && newSpt.length > 0) {
    for (let i = 0; i < newSpt.length; i++) {
      if (!bigSpt.some((bgSpt) => bgSpt['samplePointId'] === newSpt[i]['samplePointId'])) {
        biggerSpt.push(newSpt[i]);
      }
    }
  }

  return biggerSpt;
};

const getBiggestSamplePoint = (recordData) => {
  let biggestSpt;
  const keys = Object.keys(recordData);
  if (keys && keys.length > 0) {
    for (let i = 0; i < keys.length; i++) {
      if (!biggestSpt) biggestSpt = recordData[keys[i]];
      else {
        biggestSpt = mergeSpt(biggestSpt, recordData[keys[i]]);
      }
    }
  }
  return biggestSpt;
};

const getBiggerReport = (fmts) => {
  if (fmts && fmts.length > 0) {
    let returningFmt = fmts[0];
    if (!returningFmt['qaqc']) returningFmt['qaqc'] = [];
    if (!returningFmt['report']) returningFmt['report'] = [];

    fmts.map((fmt) => {
      if (
        (fmt.qaqc && fmt.report && fmt.qaqc.length > fmt.report.length) ||
        (fmt.qaqc && !fmt.report)
      ) {
        if (
          fmt.qaqc.length > returningFmt.qaqc.length &&
          fmt.qaqc.length > returningFmt.report.length
        ) {
          returningFmt = fmt;
        }
      } else if (
        (fmt.qaqc && fmt.report && fmt.report.length > fmt.qaqc.length) ||
        (fmt.report && !fmt.qaqc)
      ) {
        if (
          fmt.report.length > returningFmt.report.length &&
          fmt.report.length > returningFmt.qaqc.length
        ) {
          returningFmt = fmt;
        }
      }
    });
    return returningFmt;
  }
};

const extractCompareData = ({ samplePtId, field = 'all', results }) => {
  const fmtRst = [];
  results &&
    results.length > 0 &&
    results.map((rst1) => {
      const rstQtr = Object.keys(rst1)[0];
      const rstR = rst1[rstQtr].filter((rstF) => rstF.samplePointId === samplePtId);
      if (rstR && rstR.length > 0) {
        const rstRr = { ...rstR[0] };
        fmtRst.push(rstRr);
      }
    });

  if (fmtRst && fmtRst.length > 0) {
    const pmtRst = [];
    const resultReturning = [];
    const avgReturning = [];

    if (field === 'parameters' || field === 'allcancelled') {
      const usableFmtRst = getBiggerReport(fmtRst);
      if (
        (usableFmtRst.qaqc &&
          usableFmtRst.result &&
          usableFmtRst.qaqc.length >= usableFmtRst.report.length) ||
        (usableFmtRst.qaqc && usableFmtRst.qaqc.length > 0)
      ) {
        usableFmtRst.qaqc.map((fmQ) =>
          pmtRst.push({
            measurementId: fmQ.measurementId,
            parameterId: fmQ.parameterId,
            unit: fmQ.unit,
            siUnit: fmQ.siUnit,
          })
        );
      } else {
        if (usableFmtRst.report && usableFmtRst.report.length > 0) {
          usableFmtRst.report.map((fmQ) =>
            pmtRst.push({
              measurementId: fmQ.measurementId,
              parameterId: fmQ.parameterId,
              unit: fmQ.unit,
              siUnit: fmQ.siUnit,
            })
          );
        }
      }
      if (field === 'parameters') return { parameters: pmtRst };
    }
    if (field === 'tabledata' || field === 'all') {
      const avgQCompData = { fmEnv: {}, worldBank: {} };

      fmtRst.map((fmtRstOne) => {
        const objQaqc = {};
        let key = fmtRstOne['reportType'];
        let { [key]: theReport } = { QAQC: fmtRstOne.qaqc, NORMAL: fmtRstOne.report };
        theReport &&
          theReport.length > 0 &&
          theReport.map((fmtR) => {
            objQaqc[fmtR.unit] = fmtR.average.toFixed(2);
            avgQCompData['fmEnv'][fmtR.unit] = fmtR['fmEnv'].toFixed(2);
            avgQCompData['worldBank'][fmtR.unit] = fmtR['worldBank'].toFixed(2);
          });
        objQaqc['reportType'] = key;
        avgQCompData['fmEnv']['reportType'] = key;
        avgQCompData['worldBank']['reportType'] = key;
        objQaqc['reportStatus'] = fmtRstOne.reportStatus;
        avgQCompData['fmEnv']['reportStatus'] = fmtRstOne.reportStatus;
        avgQCompData['fmEnv']['reportStatus'] = fmtRstOne.reportStatus;
        objQaqc['reportQuarter'] = fmtRstOne.reportQuarter;
        avgQCompData['fmEnv']['reportQuarter'] = fmtRstOne.reportQuarter;
        avgQCompData['worldBank']['reportQuarter'] = fmtRstOne.reportQuarter;

        if (
          !resultReturning.filter((rstRt) => rstRt.reportQuarter === objQaqc['reportQuarter'])[0]
        ) {
          resultReturning.push(objQaqc);
        }
        avgReturning.push(avgQCompData);
      });

      if (field === 'tabledata') return { tabledata: resultReturning };
    }
    return {
      parameters: pmtRst,
      tabledata: resultReturning,
      avgData: avgReturning,
    };
  } else return null;
};

function MakeQuerablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isResolved) return promise;

  // Set initial state
  var isPending = true;
  var isRejected = false;
  var isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  var result = promise.then(
    function(v) {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    function(e) {
      isRejected = true;
      isPending = false;
      throw e;
    }
  );

  result.isFulfilled = function() {
    return isFulfilled;
  };
  result.isPending = function() {
    return isPending;
  };
  result.isRejected = function() {
    return isRejected;
  };
  return result;
}

export {
  avgCalc,
  toFixed,
  getErrorDesc,
  addUrlParam,
  properCase,
  normalizeObjectCase,
  isLongitude,
  isLatitude,
  checkLatitude,
  checkLongitude,
  optionalCheckLatitude,
  optionalCheckLongitude,
  formatDate,
  getQuarter,
  datelize,
  mergeArrays,
  parseString,
  parseString2,
  parseString3,
  parseString4,
  parseStringToHtml,
  setFormatValue,
  generateRadomColor,
  numeralize,
  convertDMS,
  generateReport,
  findItemInListOfObjects,
  isEmpty,
  getQuarterWithData,
  getBiggestSamplePoint,
  extractCompareData,
  MakeQuerablePromise,
  copyTextToClipboard,
};
