import React from 'react'
import { verifyPayment } from '../../../helpers/api'
import { useEffect } from 'react'
import { useState } from 'react'
import LoadingContent from '../../common/LoadingContent'
import { Button, Col, Row } from 'antd'
import EmptyContent from '../../common/EmptyContent'

export default function VerifyPayment(props) {
   console.log(props)
    const qryParam = new URLSearchParams(props.location.search)
    const refCode = qryParam.get("reference")
    console.log(refCode)

    const [loading, setLoading] = useState(false)
    const [statusCode, setStatusCode] = useState('')
    const [statusDesc, setStatusDesc] = useState('')

    useEffect(() => {
        handleVerify()
    }, [])

    const handleVerify = () => {
        setLoading(true)
        const param = {
            referenceCode: refCode
        }
        verifyPayment(param).then(res => {
            setLoading(false)
            setStatusCode(res.result.status.code)
            setTimeout(() => {
                window.location.replace('/')
            }, 2000)
        }).catch(err => {
            setLoading(false)
            setStatusCode(err.data.result.status.code)
            setStatusDesc(err.data.result.status.desc)

        })
    }


    const handleRedirectToFacility = () => {
        window.location.replace('/')
    }


    const handleDisplay = () => {
        if (loading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <LoadingContent pageLoading={loading} />
                    <div>Verifying . . . </div>
                </div>)
        }
        if (!loading && statusCode === '00') {
            return contentDisplay()
        }
        if (!loading && statusCode !== '00') {
            return renderErrorPage()
        }
    }

    const renderErrorPage = () => {
        return (
            <div>
                <Row type="flex" justify="center" align="middle" className="text-center">
                    <Col
                        xs={{
                            span: 12,
                        }}
                        lg={{
                            span: 8,
                        }}
                    >
                        <EmptyContent className="no-content">
                            {/* <h1 className="no-content-header">Something's wrong!</h1> */}
                            <h1 className="no-content-text">{statusDesc}</h1>
                            <div className="buttons">
                                <Button type="secondary" onClick={handleRedirectToFacility}>
                                    Go to Dashboard
                                </Button>
                            </div>
                        </EmptyContent>
                    </Col>
                </Row>
            </div>
        )
    }

    const contentDisplay = () => {
        return (
            <div>
                <Row type="flex" justify="center" align="middle" className="text-center">
                    <Col
                        xs={{
                            span: 12,
                        }}
                        lg={{
                            span: 8,
                        }}
                    >
                        <EmptyContent className="no-content">
                            <h1 className="no-content-header">Verified Successfully!</h1>
                            <p className="no-content-text">Redirecting . . .</p>
                        </EmptyContent>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div style={{ marginTop: '20vh' }}>{handleDisplay()}</div>
    )
}