import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Row, Col, Popconfirm, message, Bredcrumb, Breadcrumb } from 'antd';

import Table from '../../../../../components/common/Table';
import { openNotification } from '../../../../../helpers/notification';
import { getZones, createZone, deleteZone } from '../../../../../helpers/api';
import Button from '../../../../../components/common/Button';
import Layout from '../../../../../components/common/Layout';
import PageHeader from '../../../../../components/common/PageHeader';
import PageContent from '../../../../../components/common/PageContent';
import LoadingContent from '../../../../../components/common/LoadingContent';

import ConfirmModal from '../ConfirmLookup';
import EditModal from './EditZone';
import './Zone.css';
import AddZoneModal from './AddZone';

const Zone = ({ className }) => {
  const [loading, setloading] = useState(false);
  const [zonesList, setzonesList] = useState(null);
  const [confirmModalOpen, setconfirmModalOpen] = useState(false);
  const [confirmMessage, setconfirmMessage] = useState('');
  // const [loadMore, setLoadMore] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [reload, setReload] = useState(false);
  const [zoneName, setZoneName] = useState('');
  const [showAddZoneModal, setShowAddZoneModal] = useState(false);

  useEffect(() => {
    refreshZone();
  }, []);
  useEffect(() => {
    if (reload) {
      refreshZone();
    }
  }, [reload]);

  const refreshZone = () => {
    setloading(true);
    getZones()
      .then((res) => {
        setzonesList(res.result.data);
        setloading(false);
        setReload(false);
      })
      .catch((error) => {
        setloading(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error && error.message ? error.message : error,
        });
      });
  };

  const validateZone = (zoneId) => {
    const zoneData = zonesList.filter((zone) => zone.zoneId === zoneId);
    if (zoneData) {
      if (zoneData[0].state.length > 0) {
        return openNotification({
          type: 'error',
          title: 'Error deleting zone',
          message: 'Zone already contains states',
        });
      } else {
        handleDelete(zoneId);
      }
    }
  };

  const confirmDeleteZone = (e, id) => {
    validateZone(e);
  };

  const handleDelete = (zoneId) => {
    deleteZone(zoneId)
      .then((res) => {
        // console.log(res);
        message.success(`zone deleted successfully`);
        setReload(true);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const getSelectedZoneData = (zoneId) => {
    const zoneData = zonesList.filter((zone) => zone.zoneId === zoneId)[0];
    if (zoneData) {
      setZoneName(zoneData.zoneName);
    }
  };
  const editZone = (ev, id) => {
    getSelectedZoneData(id);
    setSelectedId(id);
    setEditModal(true);
  };

  const columns = [
    {
      title: 'Zone Name',
      dataIndex: 'zoneName',
      render: (text) => <p>{text.toUpperCase()}</p>,
    },
    {
      title: 'No of States',
      dataIndex: 'state',
      render: (state) => (
        <>
          {state.length === 0 ? (
            'No States'
          ) : state.length === 1 ? (
            <>{state.length} State </>
          ) : (
            <>{state.length} States</>
          )}{' '}
        </>
      ),
    },

    {
      title: '',
      dataIndex: 'zoneId',
      render: (zoneId) => (
        <>
          <Button className="edit__zone__btn" id={zoneId} onClick={(e) => editZone(e, zoneId)}>
            <EditOutlined /> Edit
          </Button>

          <Popconfirm
            title="Are you sure delete this zone ?"
            onConfirm={(e) => confirmDeleteZone(zoneId)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="text-danger">
              <CloseCircleOutlined style={{ color: '#ec4c47' }} /> Delete
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const closeConfirmModal = () => {
    setconfirmModalOpen(false);
    window.location.reload();
  };
  const closeEditModal = () => {
    setEditModal(false);
  };

  const closeAddZoneModal = () => {
    setShowAddZoneModal(false);
  };
  const reloadZone = () => {
    setReload(true);
  };

  const keyedZoneData = zonesList;
  keyedZoneData && keyedZoneData.length && keyedZoneData.forEach((dt) => (dt['key'] = dt.zoneId));

  return (
    <Layout headerTitle="Zone Management">
      <div className="breadcrumb__container ml-2rem mt-1rem">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/settings">Settings</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/settings/lookups">Lookups</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Zone Lookup</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <ConfirmModal open={confirmModalOpen} close={closeConfirmModal} message={confirmMessage} />
      {editModal && (
        <EditModal
          open={editModal}
          close={closeEditModal}
          zoneId={selectedId}
          name={zoneName}
          reloadZone={reloadZone}
        />
      )}
      <AddZoneModal open={showAddZoneModal} close={closeAddZoneModal} reloadZone={reloadZone} />
      <PageHeader className="no-padding-x">
        <Row
          // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="start"
          className="pl-2rem"
          style={{ width: '100%' }}
        >
          <Col span={8} style={{ flexFlow: 'column' }}>
            <h3 className="heading mb-20" style={{ alignSelf: 'flex-start' }}>
              Zone Lookups
            </h3>
            <p style={{ alignSelf: 'flex-start' }}>Zones define geo-political area</p>
          </Col>
          <Col
            span={8}
            className="text-right"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button appearance="default" type="primary" onClick={() => setShowAddZoneModal(true)}>
              <PlusOutlined />
              New Zone
            </Button>
          </Col>
        </Row>
      </PageHeader>

      <PageContent>
        <Row justify="" className="">
          <Col lg={{ span: 16 }}>
            <div className={className}>
              {loading ? (
                <div className="spin-fullpage">
                  <LoadingContent pageLoading={true} />
                </div>
              ) : (
                <Table
                  bordered={false}
                  paddedTable={true}
                  dataSource={keyedZoneData}
                  columns={columns}
                  rowClassName="editable-row"
                  pagination={false}
                />
              )}
            </div>
          </Col>
        </Row>
      </PageContent>
    </Layout>
  );
};

export default styled(Zone)`
  padding-top: 1rem;
  padding-left: 2rem;
  .edit-btn {
    color: #0c5bab !important;
    font-size: 14px;
    margin-right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .delete-btn {
    color: #ec4c47 !important;
  }
  .add-more {
    text-align: right;
  }
  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: -20px !important;
  }
`;
