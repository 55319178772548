import { Col, Row, Tag } from 'antd';
import {
  AreaChartOutlined,
  CreditCardOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getEdmDashboardStats } from '../../../../helpers/api';
import styled from 'styled-components';
import LoaderSkeleton from '../../../common/LoaderSkeleton';

export default function StatCardsForEdm({ stateId }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, [stateId]);

  const fetch = () => {
    setLoading(true);
    getEdmDashboardStats(stateId)
      .then((response) => {
        const responseData = response?.result?.data;
        console.log(responseData);
        const m = [
          {
            title: 'Facilities',
            value: responseData?.facilityCount,
            subtitle: 'Total No. of Facilities',
            icon: <AreaChartOutlined style={{ color: 'white', fontSize: '1.6em' }} />,
            iconBg: '#0cb230',
            type: '',
            otherText: '',
          },
          {
            title: 'Active',
            value: responseData?.activeFacilityPercentage,
            subtitle: 'Total No. of Active Facilities',
            icon: <LineChartOutlined style={{ color: 'white', fontSize: '1.6em' }} />,
            iconBg: '#FA1010',
            type: '%',
            otherText: '',
          },
          {
            title: 'QA/QC Completion',
            value: responseData?.completedQaQcReportPercentage,
            subtitle: <div>Completion ration of QA/QC reports'</div>,
            icon: <PieChartOutlined style={{ color: 'white', fontSize: '1.6em' }} />,
            iconBg: '#FAAB10',
            type: '%',
            otherText: `${Intl.NumberFormat('en-NG', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(responseData?.facilityReportCount)} in progress`,
          },
          {
            title: 'Payment Completion',
            value: responseData?.paymentPercentage,
            subtitle: 'Completion ration of Payments',
            icon: <CreditCardOutlined style={{ color: 'white', fontSize: '1.6em' }} />,
            iconBg: '#108AFA',
            type: '%',
            otherText: '',
          },
        ];
        setData(m);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleisplay = () => {
    if (loading) {
      return contentLoader();
    }

    if (!loading && data) {
      return content();
    }
  };

  const contentLoader = () => {
    return (
      <Row gutter={20}>
        {Array(4)
          .fill()
          .map((_, index) => (
            <Col key={index} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
              <LoaderSkeleton />
            </Col>
          ))}
      </Row>
    );
  };

  const content = () => {
    return (
      <Row gutter={20}>
        {data?.map((item, index) => (
          <Col key={index} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <Card>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <Title>{item?.title}</Title>
                  <Info>
                    {item?.value}
                    {item?.type}
                  </Info>
                  <div style={cardDesc}>{item?.subtitle}</div>
                  <div style={{ height: '20px', marginTop:"2px" }}>
                    {item?.otherText && <Tag>{item?.otherText}</Tag>}
                  </div>
                </div>
                <IconWrapper style={{ background: item?.iconBg }}>{item?.icon}</IconWrapper>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  return <div>{handleisplay()}</div>;
}

const Card = styled.div`
  border: 1px solid #c4c4c4;
  padding: 1rem;
  border-radius: 10px;
`;

const Title = styled.div`
  color: #898282;
  font-weight: bold;
  font-size: 1em;
  text-transform: uppercase;
`;

const Info = styled.div`
  font-weight: bolder;
  font-size: 2em;
`;

const cardDesc = {
  fontSize: '0.9em',
};

const IconWrapper = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  align-items: 'center;
  justify-content: center;
  border-radius: 50%;
`;
