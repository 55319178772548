import React, { useState } from 'react';
import { Row, Col } from 'antd';
import PageContent from '../../../../components/common/PageContent';
import PageHeader from '../../../../components/common/PageHeader';
import ModifyPreferences from './ModifyPreference';
import '../style.css';

const PreferencesConfig = () => {
  return (
    <div className="notification__container mb-2rem">
      <Row>
        <Col lg={22} offset={2}>
          <PageHeader split={true} className="no-padding-x">
            <Row
              // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
              type="flex"
              align="start"
              justify="space-between"
              className=""
              style={{ width: '100%' }}
            >
              <Col span={15} style={{ flexFlow: 'column' }}>
                <h3 className="heading" style={{ alignSelf: 'flex-start' }}>
                  Personal Preferences
                </h3>
                <p className="text-gray sub-heading" style={{ alignSelf: 'flex-start' }}>
                  Manage your preferences to get instant notifications on the activities in the
                  system.
                </p>
              </Col>
            </Row>
          </PageHeader>
          <PageContent className="transparent__bg">
            <Row>
              <Col lg={16}>
                <ModifyPreferences />
              </Col>
            </Row>
          </PageContent>
        </Col>
      </Row>
    </div>
  );
};

export default PreferencesConfig;
