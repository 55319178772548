import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import ReportingScreen from '../components/screens/reporting';

export default (props) => {
  return (
    <Layout headerTitle={'Reporting'}>
      <PageContainer>
        <ReportingScreen props={props} id="reporting" />
      </PageContainer>
    </Layout>
  );
};
