import { Divider, Empty, Select, Skeleton, Space, Spin } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
  getEdmDashboardComparativeMeasurementFacilityList,
  getEdmDashboardComparativeReport,
  getEdmDashboardComparativeReportFacilityList,
} from '../../../../helpers/api';
import { parseString2, parseString3, parseString4, properCase } from '../../../../helpers/utils';

export default function ComparativeAnalyticsEdmView({ stateId }) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const [facilityData, setFacilityData] = useState([]);
  const [loadingFacilityData, setLoadingFacilityData] = useState(false);
  const [selectedFacilityId, setSelectedFacilityId] = useState('');

  const [measurementData, setMeasurementData] = useState([]);
  const [loadingMeasurement, setLoadingMeasurement] = useState(false);
  const [selectedMeasurementId, setSelectedMeasurementId] = useState();

  useEffect(() => {
    fetchFacilityList();
  }, [stateId]);

  useEffect(() => {
    fetchMeasurementList();
  }, []);

  useEffect(() => {
    fetch();
  }, [selectedMeasurementId, selectedFacilityId]);

  const fetch = () => {
    setLoading(true);
    getEdmDashboardComparativeReport(selectedFacilityId, selectedMeasurementId)
      .then((response) => {
        setLoading(false);
        const responseData = response.result.data;
        setData(responseData);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchFacilityList = () => {
    setLoadingFacilityData(true);
    getEdmDashboardComparativeReportFacilityList(stateId)
      .then((response) => {
        setLoadingFacilityData(false);
        const responseData = response?.result?.data;
        const m = responseData?.map((item) => ({
          label: item.facilityName,
          value: item.facilityId,
        }));
        setFacilityData(m);
        setSelectedFacilityId(m[0].value);
      })
      .catch((error) => {
        setLoadingFacilityData(false);
        console.log(error);
      });
  };

  const renderFacilityList = () => {
    if (loadingFacilityData) {
      return (
        <div style={{ display: 'flex', gap: 4 }}>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Loading facilities
        </div>
      );
    }
    if (!loadingFacilityData && facilityData?.length > 0) {
      return (
        <Select
          defaultValue={facilityData[0]?.label}
          placeholder="Select Facility"
          showSearch
          options={facilityData}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          style={{
            width: '220px',
          }}
          onChange={(value) => {
            setSelectedFacilityId(value);
          }}
        />
      );
    }
  };

  const fetchMeasurementList = () => {
    setLoadingMeasurement(true);
    getEdmDashboardComparativeMeasurementFacilityList()
      .then((response) => {
        setLoadingMeasurement(false);
        const responseData = response.result.data;
        const m = responseData?.map((item) => ({
          label: properCase(item.measurementName),
          value: item.measurementId,
        }));
        setMeasurementData(m);
        setSelectedMeasurementId(m[0].value);
      })
      .catch((error) => {
        setLoadingMeasurement(false);
      });
  };

  const renderMeasurementList = () => {
    if (loadingMeasurement) {
      return (
        <div style={{ display: 'flex', gap: 4 }}>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Loading measurements
        </div>
      );
    }

    if (!loadingMeasurement && measurementData?.length > 0) {
      return (
        <Select
          defaultValue={properCase(measurementData[0]?.label)}
          placeholder="Select Measurement"
          showSearch
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={measurementData}
          onChange={(value) => {
            setSelectedMeasurementId(value);
          }}
          style={{
            width: '200px',
          }}
        />
      );
    }
  };

  const handleDisplay = () => {
    if (loading) {
      return <Skeleton active />;
    }

    if (!loading && !data.length) return <Empty style={{ padding: '1rem' }} />;

    if (!loading && data?.length > 0 && measurementData?.length > 0) {
      return content();
    }
  };

  const content = () => {
    const renderCustomYAxisTick = ({ x, y, payload }) => (
      <Text x={x} y={y} textAnchor="end" verticalAnchor="middle" fontSize={10}>
        {parseString4(payload.value)}
      </Text>
    );
    return (
      <div>
        <ResponsiveContainer width={'100%'} height={250}>
          <BarChart data={data} barSize={10}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={`parameterName`}  tick={renderCustomYAxisTick}/>
            <YAxis />
            <Tooltip/>
            <Legend />
            <Bar dataKey={`normal`} fill="#7CBDFF" radius={[50, 50, 0, 0]} />
            <Bar dataKey={`qaqc`} fill="#3AF141" radius={[50, 50, 0, 0]} />
            <Bar dataKey={'nesrea'} fill="#FA7970" radius={[50, 50, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };
  return (
    <div style={card}>
      <div style={header}>
        <div style={title}>
          <div style={titlePrimary}>Comparative Analytics</div>
          <div style={titleSecondary}>Emissions comparism analytics</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Space>{renderMeasurementList()}</Space>
          <Space>{renderFacilityList()}</Space>
        </div>
      </div>

      <Divider />
      <div style={{ padding: '0 1rem 1rem 1rem' }}>{handleDisplay()}</div>
    </div>
  );
}

const card = {
  border: '1px solid #C4C4C4',
  borderRadius: '10px',
  fontFamily: 'sans-serif',
  height: '100%',
};

const header = {
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 1rem 0 1rem',
};

const title = {
  color: '#898282',
  flexGrow: 1,
};

const titlePrimary = {
  color: '#5f6368',
  fontSize: '1.5em',
  fontWeight: 'bold',
};

const titleSecondary = {
  /* font-size: 0.8em; */
  marginTop: '-5px',
};
