import React, { useState, useEffect, useRef } from 'react';
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  DatePicker,
  Modal,
  Tooltip,
  Typography,
  Form,
  Divider,
  Input,
  InputNumber,
  Carousel,
  Progress,
  Popconfirm,
  Upload,
  message,
  Button as AntButton,
  Spin,
} from 'antd';
import { LoadingOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import LoadingContent from '../../../components/common/LoadingContent';
import CloseIcon from '../../../components/common/CloseIcon';
import { ReactComponent as PlusIcon } from '../../../assets/img/plus.svg';
import Button from '../../../components/common/Button';
import CreateNewFacility from '../../../components/CreateNewFacility';
import EditFacility from '../../../components/EditFacility';
import PageHeader from '../../../components/common/PageHeader';
import PageContent from '../../../components/common/PageContent';
import Select from '../../../components/common/Select';
import { openNotification } from '../../../helpers/notification';
import {
  lockReport,
  createAdhocReport,
  getFacilityAdhocReport,
  getFacilityReport,
  getAdhocReport,
  getSamplePointRecord,
  sendSamplePointRecord,
  publishFacilityReport,
  uploadEIACert,
  sendAdhocInvoice,
  sendAdhocInvoiceApi,
} from '../../../helpers/api';
import FacilityReportingModal from './FacilityReportingModal';
import {
  properCase,
  formatDate,
  getQuarter,
  getErrorDesc,
  datelize,
  parseString,
  parseString3,
  parseString4,
  setFormatValue,
} from '../../../helpers/utils';
import CalendarLock from '../../../assets/img/calendar-6.svg';
import CalendarOpen from '../../../assets/img/calendar-18.svg';
import LockRed from '../../../assets/img/lock.svg';
import LockGrey from '../../../assets/img/lock-grey.svg';
import CalendarGrey from '../../../assets/img/calendar-grey.svg';
import WindPowerIcon from '../../../assets/img/wind-power.svg';
import TaskCompleteIcon from '../../../assets/img/taskdone.svg';
import DraftIcon from '../../../assets/img/draw.svg';
import FacilityUploadTable from '../../FacilityUploadTable';
import FacilityView from './FacilityView';
import '../../../shared.css';
import './index.css';
import '../../../scrollfix.css';
import FacilityInvoiceList from './FacilityInvoice/FacilityInvoiceList';
import FacilityListTable from './FacilityListTable';

const { Text } = Typography;
const { TextArea } = Input;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';
const date = new Date();
const y = date.getFullYear();
const m = date.getMonth();
const currentDay = moment(new Date()).format(dateFormat);
// .startOf('month')
const endOfYear = moment()
  .endOf('year')
  .format(dateFormat);

const initAdhocValues = {
  adhocReportPeriod: moment(currentDay),
  // adhocReportPeriod: [moment(currentDay), moment(endOfYear)],
};

// Generate random color
const generateRadomColor = () => {
  const xxx = Math.random() * 255;
  const yyy = Math.random() * 255;
  const zzz = Math.random() * 255;
  return `rgba(${xxx}, ${yyy}, ${zzz}, 0.4)`;
};

// left here for when we want to use timestamp to lock quarter
// current only when report type is not under QA/QC
const currentYear = new Date().getFullYear();
const isNotLocked = (report, qtr) => {
  if (report.length > 0) {
    const rpt = report.filter(
      (rp) =>
        rp &&
        rp.reportQuarter &&
        parseInt(rp.reportQuarter) === parseInt(qtr) &&
        rp.reportType !== 'QAQC'
    );
    if (rpt && rpt.length > 0) return true;
    return false;
  }
  return false;
};

export default function Facilities({ props }) {
  const initialPage = props.location.pathname === '/facilities/create' ? 'create' : 'loading';
  const [page, setPage] = useState(initialPage);
  const [record, setRecord] = useState('');

  const [samplePointRecord, setSamplePointRecord] = useState('');
  const [combinedSamplePointParameters, setCombinedSamplePointParameters] = useState([]);

  const [samplePointRecordData, setSamplePointRecordData] = useState([]);

  const [parameters, setParameters] = useState('');
  const [report, setReport] = useState('');
  const [facilityRecord, setFacilityRecord] = useState('');
  const [adhocReport, setAdhocReport] = useState('');
  const [modal, setModal] = useState('');
  const [load, reload] = useState(false);
  const [consultantFilter, setConsultantFilter] = useState('');
  const [facilityFilter, setFacilityFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sectorFilter, setSectorFilter] = useState('');
  const [eiaFilter, setEiaFilter] = useState('');
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filtering, setFiltering] = useState(false);
  const [createNewAdhocModal, setCreateNewAdhocModal] = useState('');
  const [creatingAdhocReport, setCreatingAdhocReport] = useState('');
  const [adhocStep, setAdhocStep] = useState('');
  const [currentAdhocSummary, setCurrentAdhocSummary] = useState('');
  const [currentAdhocReport, setCurrentAdhocReport] = useState('');
  const [reportingModalState, setReportingModalState] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [sendingAdhocInvoice, setSendingAdhocInvoice] = useState(false);
  const [loadingSaveRecords, setLoadingSaveRecords] = useState(false);

  const [form] = Form.useForm();
  const sampleDataAddFormRef = useRef();
  const filterFormRef = useRef();

  const getAndSetFacilityReport = (id, qtr, yyyy = currentYear) => {
    getFacilityReport(id, qtr, yyyy)
      .then(({ result }) => {
        const fReport = { qtr, data: null };
        if (result.data) fReport.data = result.data;
        setReport(fReport);
        // setModal('reporting');
        // setPage('');
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get facility report',
          message: `${err}`,
        });
      });
  };

  const getAndSetSamplePointRecord = (samplePtId, reportId) => {
    getSamplePointRecord(samplePtId, reportId)
      .then(({ result }) => {
        const responseData = result?.data;

        const {
          parameters: normalParameters,
          noiseParameters,
          results: existingResults,
        } = responseData;

        const combinedParameters = [...normalParameters, ...noiseParameters];

        setSamplePointRecord(responseData);
        setParameters(responseData?.parameters);
        setCombinedSamplePointParameters(combinedParameters);

        //TO BE REVIEWED
        // const sptRecord = { data: null };
        // if (result.data) {
        //   sptRecord.data = result.data;
        //   setSamplePointRecord(sptRecord);
        // }

        const resultReturning = [];

        existingResults?.map((fmRst, index) => {
          const objR = {};
          fmRst.data.map((fmRst2) => {
            const thePamata = combinedParameters?.filter(
              (pamata) => pamata.parameterId === fmRst2.parameterId
            );
            if (thePamata && thePamata[0] && thePamata[0]['unit'])
              objR[thePamata[0]['unit']] = fmRst2.resultValue;
          });

          if (!objR['entryDate']) {
            if (fmRst && fmRst.data && fmRst.data[0] && fmRst.data[0]['entryDate'])
              objR['entryDate'] = fmRst.data[0]['entryDate'];
            else objR['entryDate'] = new Date();
          }
          resultReturning.push(objR);
        });
        setSamplePointRecordData(resultReturning);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get facility record',
          message: `${err}`,
        });
      });
  };

  const handleSubmitStandardMeasurements = (type) => {
    setLoadingSaveRecords(true);

    if (type === 'adhoc' && currentAdhocSummary) {
      const data = {};
      data['reportId'] = currentAdhocSummary.reportId;
    } else {
      const noiseMeasurementId = 'AQ_3';
      const measurMntData = [];
      const noiseMeasurMntData = [];

      const normalMeasurementData = combinedSamplePointParameters?.filter(
        (x) => x.measurementId !== noiseMeasurementId
      );
      const noiseMeasurementData = combinedSamplePointParameters?.filter(
        (x) => x.measurementId === noiseMeasurementId
      );

      samplePointRecordData.map((smptRData) => {
        const theParams = [];
        normalMeasurementData.map((pamta) =>
          theParams.push({
            parameterId: String(pamta.parameterId),
            value: parseFloat(smptRData[pamta.unit]) || 0,
            entryDate: smptRData['entryDate'],
          })
        );
        measurMntData.push(theParams);
      });

      samplePointRecordData.map((smptRData) => {
        const theParams = [];
        noiseMeasurementData.map((pamta) =>
          theParams.push({
            parameterId: String(pamta.parameterId),
            value: parseFloat(smptRData[pamta.unit]) || 0,
            entryDate: smptRData['entryDate'],
          })
        );
        noiseMeasurMntData.push(theParams);
      });

      const normalMeasurementPayload = {
        reportId: report.data.reportId,
        facilityId: samplePointRecord.facilityId,
        samplePointId: samplePointRecord.samplePointId,
        measurmentId: samplePointRecord.measurementId,
        measurements: measurMntData,
      };

      const noiseMeasurementPayload = {
        reportId: report.data.reportId,
        facilityId: samplePointRecord.facilityId,
        samplePointId: samplePointRecord.samplePointId,
        measurmentId: noiseMeasurementId,
        measurements: noiseMeasurMntData,
      };

      sendSamplePointRecord(normalMeasurementPayload)
        .then(() => {
          setLoadingSaveRecords(false);
          if (type === 'adhoc') handleCancel('adhoc-recording');
          else backToReporting(report.qtr);
          return openNotification({
            type: 'success',
            title: 'Successfully!',
            message: 'Measurements saved successfully',
          });
        })
        .catch((err) => {
          setLoadingSaveRecords(false);
          const errMessage = err?.data?.result?.status?.desc;
          return openNotification({
            type: 'error',
            title: 'Failed!',
            message: errMessage,
          });
        });

      if (noiseMeasurMntData[0]?.length > 0) {
        sendSamplePointRecord(noiseMeasurementPayload)
          .then(() => {
            setLoadingSaveRecords(false);
            if (type === 'adhoc') handleCancel('adhoc-recording');
            else backToReporting(report.qtr);
            return openNotification({
              type: 'success',
              title: 'Successfully!',
              message: 'Measurements for noise status saved successfully',
            });
          })
          .catch((err) => {
            setLoadingSaveRecords(false);
            const errMessage = err?.data?.result?.status?.desc;
            return openNotification({
              type: 'error',
              title: 'Failed!',
              message: errMessage,
            });
          });
      }
    }
  };

  const getAdhocReports = (id, yyyy = currentYear) => {
    getAdhocReport(id, yyyy)
      .then(({ result }) => {
        const fList = [];
        result.data && result.data.length > 0 && result.data.forEach((res) => fList.push(res));
        setAdhocReport(fList);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: `${err && typeof err === 'string' ? err : 'Failed to get facility.'}`,
        });
      });
  };

  // const getFacilityRecord = (id, yyyy = currentYear) => {
  //   getFacility(id, yyyy)
  //     .then(({ result }) => {
  //       const fList = [];
  //       result.data && result.data.length > 0 && result.data.forEach((res) => fList.push(res));
  //       setFacilityRecord(fList);
  //     })
  //     .catch((err) => {
  //       return openNotification({
  //         type: 'error',
  //         title: 'Something went wrong!',
  //         message: `${err && typeof err === 'string' ? err : 'Failed to get facility.'}`,
  //       });
  //     });
  // };

  const getAndLock = (id, qtr) => {
    // make a request to the api to
    // lock this facility report
    // for the stated qtr
    // and re set the report state
    lockReport(id)
      .then(({ result }) => {
        openNotification({
          type: 'success',
          title: 'Successful',
          message: `Quarter ${qtr} locked for ${result.data.facility}`,
        });
        reload(!load);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: `${err && typeof err === 'string' ? err : 'Failed to lock report.'}`,
        });
      });
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 14 },
  };

  // const saveSamplePointData = (type) => {
  //   sendSamplePointsData(type);
  // };

  useEffect(() => {
    if (page !== 'create' && page !== 'edit' && page !== 'error') setPage('loading');
    setCreateNewAdhocModal(false);
    try {
      if (props.location.pathname === '/facilities/create') setPage('create');
      // else if (props.location.pathname === '/facilities/edit') setPage('edit');
      else if (page === 'edit') setPage('edit');
      else if (page === 'error') setPage('error');
      else {
        if (!modal) getFacilitiesLists();
        if (modal === 'adhoc' && record.data.facilityId) {
          // do adhoc stuffs
          //first a form to submit adhoc body fields
          getAdhocReports(record.data.facilityId);
        }
        if ((modal === 'selectqtr' || modal === 'locking') && record.data.facilityId) {
          // getFacilityRecord(record.facilityId);
          getFacilityRecord(record.data.facilityId);
        }
        if (modal === 'viewingcert') {
          // do nothing really
        }
        setPage('');
      }
    } catch (err) {
      setPage('error');
    }
    return;
  }, [
    props,
    page,
    modal,
    record,
    currentAdhocReport,
    currentAdhocSummary,
    samplePointRecordData,
    parameters,
    load,
    consultantFilter,
    facilityFilter,
    stateFilter,
    sectorFilter,
    statusFilter,
    eiaFilter,
  ]);

  const createFacility = () => {
    setPage('create');
    props.history.push('/facilities/create');
  };

  const { userCategory, userPermission } = props;

  const userCat = userCategory.trim().toLowerCase();
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const isNotEDM = userCat !== 'edm';
  const isEDM = !isNotEDM;
  const isNotConsultant = userCat !== 'consultant';
  const isConsultant = !isNotConsultant;

  // const canMakeFacilityReport =
  //   userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  // const canLockReport = userCat === 'edm';
  // const canViewReport =
  //   userCat === 'edm' ||
  //   (userCat === 'consultant' && userPerm.includes('view_report')) ||
  //   userCat === 'regulator';
  // const canCreateAdhocReport =
  //   userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  // const canRecordReport =
  //   userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  // const canUpdateFacility =
  //   userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('update_facility'));
  // const canCreateFacilityReport =
  //   userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));
  // const canCreateFacility = userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('create_facility'));
  // const canPublishReport = userCat === 'edm' || (userCat === 'consultant' && userPerm.includes('record_report'));

  const canViewRecordedReport = userPerm.includes('view_report');
  const canMakeFacilityReport = userPerm.includes('record_report');
  const canLockReport = userCat === 'edm';
  const canViewReport = userPerm.includes('view_report');
  const canCreateAdhocReport = userPerm.includes('record_report');
  const canRecordReport = userPerm.includes('record_report');
  const canUpdateFacility = userPerm.includes('update_facility');
  const canCreateFacilityReport = userPerm.includes('record_report');
  const canCreateFacility = userPerm.includes('create_facility');
  const canPublishReport = userPerm.includes('publish_report');

  const closeEditing = (e, which) => {
    if (!which) {
      e.preventDefault();
      return setPage('');
    }
    if (which === 'startediting') {
      openNotification({
        type: 'info',
        title: `Editing: ${record.data.facilityName}`,
        message: 'Use the breadcrumb link on the top left to exit action',
      });
    } else if (which === 'closeediting') {
      return setPage('');
    } else if (which === 'error') return setPage(which);
    else setPage('');
  };

  if (page === 'create' && canCreateFacility) return <CreateNewFacility />;
  if (page === 'edit' && canUpdateFacility)
    return <EditFacility facility={record.data} closeEditing={closeEditing} />;
  // if (
  //   (page === 'loading' && !facilities) ||
  //   page === 'loading' ||
  //   (!facilities && page !== 'error')
  // )
  //   return (
  //     <div className="spin-fullpage">
  //       <LoadingContent pageLoading={true} />
  //     </div>
  //   );

  const handleOk = () => setModal('');
  const handleCancel = (which) => {
    switch (which) {
      case 'selecting':
        setModal('');
        setRecord('');
        break;
      case 'recording':
        setRecord('');
        setSamplePointRecordData([]);
        setSamplePointRecord('');
        setParameters('');
        break;
      case 'locking':
        setModal('');
        setRecord('');
        break;
      case 'reporting':
        setReport('');
        setFacilityRecord('');
        setRecord('');
        setModal('');
        break;
      case 'viewing':
        setRecord('');
        setModal('');
        break;
      case 'viewingcert':
        setRecord('');
        setFileList([]);
        setModal('');
        break;
      case 'adhoc':
        setModal('');
        setRecord('');
        setAdhocStep('');
        setCreatingAdhocReport('');
        setCurrentAdhocSummary('');
        setAdhocReport('');
        break;
      case 'adhoc-recording':
        adhocReporting2(currentAdhocSummary);
        break;
      default:
        break;
    }
  };

  function backToReporting(whichQtr) {
    setPage('loading');
    getAndSetFacilityReport(record.data.facilityId, whichQtr);
    setPage('');
    setModal('reporting');
  }

  function backToReportingQtr(e) {
    e.preventDefault();
    // setPage('loading');
    // setPage('');
    setModal('selectqtr');
  }

  const locking = (whichQtr) => {
    setPage('loading');
    let qtr = whichQtr;
    let { [qtr]: selectedQtr } = {
      q1: 1,
      q2: 2,
      q3: 3,
      q4: 4,
    };
    if (facilityRecord && facilityRecord.length > 0) {
      const rpt = facilityRecord.filter((rp) => parseInt(rp.reportQuarter) === selectedQtr);
      getAndLock(rpt[0].reportId, selectedQtr);
      setPage('');
    } else {
      setPage('');
    }
  };

  // get quarter from click and fetch report data
  const reporting = (whichQtr) => {
    //setPage('loading');
    let qtr = whichQtr;
    let { [qtr]: currQtr } = {
      q1: 1,
      q2: 2,
      q3: 3,
      q4: 4,
    };
    if (record.data.facilityId) {
      setModal('reporting');
      // setPage('');
      getAndSetFacilityReport(record.data.facilityId, currQtr);
    } else {
      setModal('');
      // setRecord('');
      setPage('');
    }
  };

  // const uploadEIACert = () => new Promise((resolve, reject) => resolve(true));

  const uploadProps = {
    name: 'file',
    action: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const formerRecord = Object.assign({}, record);
          uploadEIACert(formerRecord.data.facilityId, reader.result)
            .then(() => {
              message.success('Uploaded succesfully');
              formerRecord.data.eiaCert = reader.result;
              setRecord(formerRecord);
              setFileList([file]);
            })
            .catch((error) => {
              let errMsg = getErrorDesc(error)
                ? getErrorDesc(error)
                : 'Failed to upload. Please try again!';
              message.error(errMsg);
              setRecord(formerRecord);
              setFileList([]);
              reject(error);
            });
          // setRecord(formerRecord);
          return resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      }),
    beforeUpload(file, fileList) {
      const isJpgOrPngorPdf =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'application/pdf' ||
        file.type === 'image/x-eps';
      if (!isJpgOrPngorPdf) {
        message.error('You can only upload JPG/PNG/PDF file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('File must smaller than 2MB!');
      }
      isJpgOrPngorPdf && isLt2M && fileList && setFileList(fileList.slice(-1));
      return isJpgOrPngorPdf && isLt2M;
    },
    onRemove: () =>
      new Promise((resolve) => {
        setFileList([]);
        return resolve(true);
      }),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
        // message.success(`${info.file.name ? info.file.name : ''} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        setFileList([]);
        // message.error(`${info.file.name ? info.file.name : ''} file upload failed.`);
      }
    },
    transformFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // setCertBase64(reader.result);
          return resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    fileList,
    // disabled: fileList && fileList.length > 0,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  function generateUploadProps(which) {
    const retProps = { ...uploadProps };
    // if (which === 'new') delete retProps.fileList;
    if (which === 'header') {
      retProps.fileList = [];
    }
    return retProps;
  }

  function renderModalHeader(status, modalType, cancelAct) {
    if (!cancelAct) cancelAct = modalType;
    if (status && status.data) {
      let sampleCount = status.data.samplePointCount;
      let sampleText =
        sampleCount > 1 ? `(${sampleCount} SamplePoints)` : `(${sampleCount} SamplePoint)`;
      const { facilityName } = status.data;
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className="facility-report__header"
        >
          <span style={{ alignSelf: 'start' }}>
            <div className="report-title">
              <Text type="strong">
                {modalType === 'selecting' || modalType === 'locking'
                  ? properCase(facilityName)
                  : properCase(record.data.facilityName)}
              </Text>

              {modalType === 'viewingcert' && (
                <span
                  className={
                    record.data.status.toLowerCase().trim() === 'active' ? 'active' : 'not-active'
                  }
                >
                  {properCase(record.data.status)}
                </span>
              )}

              {modalType === 'viewinvoices' && (
                <span
                  className={
                    record.data.status.toLowerCase().trim() === 'active' ? 'active' : 'not-active'
                  }
                >
                  {properCase(record.data.status)}
                </span>
              )}
            </div>
            <div className="report-subtitle-1">
              {modalType !== 'adhocrecording' &&
                modalType !== 'viewingcert' &&
                modalType !== 'adhoc' && (
                  <Text type="secondary">
                    {!sampleCount
                      ? `${
                          modalType === 'selecting' ||
                          modalType === 'locking' ||
                          modalType === 'viewing' ||
                          modalType === 'adhoc'
                            ? datelize(getQuarter(new Date(Date.now())))
                            : datelize(report.data.reportQuarter)
                        }
                 Quarter`
                      : sampleText}
                  </Text>
                )}
              {modalType === 'adhoc' && (
                <Text type="secondary">{formatDate(new Date(Date.now()), 'd LLL yyyy')}</Text>
              )}
              {modalType === 'adhocrecording' && currentAdhocSummary && (
                <Text type="secondary">{`${currentAdhocSummary.reportTitle}`}</Text>
              )}
              {modalType === 'viewingcert' && <Text type="secondary">EIA Certificate</Text>}
            </div>
            <div className="report-subtitle-2">
              {modalType !== 'viewingcert' && modalType !== 'viewinvoices' && (
                <Text type="secondary">
                  {(record.type === 'standard' || record.type === 'locking') &&
                  modalType !== 'adhocrecording'
                    ? 'Standard Report'
                    : 'Adhoc Report'}
                </Text>
              )}
              {modalType === 'viewingcert' && status.data.eiaCert && (
                <Upload {...generateUploadProps('header')} className="cert-upload-btn">
                  <AntButton>
                    <UploadOutlined /> Upload New Certificate
                  </AntButton>
                </Upload>
              )}
            </div>
          </span>
          <span style={{ alignSelf: 'end', textAlign: 'right' }}>
            <>
              <CloseIcon
                className="close-icon report-viewing"
                onClick={() => handleCancel(cancelAct)}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
              {false && (
                <>
                  <div className="report-date__title">
                    <Text type="secondary">{formatDate(new Date(Date.now()), 'd LLL yyyy')}</Text>
                  </div>
                  <div className="report-subtitle-1 report-time">
                    <Text type="secondary">{formatDate(new Date(Date.now()), 'hh mm aaaa')}</Text>
                  </div>
                </>
              )}
            </>
          </span>
        </div>
      );
    } else
      return (
        <>
          <div className="facility-report__body">
            <Row gutter={16} style={{ marginTop: '1.5rem' }} type="flex" align="end" justify="end">
              <CloseIcon
                className="close-icon report-viewing"
                onClick={() => handleCancel('viewing')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row>
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );
  }

  const renderFacilityInvoiceHeader = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="facility-report__header"
      >
        <span style={{ alignSelf: 'start' }}>
          <div className="report-title">
            <Text type="strong">{properCase(record.data.facilityName)} </Text>
            <br />
            <Text type="secondary">Invoices</Text>
          </div>
        </span>
        <span style={{ alignSelf: 'end', textAlign: 'right' }}>
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => {
              setModal('');
              setRecord('');
            }}
            style={{
              marginBottom: '1rem',
              marginTop: '0.8rem',
            }}
          />
        </span>
      </div>
    );
  };

  function renderSelectModal(factyRecord) {
    if (!factyRecord || factyRecord.length < 1)
      return (
        <div className="facility-report__body">
          <Row gutter={16} style={{ marginTop: '1.5rem' }}>
            <LoadingContent modal={true} />
          </Row>
        </div>
      );

    const isNotPassedFirstQuarter = !isNotLocked(factyRecord, 1);
    const isNotPassedSecondQuarter = !isNotLocked(factyRecord, 2);
    const isNotPassedThirdQuarter = !isNotLocked(factyRecord, 3);
    const isNotPassedFourthQuarter = !isNotLocked(factyRecord, 4);
    const getStatusAsap = (q) => factyRecord.filter((fR) => parseInt(fR.reportQuarter) === q)[0];
    const q1Status = getStatusAsap(1)['reportStatus'].toLowerCase();
    const q2Status = getStatusAsap(2)['reportStatus'].toLowerCase();
    const q3Status = getStatusAsap(3)['reportStatus'].toLowerCase();
    const q4Status = getStatusAsap(4)['reportStatus'].toLowerCase();

    const quarters = [
      {
        code: 'q1',
        text: 'First Quarter',
        period: 'Jan - Mar',
        hoverable: isEDM ? isNotPassedFirstQuarter : !isNotPassedFirstQuarter,
        status: q1Status,
      },
      {
        code: 'q2',
        text: 'Second Quarter',
        period: 'Apr - Jun',
        hoverable: isEDM ? isNotPassedSecondQuarter : !isNotPassedSecondQuarter,
        status: q2Status,
      },
      {
        code: 'q3',
        text: 'Third Quarter',
        period: 'Jul - Sep',
        hoverable: isEDM ? isNotPassedThirdQuarter : !isNotPassedThirdQuarter,
        status: q3Status,
      },
      {
        code: 'q4',
        text: 'Fourth Quarter',
        period: 'Oct - Dec',
        hoverable: isEDM ? isNotPassedFourthQuarter : !isNotPassedFourthQuarter,
        status: q4Status,
      },
    ];

    return (
      <>
        <div className="facility-report__body">
          {/* <div className="arrow-back" onClick={() => handleCancel('selecting')} /> */}
          <Row gutter={10}>
            {quarters.map((qt) => {
              return (
                <Col span={6} key={qt.code} style={{ padding: '0.5rem', marginLeft: '-5px' }}>
                  <Card
                    hoverable={qt.hoverable}
                    className={`facility-card__body ${!qt.hoverable ? 'nocursor' : ''}`}
                    onClick={qt.hoverable ? () => reporting(qt.code) : () => false}
                    style={{ width: '100%' }}
                  >
                    <div className={`card-lock qtr-lock-icon ${qt.hoverable ? '' : ''}`}>
                      {isNotConsultant || (isConsultant && qt.hoverable) ? (
                        ''
                      ) : (
                        <p className="lock-text-desc">Locked to QAQC</p>
                      )}
                      <img
                        src={
                          !qt.hoverable
                            ? LockRed
                            : qt.status === 'draft'
                            ? DraftIcon
                            : TaskCompleteIcon
                        }
                        className="lock-icon"
                        alt="lock"
                      />
                    </div>

                    <div className="card-content-">
                      <div className="qtr-icon">
                        <img
                          src={qt.hoverable ? CalendarOpen : CalendarLock}
                          className="calendar-6"
                          alt="calendar"
                        />
                      </div>
                      <div className="qtr-text">{qt.text}</div>
                      <div className="qtr-month">{qt.period}</div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  }

  function adhocReporting2(adhocData) {
    setCreatingAdhocReport(true);
    setCurrentAdhocSummary('');
    // setAdhocStep('');
    setReportingModalState('loading');
    setCurrentAdhocReport('');
    setModal('adhoc');
    setCurrentAdhocSummary(adhocData);
    getFacilityAdhocReport(adhocData.facilityId, adhocData.reportId)
      .then(({ result: res2 }) => {
        setCurrentAdhocReport(res2);
        setReportingModalState('');
      })
      .then(() => setAdhocStep('adhocReporting'));

    setCreatingAdhocReport(false);
  }

  function adhocReporting(adhocData) {
    return function() {
      if (adhocData) {
        setCurrentAdhocSummary(adhocData);
        getFacilityAdhocReport(adhocData.facilityId, adhocData.reportId)
          .then(({ result: res2 }) => {
            setCurrentAdhocReport(res2);
          })
          .then(() => setAdhocStep('adhocReporting'));

        setCreatingAdhocReport(false);

        // return openNotification({
        //   type: 'success',
        //   title: 'Successful!',
        //   message: `You can now capture your data.`,
        // });
      }
    };
  }

  function recording(reportId, data) {
    return function() {
      //setPage('loading');
      if (data) {
        setSamplePointRecord('');
        setSamplePointRecordData([]);
        // setReport('');
        setParameters('');
        getAndSetSamplePointRecord(data.samplePointId, reportId);
        //setPage('');
        //setModal('recording');
      } else {
        // or just setModal('reporting);
        setModal('');
        setSamplePointRecord('');
        setParameters('');
        setPage('');
      }
    };
  }

  function adhocRecording(reportId, adhocData) {
    return function() {
      if (adhocData) {
        setSamplePointRecord('');
        setSamplePointRecordData([]);
        // setReport('');
        setParameters('');

        getAndSetSamplePointRecord(adhocData.samplePointId, reportId);
        setModal('adhocrecording');
        setAdhocStep('adhocReporting');
        // setAdhocStep('adhocRecording');

        setCreatingAdhocReport(false);
      } else {
        setSamplePointRecord('');
        setParameters('');
        setAdhocStep('adhocReporting');
      }
    };
  }

  function navigateAdhocBreadCrumb(toWhere) {
    if (toWhere === 'root') {
      setAdhocStep('');
      setCreatingAdhocReport(false);
      setCurrentAdhocSummary('');
      setCurrentAdhocReport('');
    }
  }

  function proceedToAdhocReport(values) {
    try {
      setCreatingAdhocReport(true);
      const adhocPayload = {
        reportTitle: values['adhocReportName'],
        facilityId: record.data.facilityId,
        remarks: values['adhocReportRemark'] ? values['adhocReportRemark'] : null,
        dueDate: new Date(values['adhocReportPeriod'].format('YYYY-MM-DDTHH:mm:ssZ')),
      };
      createAdhocReport(adhocPayload)
        .then(({ result }) => {
          setCurrentAdhocSummary(result.data);
          if (result)
            getFacilityAdhocReport(record.data.facilityId, result.data.reportId)
              .then(({ result: res2 }) => {
                setCurrentAdhocReport(res2);
              })
              .then(() => setAdhocStep('adhocReporting'))
              .catch((err) => {
                return openNotification({
                  type: 'error',
                  title: 'Something went wrong!',
                  message: `$${getErrorDesc(err) ? getErrorDesc(err) : 'Failed to load report'}`,
                });
              });

          setCreatingAdhocReport(false);

          return openNotification({
            type: 'success',
            title: 'Successful!',
            message: `You can now capture your data.`,
          });
        })
        .catch((err) => {
          setCreatingAdhocReport(false);
          return openNotification({
            type: 'Error',
            title: 'Something went wrong!',
            message: `${getErrorDesc(err) ? getErrorDesc(err) : 'Failed to create report'}`,
          });
        });
    } catch (err) {
      setCreatingAdhocReport(false);
      return openNotification({
        type: 'Error',
        title: 'Something went wrong!',
        message: `${
          getErrorDesc(err)
            ? getErrorDesc(err).toLowerCase() === 'already exists'
              ? 'Report Name Already Exists'
              : getErrorDesc()
            : 'Failed to create report'
        }`,
      });
    }
  }

  function checkReportStatus(type = 'standard', adhocDatX) {
    let reportStatus = 'Draft';
    if (type !== 'adhoc' && report && report.data) reportStatus = report.data.reportStatus;
    else {
      if (adhocDatX) {
        const currDat = adhocDatX.data;
        if (currDat && currDat.some((cr) => cr.status && cr.status.toLowerCase() === 'draft')) {
          reportStatus = 'Draft';
        } else reportStatus = 'Published';
      }
    }
    return reportStatus;
  }

  // const published = checkReportStatus().toLowerCase() == 'published';
  const published =
    checkReportStatus().toLowerCase() === 'published' ||
    checkReportStatus().toLowerCase() === 'approved';
  // const adhocPublished = checkReportStatus('adhoc', currentAdhocReport).toLowerCase() !== 'draft';
  const isAdhocPublished = (currentReport) => {
    let published = false;
    if (
      currentReport &&
      currentReport.reportStatus &&
      currentReport.reportStatus.toLowerCase() !== 'draft'
    ) {
      published = true;
    }
    return published;
  };
  const adhocPublished = isAdhocPublished(currentAdhocSummary);

  function renderAdhocModal(adhocReport) {
    if (!adhocReport)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('adhoc')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );
    if (!adhocStep && adhocReport.length === 0)
      return (
        <>
          <div className="thereport__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('adhoc')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                className="sub-heading"
              >
                You have not created any adhoc report this year.
              </Col>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                className="sub-heading"
              >
                Click the button below to create one
              </Col>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
              >
                <Button
                  type="primary"
                  appearance="default"
                  onClick={() => setAdhocStep('createNewAdhocReport')}
                >
                  Create Adhoc Report
                </Button>
              </Col>
            </Row>
          </div>
        </>
      );

    if (!adhocStep && adhocReport.length > 0) {
      return (
        <>
          {/* <div className="theadhocreport__body">
            <div className="arrow-back" onClick={() => handleCancel('adhoc')} />
          </div> */}
          <Carousel>
            {adhocReport.map((adr, index) => {
              if (index % 4 === 0) {
                return (
                  <div key={`${index}${adr.reportId}`}>
                    <Row gutter={16} style={{ marginTop: '1.5rem', overflowX: 'unset' }}>
                      {adhocRowContent(adhocReport, index, adr.reportId)}
                    </Row>
                  </div>
                );
              }
            })}
          </Carousel>
          <Row>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
              className="add-new-adhoc-btn"
            >
              <Button
                type="primary"
                appearance="default"
                onClick={() => setAdhocStep('createNewAdhocReport')}
              >
                <Tooltip title="Create new adhoc report">
                  <PlusIcon />
                </Tooltip>
                {/* <PlusIcon onClick={() => setAdhocStep('createNewAdhocReport')}/> */}
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    if (adhocStep === 'createNewAdhocReport')
      return (
        <>
          <div className="adhoc-thereport__body">
            <Breadcrumb
              separator=""
              className="onreporting-modal__breadcrumb"
              style={{ marginTop: '-10px', marginBottom: 10 }}
            >
              <Breadcrumb.Item
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  navigateAdhocBreadCrumb('root');
                }}
                className="clickable-breadcrumb"
              >{`Adhoc Reports`}</Breadcrumb.Item>
              <Breadcrumb.Separator>:</Breadcrumb.Separator>
              <Breadcrumb.Item>Create Adhoc Report</Breadcrumb.Item>
              {/* <Breadcrumb.Separator /> */}
              {/* <Breadcrumb.Item>{`${datelize(records.data.reportQuarter)} Quarter`}</Breadcrumb.Item> */}
            </Breadcrumb>
            <Row>
              <Col span={20}>
                <Form
                  className=""
                  {...formItemLayout}
                  layout="horizontal"
                  form={form}
                  // ref={formRef}
                  initialValues={initAdhocValues}
                  onFinish={proceedToAdhocReport}
                  //onFinishFailed={() => {}}
                >
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        name="adhocReportName"
                        label="Report Title"
                        rules={[{ required: true, message: 'Report Title is required!' }]}
                        style={{ marginBottom: 12, display: 'block' }}
                      >
                        <Input
                          placeholder="Give your report a name"
                          className="form-controlx"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row> */}
                    <Col span={10} className="pl-30">
                      <Form.Item
                        name="adhocReportPeriod"
                        // name="adhocReportPeriod"
                        label="Choose Report Date"
                        style={{ marginBottom: 12, display: 'block' }}
                      >
                        <DatePicker />
                        {/* <RangePicker disabled={[false, true]} /> */}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="adhocReportRemark"
                        label="Report Remarks (Optional)"
                        style={{ marginBottom: 20, display: 'block' }}
                      >
                        {/* <TextArea rows={4} onChange={onChange} value={value} /> */}
                        <TextArea rows={4} placeholder={'Enter your remarks here'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={10}
                      className="pl-3"
                      style={{ alignSelf: 'end', placeContent: 'end' }}
                    >
                      <Form.Item
                        label=""
                        {...tailLayout}
                        style={{ marginBottom: '0', display: 'block' }}
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues['adhocReportName'] !== currentValues['adhocReportName']
                        }
                        className="create-adhoc-report-btn"
                      >
                        {({ getFieldValue }) => {
                          return (
                            <>
                              <Button
                                type="primary"
                                appearance="default"
                                htmlType="submit"
                                loading={Boolean(creatingAdhocReport)}
                                disabled={Boolean(!getFieldValue('adhocReportName'))}
                                style={{ marginRight: '10px' }}
                              >
                                Proceed
                              </Button>
                              <Button
                                type="secondary"
                                onClick={() => handleCancel('adhoc')}
                                disabled={Boolean(creatingAdhocReport)}
                              >
                                Cancel
                              </Button>
                            </>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );

    if (adhocStep === 'adhocReporting' && currentAdhocReport)
      return (
        <FacilityReportingModal
          type="adhoc"
          handleCancel={handleCancel}
          currentReport={currentAdhocSummary}
          rowContent={adhocReportingRowContent}
          loading={reportingModalState === 'loading'}
          records={currentAdhocReport}
          adhocPublished={adhocPublished}
          // adhocPublished={isAdhocPublished(currentAdhocSummary)}
          back={navigateAdhocBreadCrumb}
          finalSubmitReport={() => finalSubmitReport('adhoc')}
        />
      );

    return <></>;
  }

  function renderLockingModal(lockingRecord) {
    if (!lockingRecord || lockingRecord.length < 1)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('locking')} /> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    const isLockedFirstQuarter = !isNotLocked(lockingRecord, 1);
    const isLockedSecondQuarter = !isNotLocked(lockingRecord, 2);
    const isLockedThirdQuarter = !isNotLocked(lockingRecord, 3);
    const isLockedFourthQuarter = !isNotLocked(lockingRecord, 4);

    const quarters = [
      {
        code: 'q1',
        text: 'First Quarter',
        period: 'Jan - Mar',
        hoverable: !isLockedFirstQuarter,
      },
      {
        code: 'q2',
        text: 'Second Quarter',
        period: 'Apr - Jun',
        hoverable: !isLockedSecondQuarter,
      },
      {
        code: 'q3',
        text: 'Third Quarter',
        period: 'Jul - Sep',
        hoverable: !isLockedThirdQuarter,
      },
      {
        code: 'q4',
        text: 'Fourth Quarter',
        period: 'Oct - Dec',
        hoverable: !isLockedFourthQuarter,
      },
    ];

    return (
      <>
        <div className="thereport__body">
          {/* <div className="arrow-back" onClick={() => handleCancel('locking')} /> */}
          <Row gutter={16} style={{ marginTop: '1.5rem' }}>
            {quarters.map((qt) => {
              return (
                <Col span={6} key={qt.code}>
                  {qt.hoverable ? (
                    <Popconfirm
                      title={
                        <>
                          {/* <h3>Submitting is final </h3> */}
                          <p>Are you sure you want to lock {qt.text} for QAQC?</p>
                        </>
                      }
                      onConfirm={() => locking(qt.code)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Card
                        hoverable={qt.hoverable}
                        className={`facility-card__body ${!qt.hoverable ? 'nocursor' : ''}`}
                        onClick={qt.hoverable ? () => true : () => false}
                      >
                        <div className="card-lock">
                          <div className="qtr-lock-icon">
                            {qt.hoverable ? '' : <p className="lock-text-desc">Locked for QA/QC</p>}

                            <img
                              src={!qt.hoverable ? LockRed : LockGrey}
                              className="lock-icon"
                              alt="lock"
                            />
                          </div>
                        </div>
                        <div className="card-content">
                          <div
                            className={`qtr-icon ${
                              !qt.hoverable ? 'calendar-loc' : 'calendar-grey'
                            }`}
                          >
                            <img
                              src={!qt.hoverable ? CalendarLock : CalendarGrey}
                              className="calendar-6"
                              alt="calendar"
                            />
                          </div>
                          <div className="qtr-text">{qt.text}</div>
                          <div className="qtr-month">{qt.period}</div>
                        </div>
                      </Card>
                    </Popconfirm>
                  ) : (
                    <Card
                      hoverable={qt.hoverable}
                      className={`facility-card__body ${!qt.hoverable ? 'nocursor' : ''}`}
                      onClick={qt.hoverable ? () => true : () => false}
                    >
                      <div className="card-lock">
                        <div className="qtr-lock-icon">
                          {qt.hoverable ? '' : <p className="lock-text-desc">Locked for QA/QC</p>}

                          <img
                            src={!qt.hoverable ? LockRed : LockGrey}
                            className="lock-icon"
                            alt="lock"
                          />
                        </div>
                      </div>
                      <div className="card-content">
                        <div
                          className={`qtr-icon ${!qt.hoverable ? 'calendar-loc' : 'calendar-grey'}`}
                        >
                          <img
                            src={!qt.hoverable ? CalendarLock : CalendarGrey}
                            className="calendar-6"
                            alt="calendar"
                          />
                        </div>
                        <div className="qtr-text">{qt.text}</div>
                        <div className="qtr-month">{qt.period}</div>
                      </div>
                    </Card>
                  )}
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  }

  function renderSampleStatusProgress(status, type = 'standard', where) {
    const stat = status.split('/');

    if (type === 'standard' && published && stat[1]) {
      return (
        <div className="progress-published">
          <Progress type="line" percent={100} format={() => 'Published'} />
        </div>
      );
    }
    if (type === 'adhoc' && where !== 'onreporting' && adhocPublished && stat[1]) {
      return (
        <div className="progress-published">
          <Progress type="line" percent={100} format={() => 'Published'} />
        </div>
      );
    }

    const perccCalc = (parseInt(stat[0]) / parseInt(stat[1])) * 100;
    let percc = Math.round(perccCalc);
    if (percc < perccCalc) percc += 1;
    if (type === 'adhoc' && adhocPublished && stat[1]) {
      if (stat[0] >= stat[1])
        return (
          <div style={{ width: '100%', padding: '0.9px 15px' }} className="progress-notpublished">
            {/* <Progress percent={percc} steps={parseInt(stat[1])} strokeColor="#1890ff" /> */}
            Uploaded
          </div>
        );
      else {
        return (
          <div style={{ width: '100%', padding: '0.9px 15px' }} className="progress-notpublished">
            <Progress percent={percc} steps={parseInt(stat[1])} strokeColor="#1890ff" />
          </div>
        );
      }
    }

    return (
      <div style={{ width: '100%', padding: '0.9px 15px' }} className="progress-notpublished">
        <Progress percent={percc} steps={parseInt(stat[1])} strokeColor="#1890ff" />
      </div>
    );
  }

  function recording(reportId, data) {
    return function() {
      //setPage('loading');
      if (data) {
        setSamplePointRecord('');
        setSamplePointRecordData([]);
        // setReport('');
        setParameters('');
        getAndSetSamplePointRecord(data.samplePointId, reportId);
        //setPage('');
        setModal('recording');
      } else {
        // or just setModal('reporting);
        setModal('');
        setSamplePointRecord('');
        setParameters('');
        setPage('');
      }
    };
  }

  function finalSubmitReport(type) {
    setIsPublishing(true);
    let publishedX = published;
    if (type === 'adhoc') publishedX = adhocPublished;

    if (publishedX) {
      // do this;
      reload(!load);
      return;
    }
    let rId;
    if (type === 'adhoc') rId = currentAdhocSummary.reportId;
    else rId = report.data.reportId;
    publishFacilityReport(rId)
      .then(() => {
        // setRecord('');
        // backToReporting(report.qtr);
        // refetch report
        if (type !== 'adhoc') {
          const updatedReport = { ...report };
          updatedReport.data.reportStatus = 'PUBLISHED';
          setReport(updatedReport);

          return openNotification({
            type: 'success',
            title: 'Successful',
            message: `Report for ${report.data.facility} is approved!`,
          });
        } else {
          const updatedAdhocReport = { ...currentAdhocSummary };
          updatedAdhocReport.reportStatus = 'PUBLISHED';
          setCurrentAdhocSummary(updatedAdhocReport);

          return openNotification({
            type: 'success',
            title: 'Successful',
            message: `${currentAdhocSummary.reportTitle} report is published!`,
          });
        }
        setIsPublishing(false);
      })
      .catch((err) => {
        setIsPublishing(false);
        return openNotification({
          type: 'Error',
          title: 'Something went wrong!',
          message: `${
            err && typeof err === 'string'
              ? err
              : err && err.result && err.result.status.desc
              ? err.result.status.desc
              : 'Failed to publish report.'
          }`,
        });
      });
  }

  const sendAdhocInvoice = () => {
    setSendingAdhocInvoice(true);
    const roleId = report.data.reportId;
    sendAdhocInvoiceApi(roleId)
      .then((res) => {
        setSendingAdhocInvoice(false);
        return openNotification({
          type: 'success',
          title: 'Successful',
          message: `Invoice was sent successfully`,
        });
      })
      .catch((err) => {
        setSendingAdhocInvoice(false);
        return openNotification({
          type: 'Error',
          title: 'Something went wrong!',
          message: `Failed to send invoice`,
        });
      });
  };

  const generateCol = (combinedParameters) => {
    return (
      <div style={{ display: 'flex', overflowX: 'scroll', gap: 10 }}>
        {combinedParameters?.map((paramtr) => {
          let siUnite = parseString(`${paramtr.siUnit}`);
          if (paramtr.nesreaDataType !== 'TEXT') {
            return (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <span>{parseString(paramtr.unit)}</span>{' '}
                  <sub>
                    <small>{siUnite}</small>
                  </sub>
                </div>
                <Form.Item name={paramtr.unit}>
                  <InputNumber formatter={(value) => `${setFormatValue(value)}`} />
                </Form.Item>
              </div>
            );
          } else if (paramtr.nesreaDataType === 'TEXT' && paramtr.nesrea !== '') {
            return (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <span>{parseString(paramtr.unit)}</span>{' '}
                  <sub>
                    <small>{siUnite}</small>
                  </sub>
                </div>
                <Form.Item name={paramtr.unit}>
                  <Select
                    formatter={(value) => `${setFormatValue(value)}`}
                    style={{ width: '100px', marginRight: '5px' }}
                  >
                    <Option value="1">{!paramtr.nesrea}</Option>
                    <Option value="0">{`Not ${paramtr.nesrea}`}</Option>
                  </Select>
                </Form.Item>
              </div>
            );
          } else if (paramtr.nesreaDataType === 'TEXT' && paramtr.nesrea === '') {
            return (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <span>{parseString(paramtr.unit)}</span>{' '}
                  <sub>
                    <small>{siUnite}</small>
                  </sub>
                </div>
                <Form.Item name={paramtr.unit}>
                  <InputNumber formatter={(value) => `${setFormatValue(value)}`} />
                </Form.Item>
              </div>
            );
          } else {
            return (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <span>{parseString(paramtr.unit)}</span>
                  <sub>
                    <small>{siUnite}</small>
                  </sub>
                </div>
                <Form.Item name={paramtr.unit}>
                  <InputNumber formatter={(value) => `${setFormatValue(value)}`} />
                </Form.Item>
              </div>
            );
          }
        })}

        {/* {noiseParams?.map((noise) => {
          let siUnite = parseString(`${noise.siUnit}`);
          return (
            <div>
              <div style={{ marginBottom: '10px' }}>
                <span>{parseString(noise.unit)}</span>
                <sub>
                  <small>{siUnite}</small>
                </sub>
              </div>

              <Form.Item name={noise.unit} style={{ width: '100px' }}>
                <InputNumber formatter={(value) => `${setFormatValue(value)}`} />
              </Form.Item>
            </div>
          );
        })} */}
      </div>
    );
    //return content;
    // const content = [];
    // let item;
    // <div style={{ display: 'flex', border:"1px solid red" }}>
    //   {params?.map((paramtr) => {
    //     item = <div style={{display:"flex"}}>sdsdsd</div>;
    //     content.push(item);
    //   })}
    // </div>;

    // return content;

    // const content = [];
    // let item;
    // let siUnite = parseString(`${paramtr.siUnit}`);
    // if (paramtr.nesreaDataType !== 'TEXT') {
    //   item = (
    //     <div>
    //       <div style={{ height: '60px', width: '100px' }}>
    //         <div>{parseString(paramtr.unit)}</div>
    //         <small>{siUnite}</small>
    //       </div>
    //       <Form.Item name={paramtr.unit}>
    //         <InputNumber
    //           formatter={(value) => `${setFormatValue(value)}`}
    //           style={{ width: '100px', marginRight: '5px' }}
    //         />
    //       </Form.Item>
    //     </div>
    //   );
    // } else if (paramtr.nesreaDataType === 'TEXT' && paramtr.nesrea !== '') {
    //   item = (
    //     <div>
    //       <div style={{ height: '60px', width: '100px' }}>
    //         <div>{parseString(paramtr.unit)}</div>
    //         <small>{siUnite}</small>
    //       </div>
    //       <Form.Item name={paramtr.unit}>
    //         <Select
    //           formatter={(value) => `${setFormatValue(value)}`}
    //           style={{ width: '100px', marginRight: '5px' }}
    //         >
    //           <Option value="1">{!paramtr.nesrea}</Option>
    //           <Option value="0">{`Not ${paramtr.nesrea}`}</Option>
    //         </Select>
    //       </Form.Item>
    //     </div>
    //   );
    // } else if (paramtr.nesreaDataType === 'TEXT' && paramtr.nesrea === '') {
    //   item = (
    //     <div>
    //       <div style={{ height: '60px', width: '100px' }}>
    //         <div>{parseString(paramtr.unit)}</div>
    //         <small>{siUnite}</small>
    //       </div>
    //       <Form.Item name={paramtr.unit}>
    //         <InputNumber
    //           formatter={(value) => `${setFormatValue(value)}`}
    //           style={{ width: '100px', marginRight: '5px' }}
    //         />
    //       </Form.Item>
    //     </div>
    //   );
    // }
    // content.push(item);
    // return content;
  };

  function rowContent(spt, index, reportId) {
    let times = 0;
    let start = index;
    const content = [];
    while (times < 8 && times < spt.length - index) {
      const sampStatus = spt[start] || { resultCount: 0, checkCount: 1 };
      const samplStatus = `${sampStatus.resultCount}/${sampStatus.checkCount}`;
      const newCol = (
        <Col span={6} style={{ height: '100%' }} key={spt[start].samplePointId}>
          <Card
            hoverable={true}
            className="facility-card__body"
            style={{ backgroundColor: generateRadomColor(), marginBottom: '1rem' }}
            onClick={recording(reportId, spt[start])}
          >
            <div className="card-content">
              <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div>
              <div className="qtr-subtext">{spt[start].samplePointName}</div>
              <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div>

              <div className={`qtr-progress ${published ? 'qtr-published' : ''}`}>
                {renderSampleStatusProgress(samplStatus)}
              </div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  function adhocReportingRowContent(spt, index, reportId) {
    let times = 0;
    let start = index;
    const content = [];
    while (times < 8 && times < spt.length - index) {
      const sampStatus = spt[start] || { resultCount: 0, checkCount: 1 };
      const samplStatus = `${sampStatus.resultCount}/${sampStatus.checkCount}`;
      const newCol = (
        <Col span={6} key={spt[start].samplePointId}>
          <Card
            hoverable={true}
            className="facility-card__body"
            style={{ backgroundColor: generateRadomColor(), marginBottom: '1rem' }}
            onClick={adhocRecording(reportId, spt[start])}
          >
            <div className="card-content">
              <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div>
              <div className="qtr-subtext">{spt[start].samplePointName}</div>
              <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div>

              <div
                className={`qtr-progress ${
                  spt[start]['status'].toLowerCase() !== 'draft' ? 'qtr-published' : ''
                }`}
              >
                {renderSampleStatusProgress(samplStatus, 'adhoc', 'onreporting')}
                {/* {spt[start]['status']} */}
              </div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  function getAdhocReportStatus(spt) {
    if (spt && spt.reportStatus) return spt.reportStatus;
  }

  function adhocRowContent(spt, index, reportId) {
    let times = 0;
    let start = index;
    const content = [];
    while (times < 4 && times < spt.length - index) {
      // const sampStatus = spt[start] || { resultCount: 0, checkCount: 1 };
      // const samplStatus = `${sampStatus.resultCount}/${sampStatus.checkCount}`;
      const newCol = (
        <Col span={6} key={spt[start].reportId}>
          <Card
            hoverable={true}
            className="facility-card__body"
            style={{ backgroundColor: generateRadomColor(), marginBottom: '1rem' }}
            onClick={adhocReporting(spt[start])}
          >
            <div className="card-content">
              {/* <div className="qtr-icon">
                <img
                  src={spt[start].icon ? spt[start].icon : WindPowerIcon}
                  className="calendar-6"
                  alt="calendar"
                />
              </div> */}
              <div className="qtr-subtext">{spt[start].reportTitle}</div>
              {/* <div className="qtr-text">{`(${
                spt[start].groupName
                  ? spt[start].groupName
                  : spt[start].measurementName
                  ? spt[start].measurementName
                  : 'Sample Point Type'
              })`}</div> */}

              <div
                className={`qtr-progress ${
                  spt[start].reportStatus.toLowerCase() === 'draft' ? '' : 'qtr-published'
                }`}
              >
                {getAdhocReportStatus(spt[start])}
              </div>
              <div className="qtr-month">{`${formatDate(
                new Date(spt[start].dueDate),
                'LLL do, yyyy'
              )}`}</div>
            </div>
          </Card>
        </Col>
      );

      content.push(newCol);

      start++;
      times++;
    }
    return content;
  }

  function notEverySamplePointCompleted(spt) {
    let disable = true;
    if (spt && spt.length > 0) {
      disable = !spt.every((sp) => sp.resultCount >= sp.checkCount);
    }
    return disable;
  }

  function renderReportingModal(records) {
    if (!records || records.length < 1)
      return (
        <>
          <div className="facility-report__body- reporting-modal-">
            {/* <div className="arrow-back" onClick={() => handleCancel('reporting')} /> */}
            {/* <Row gutter={16} type="flex" align="end" justify="end">
              <CloseIcon
                className="close-icon report-viewing"
                onClick={() => handleCancel('reporting')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row> */}
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    const { samplePoints } = records.data || [];
    const len = samplePoints && samplePoints.length ? samplePoints.length : 0;
    return (
      <>
        <div className=" onreporting-modal">
          <Breadcrumb separator="" className="onreporting-modal__breadcrumb-">
            <Breadcrumb.Item>{`${properCase(records.data.type)} Report`}</Breadcrumb.Item>
            <Breadcrumb.Separator>:</Breadcrumb.Separator>
            <Breadcrumb.Item href="" onClick={backToReportingQtr} className="clickable-breadcrumb">
              Quarters
            </Breadcrumb.Item>
            <Breadcrumb.Separator />
            <Breadcrumb.Item>{`${datelize(records.data.reportQuarter)} Quarter`}</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <Carousel>
            {samplePoints &&
              samplePoints.length > 0 &&
              samplePoints.map((spt, index) => {
                if (index % 2 === 0) {
                  return (
                    <div key={`${index}${spt.samplePointId}`}>
                      <Row gutter={10} style={{ overflowX: 'unset' }}>
                        {rowContent(samplePoints, index, records.data.reportId)}
                      </Row>
                    </div>
                  );
                }
              })}
          </Carousel>

          <br />

          <Form
            {...formItemLayout}
            // form={form}
            layout="horizontal"
            onFinish={() => {}}
            // className="facility-reporting__form submit-report-"
          >
            <div style={{ display: 'flex' }}>
              {published && (
                <div style={{ marginRight: '5px' }}>
                  <Button
                    type="secondary"
                    className="back-button"
                    onClick={() => handleCancel('reporting')}
                  >
                    Exit
                  </Button>
                </div>
              )}

              {!published && canPublishReport && (
                <div style={{ marginRight: '5px' }}>
                  <Popconfirm
                    title={<p>Are you sure you want to approve this report?</p>}
                    onConfirm={finalSubmitReport}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="primary"
                      appearance="default"
                      htmlType="submit"
                      disabled={
                        notEverySamplePointCompleted(samplePoints) ||
                        !canPublishReport ||
                        isPublishing
                      }
                    >
                      {isPublishing ? `Approving...` : `Approve`}
                    </Button>
                  </Popconfirm>
                </div>
              )}

              <div style={{ marginRight: '5px' }}>
                <Popconfirm
                  title={<p>Are you sure you want to send invoice for the report?</p>}
                  onConfirm={sendAdhocInvoice}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    appearance="default"
                    htmlType="submit"
                    disabled={sendingAdhocInvoice}
                  >
                    {!sendingAdhocInvoice ? 'Send Adhoc Invoice' : 'Sending . . .'}
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }

  function renderRecordingModal(record, type) {
    if (!record) {
      return (
        <div style={{ padding: '1rem', textAlign: 'center' }}>
          <Spin indicator={<LoadingOutlined />} size="small" />
        </div>
      );
    }

    if (record) {
      const {
        samplePointName,
        groupName: measurementName,
        minEntry,
        parameters: normalParameters,
        noiseParameters,
        reportStatus,
      } = record;

      const isPublished = reportStatus.toUpperCase() === 'PUBLISHED';
      const hasReachedMaxEntry = minEntry === samplePointRecordData?.length;

      const takeSampleData = (recod) => {
        const safeRecod = {
          entryDate: new Date(),
        };

        combinedSamplePointParameters.map((item) => {
          safeRecod[item.unit] = recod[item.unit] || 0;
          sampleDataAddFormRef.current.setFieldsValue({ [item.unit]: '' });
        });

        const newSptRecData = [...samplePointRecordData];
        newSptRecData.push(safeRecod);

        setSamplePointRecordData(newSptRecData);
      };

      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
              <strong style={{ fontSize: '1.5em', marginRight: '10px' }}>{samplePointName}</strong>
              <span style={{ color: 'gray' }}>({properCase(measurementName)})</span>
            </div>
            <div>
              {renderSampleStatusProgress(`${samplePointRecordData.length}/${minEntry}`, type)}
            </div>
          </div>
          <br />

          {canMakeFacilityReport && !isPublished && !hasReachedMaxEntry && (
            <Form
              {...formItemLayout}
              form={form}
              ref={sampleDataAddFormRef}
              onFinish={takeSampleData}
              onFinishFailed={() => {}}
            >
              <div
                style={{
                  border: '1px solid #c4c4c4',
                  padding: '0.5rem',
                  borderRadius: '10px',
                  marginBottom: '10px',
                }}
              >
                {generateCol(combinedSamplePointParameters)}
              </div>
              <Form.Item
                {...tailLayout}
                shouldUpdate={(_, currentValues) =>
                  combinedSamplePointParameters &&
                  combinedSamplePointParameters.every(
                    (pt) => !!currentValues[pt.unit] || Number(currentValues[pt.unit]) === 0
                  )
                }
              >
                {({ getFieldValue }) => {
                  const noParamterValue = () => {
                    if (!combinedSamplePointParameters) return true;
                    const someNull = combinedSamplePointParameters.some(
                      (pt) => !getFieldValue(pt.unit) && parseInt(getFieldValue(pt.unit)) !== 0
                    );
                    return someNull;
                  };
                  return (
                    <Button
                      type="primary"
                      appearance="default"
                      htmlType="submit"
                      disabled={noParamterValue()}
                      style={{ width: '100px' }}
                    >
                      {samplePointRecordData.length === 0 ? 'Add' : 'Add'}
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          )}

          <FacilityUploadTable
            userCategory={userCategory}
            userPermission={userPerm}
            data={samplePointRecordData}
            setData={setSamplePointRecordData}
            parameters={combinedSamplePointParameters}
            //tableType={publishedX ? 'published-view' : ''}
            //published={publishedX}
          />
          <br />
          <Form
            {...formItemLayout}
            scrollToFirstError
            onFinish={() => handleSubmitStandardMeasurements(type)}
            onFinishFailed={() => {}}
          >
            <Button
              type="secondary"
              style={{ marginRight: '5px' }}
              onClick={() => {
                if (type === 'adhoc') handleCancel('adhoc-recording');
                else setModal('reporting');
              }}
              disabled={loadingSaveRecords}
            >
              Back
            </Button>
            {canMakeFacilityReport && (
              <Button
                type="primary"
                appearance="default"
                htmlType="submit"
                disabled={loadingSaveRecords || isPublished}
              >
                Save{' '}
                {loadingSaveRecords && <Spin indicator={<LoadingOutlined spin />} size="small" />}
              </Button>
            )}
          </Form>
        </div>
      );
    }
  }

  function renderViewingModal(records) {
    if (!records || !records.data)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('viewing')} /> */}
            <Row gutter={16}>
              <CloseIcon
                className="close-icon facility-viewing"
                onClick={() => handleCancel('viewing')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row>
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );

    return (
      <>
        <div className="facility-reporting__body onviewing-modal onrecording-modal">
          {/* <div className="arrow-back" onClick={() => handleCancel('viewing')} /> */}
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => handleCancel('viewing')}
            style={{
              marginBottom: '1rem',
              marginTop: '0.8rem',
            }}
          />
          <FacilityView record={records.data} />
        </div>
      </>
    );
  }

  function renderCertViewingModal(records) {
    let bas64 = records.data.eiaCert;
    const fileType = bas64 ? bas64.substring(5).split(';')[0] : '';
    if (!records || !records.data)
      return (
        <>
          <div className="facility-report__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('viewing')} /> */}
            <Row gutter={16}>
              <CloseIcon
                className="close-icon facility-viewing"
                onClick={() => handleCancel('certviewing')}
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.8rem',
                }}
              />
            </Row>
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <LoadingContent modal={true} />
            </Row>
          </div>
        </>
      );
    if (!records.data.eiaCert)
      return (
        <>
          <div className="thereport__body">
            {/* <div className="arrow-back" onClick={() => handleCancel('adhoc')} /> */}
            <Row
              gutter={16}
              style={{
                marginTop: '1.5rem',
              }}
            >
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
                className="sub-heading bold"
              >
                No Certicate Found for this Facility.
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
                className="sub-heading"
              >
                Click the button below to upload EIA certificate.
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                <Upload {...generateUploadProps('new')} className="cert-upload-btn">
                  <Button appearance="default" type="primary">
                    <UploadOutlined /> Upload Certificate
                  </Button>
                </Upload>
              </Col>
            </Row>
          </div>
        </>
      );
    return (
      <>
        <div className="facility-reporting__body onviewing-modal certview-modal">
          <object data={bas64} width="100%" height="100%" />
        </div>
      </>
    );
  }

  function refreshPage() {
    window.location.reload();
  }

  const filterObjs = [
    {
      label: 'Filter by facility status',
      name: 'status',
      placeholder: 'Filter by status',
      title: 'All Facility Status',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by sector',
      name: 'sectorName',
      placeholder: 'Filter by sectors',
      title: 'All Sectors',
      condition: true,
      className: '',
    },
    {
      label: 'Filter by states',
      name: 'stateName',
      placeholder: 'Filter by state',
      title: 'All States',
      condition: true,
      className: '',
    },
    // {
    //   label: 'Filter by facility',
    //   name: 'facilityName',
    //   placeholder: 'Filter by facility name',
    //   title: 'All Facilities',
    //   condition: true,
    //   className: '',
    // },
    {
      label: 'Filter by consultant',
      name: 'facilityConsultant',
      placeholder: 'Filter by consultant',
      title: 'All Consultants',
      condition: isNotConsultant,
      className: '',
    },
    {
      label: 'Filter by EIA Status',
      name: 'facilityEia',
      placeholder: 'Filter by EIA status',
      title: 'All EIA Status',
      condition: isNotConsultant,
      className: '',
    },
  ];

  const filteringStates = [
    consultantFilter,
    facilityFilter,
    statusFilter,
    sectorFilter,
    stateFilter,
    eiaFilter,
  ];

  const filteringActions = [
    setConsultantFilter,
    setFacilityFilter,
    setStatusFilter,
    setSectorFilter,
    setStateFilter,
    setEiaFilter,
  ];

  const isItFiltering = () => {
    return filteringStates.every((ftState) => {
      return Boolean(ftState) === false;
    });
  };

  const toggleFilter = () => setFiltering(!filtering);

  const clearFilter = () => {
    filterFormRef.current.resetFields();
    return filteringStates.forEach((ftState, index) => ftState && filteringActions[index](''));
  };

  const filterOnChange = (val, which) => {
    switch (which) {
      case 'facilityConsultant':
        setConsultantFilter(String(val).trim());
        break;
      case 'facilityName':
        setFacilityFilter(val);
        break;
      case 'stateName':
        setStateFilter(val);
        break;
      case 'sectorName':
        setSectorFilter(val);
        break;
      case 'status':
        setStatusFilter(val);
        break;
      case 'facilityEia':
        setEiaFilter(val);
        break;

      default:
        break;
    }
  };

  const filterOnSearch = (val, which) => {
    switch (which) {
      case 'facilityConsultant':
        setConsultantFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'facilityName':
        setFacilityFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'stateName':
        setStateFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'sectorName':
        setSectorFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'status':
        setStatusFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;
      case 'facilityEia':
        setEiaFilter(
          String(val)
            .toLowerCase()
            .trim()
        );
        break;

      default:
        break;
    }
  };

  // const displayOptions = (list, which) => {
  //   let key = list;
  //   let { [key]: name } = { facilities };
  //   let uniques;

  //   if (name && name.length > 0) {
  //     if (which === 'facilityConsultant') {
  //       uniques = [
  //         ...new Set(
  //           name.map((item) =>
  //             item[which]
  //               ? properCase(`${item[which][0].firstName} ${item[which][0].lastName}`)
  //               : 'Not Available'
  //           )
  //         ),
  //       ];
  //     } else uniques = [...new Set(name.map((item) => item[which]))];

  //     uniques.sort((a, b) => {
  //       if (typeof a !== 'object') return a > b ? 1 : b > a ? -1 : 0;
  //       return a[which].localeCompare(b[which]);
  //     });

  //     return uniques.map((item, index) => {
  //       if (which === 'status') {
  //         return (
  //           <Option value={item} key={index}>
  //             {String(item) === '1' ||
  //             String(item)
  //               .trim()
  //               .toLowerCase() == 'active'
  //               ? 'ACTIVE'
  //               : 'INACTIVE'}
  //           </Option>
  //         );
  //       }
  //       if (which === 'facilityEia') {
  //         return (
  //           <Option value={item} key={index}>
  //             {String(item)
  //               .trim()
  //               .toLowerCase() === 'yes'
  //               ? 'ACTIVE'
  //               : 'INACTIVE'}
  //           </Option>
  //         );
  //       }
  //       return (
  //         <Option value={item} key={index}>
  //           {which === 'reportQuarter' ? `${datelize(item)} Quarter` : item}
  //         </Option>
  //       );
  //     });
  //   }
  // };

  // const filterContent = (
  //   <Form
  //     className={`filtering-search`}
  //     {...formItemLayout}
  //     layout="horizontal"
  //     ref={filterFormRef}
  //   >
  //     <Row>
  //       {filterObjs.map((fltO) => {
  //         return fltO.condition ? (
  //           <Col span={10} key={fltO.name}>
  //             <Form.Item
  //               label={fltO.label}
  //               name={fltO.name}
  //               className={fltO.className}
  //               style={{ marginBottom: '10px', display: 'inline-block' }}
  //             >
  //               <Select
  //                 showSearch
  //                 style={{ width: '12.8em' }}
  //                 placeholder={fltO.placeholder}
  //                 optionFilterProp="children"
  //                 onChange={(value) => filterOnChange(value, `${fltO.name}`)}
  //                 onSearch={(value) => filterOnSearch(value, `${fltO.name}`)}
  //                 filterOption={(input, option) =>
  //                   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //                 }
  //               >
  //                 <Option value="">{`${fltO.title}`}</Option>
  //                 {displayOptions('facilities', `${fltO.name}`)}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //         ) : (
  //           ''
  //         );
  //       })}
  //     </Row>
  //   </Form>
  // );

  // function searchTable(args) {
  //   const max = 100000;
  //   if (args) {
  //     setSearch(true);
  //     setIsSearching(true);
  //     getFacilitiesLists(`?search=${args}&start=0&stop=${max}`, 'search');
  //   } else {
  //     setSearch(true);
  //     setIsSearching(true);
  //     getFacilitiesLists();
  //     // setSearch(false);
  //   }
  // }

  return (
    <>
      <PageHeader split={true} className="facility-header__main">
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="end"
          justify="end"
          style={{ width: '100%' }}
        >
          {canCreateFacility && (
            <Col style={{ align: 'start', justify: 'start', flex: 'auto' }}>
              {canCreateFacility && (
                <div className="buttons left create-fac-btn">
                  <Button appearance="blue" size="small" onClick={createFacility}>
                    <PlusIcon /> New Facility
                  </Button>
                </div>
              )}
            </Col>
          )}

          <Col gutter={2}>
            {/* <div style={{ marginRight: '10px' }}>
              <Filter
                onClick={toggleFilter}
                content={filterContent}
                isFiltering={!isItFiltering()}
                clearFilter={clearFilter}
              />
            </div> */}
            <Input
              placeholder="Search facility"
              prefix={<SearchOutlined />}
              onPressEnter={() => setSearch(searchValue)}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            {/* <SearchInput
              placeholder="Search facilities"
              //searchTable={searchTable}
              className="ml-1rem"
            /> */}
          </Col>
        </Row>
      </PageHeader>

      <PageContent>
        {/* {page !== 'loading' && page !== 'error' && facilities && (facilities.length > 0 || search) && (
          <div className="facility-table">
            <FacilitiesTable
              userCategory={userCategory}
              userPerm={userPerm}
              data={facilities}
              setRecord={setRecord}
              setModal={setModal}
              loading={isSearching}
              consultantFilter={consultantFilter}
              facilityFilter={facilityFilter}
              statusFilter={statusFilter}
              sectorFilter={sectorFilter}
              stateFilter={stateFilter}
              eiaFilter={eiaFilter}
              setPage={setPage}
            />
          </div>
        )} */}
        <div className="facility-table">
          <FacilityListTable
            userCategory={userCategory}
            userPermissions={userPerm}
            setRecord={setRecord}
            setModal={setModal}
            setPage={setPage}
            search={search}
            facilityRecord={(quarters) => {
              setFacilityRecord(quarters);
            }}
          />
        </div>

        {(canViewReport || canCreateFacilityReport) && modal === 'selectqtr' && (
          <Modal
            open={true}
            title={
              <div style={{ display: 'flex' }}>
                <h3 style={{ flexGrow: 1 }}>{record?.data?.facilityName}</h3>
                <CloseIcon className="close-icon" onClick={() => handleCancel('viewing')} />
              </div>
            }
            footer={null}
            centered={true}
            onOk={handleOk}
            onCancel={() => handleCancel('selecting')}
            className="standard-report-modal"
            //className="facility-report__standard"
          >
            {renderSelectModal(facilityRecord)}
          </Modal>
        )}

        {canRecordReport && modal === 'recording' && (
          <Modal
            open={true}
            title={
              <div style={{ display: 'flex' }}>
                <h3 style={{ flexGrow: 1 }}>{record?.data?.facilityName}</h3>
                <CloseIcon className="close-icon" onClick={() => handleCancel('viewing')} />
              </div>
            }
            footer={null}
            onOk={handleOk}
            centered={true}
            className="standard-report-modal"
          >
            {renderRecordingModal(samplePointRecord, 'standard')}
          </Modal>
        )}
        {canRecordReport && modal === 'adhocrecording' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(samplePointRecord, 'adhocrecording', 'adhoc-recording')}
            footer={null}
            onOk={handleOk}
            centered={true}
            className="facility-report__standard recording"
          >
            {renderRecordingModal(samplePointRecord, 'adhoc')}
          </Modal>
        )}
        {canViewReport && modal === 'viewing' && (
          <Modal
            visible={!!record.data}
            title={renderModalHeader(record, 'viewing')}
            footer={null}
            maskClosable={false}
            centered={true}
            keyboard={false}
            onOk={handleOk}
            onCancel={() => handleCancel('viewing')}
            className="facility-report__standard recording fac-viewing"
          >
            {renderViewingModal(record)}
          </Modal>
        )}
        {canViewReport && modal === 'viewingcert' && (
          <Modal
            visible={!!record.data}
            title={renderModalHeader(record, 'viewingcert')}
            footer={null}
            maskClosable={false}
            centered={true}
            keyboard={false}
            onOk={handleOk}
            onCancel={() => handleCancel('viewing')}
            className={`facility-report__standard recording fac-cert-viewing ${
              record.data.eiaCert ? '' : 'subY22'
            }`}
          >
            {renderCertViewingModal(record)}
          </Modal>
        )}
        {canViewReport && canViewRecordedReport && modal === 'reporting' && (
          <Modal
            open={true}
            title={
              <div style={{ display: 'flex' }}>
                <h3 style={{ flexGrow: 1 }}>{record?.data?.facilityName}</h3>
                <CloseIcon className="close-icon" onClick={() => handleCancel('viewing')} />
              </div>
            }
            footer={null}
            onOk={handleOk}
            onCancel={() => handleCancel('reporting')}
            //className="facility-report__standard reporting"
            className="standard-report-modal"
          >
            {renderReportingModal(report)}
          </Modal>
        )}
        {canLockReport && modal === 'locking' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(record, 'locking')}
            footer={null}
            centered={true}
            onOk={handleOk}
            onCancel={() => handleCancel('locking')}
            className="facility-report__standard"
          >
            {renderLockingModal(facilityRecord)}
          </Modal>
        )}
        {canCreateAdhocReport && modal === 'adhoc' && (
          <Modal
            visible={true || !!record.data}
            title={renderModalHeader(record, 'adhoc')}
            footer={null}
            onOk={handleOk}
            centered={true}
            onCancel={() => handleCancel('adhoc')}
            className={`facility-report__standard adhoc ${
              adhocStep === 'createNewAdhocReport'
                ? 'add-x2'
                : adhocStep === 'adhocReporting'
                ? 'add-x5'
                : ''
            }`}
          >
            {renderAdhocModal(adhocReport)}
          </Modal>
        )}
        {modal === 'viewinvoices' && (
          // <Modal
          //   visible={!!record.data}
          //   title={renderFacilityInvoiceHeader()}
          //   footer={null}
          //   maskClosable={false}
          //   centered={true}
          //   keyboard={false}
          //   onOk={handleOk}
          //   onCancel={() => handleCancel('viewing')}
          //   className={`facility-report__standard recording fac-cert-viewing ${
          //     record.data.eiaCert ? '' : 'subY22'
          //   }`}
          // >
          //   <FacilityInvoiceList record={record.data} />
          // </Modal>
          <Modal
            open={true}
            title={
              <div style={{ display: 'flex' }}>
                <h3 style={{ flexGrow: 1 }}>{record?.data?.facilityName}</h3>
                <CloseIcon className="close-icon" onClick={() => handleCancel('viewing')} />
              </div>
            }
            footer={null}
            onOk={handleOk}
            centered={true}
            className="standard-report-modal"
          >
            <FacilityInvoiceList record={record.data} />
            {/* {renderRecordingModal(samplePointRecord, 'standard')} */}
          </Modal>
        )}
      </PageContent>
    </>
  );
}
