import React, { useState, useEffect } from 'react';
import { Form, message, InputNumber, Row, Col, Divider } from 'antd';
import Input from '../../../../components/common/AntInput';
import Button from '../../../../components/common/Button';
import Table from '../../../../components/common/Table';
import { addParameters } from '../../../../helpers/api';
import { DeleteOutlined } from '@ant-design/icons';

// let parameters = [];

const AddParameterForm = ({ closeModal, reload, measurementId }) => {
  const [submitParameters, setSubmitParameters] = useState(false);
  const [parameterCollection, setParameterCollection] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const storeParameters = (params) => {
    // parameters.push(params);
    setParameterCollection([...parameterCollection, params]);
  };

  useEffect(() => {
    if (submitParameters) {
      handleParameters();
    }
  }, [submitParameters]);

  const removeParameter = (ev) => {
    const filteredData = parameterCollection.filter((params) => params.unit !== ev);
    if (filteredData && filteredData.length) {
      setParameterCollection(filteredData);
    }
  };

  const columns = [
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'SI Unit',
      dataIndex: 'siUnit',
      key: 'siUnit',
    },
    {
      title: 'NESERA limit',
      dataIndex: 'nesrea',
      key: 'nesrea',
    },
    // {
    //   title: 'NESERA limit (lower)',
    //   dataIndex: 'nesreaLowerLimit',
    //   key: 'nesreaLowerLimit',
    // },
    // {
    //   title: 'NESERA limit (upper)',
    //   dataIndex: 'nesreaUpperLimit',
    //   key: 'nesreaUpperLimit',
    // },
    {
      title: 'World Bank Limit',
      dataIndex: 'world',
      key: 'world',
    },
    // {
    //   title: 'World Bank limit (lower)',
    //   dataIndex: 'worldLowerLimit',
    //   key: 'worldLowerLimit',
    // },
    // {
    //   title: 'World Bank limit (upper)',
    //   dataIndex: 'worldUpperLimit',
    //   key: 'worldUpperLimit',
    // },
    {
      title: '',
      dataIndex: 'unit',
      key: 'unit',
      render: (unit) => (
        <>
          {' '}
          <Button id={unit} onClick={(e) => removeParameter(unit)} className="transparent__bg">
            <DeleteOutlined className="text-danger" />
          </Button>{' '}
        </>
      ),
    },
  ];

  const previewParameter = () => {
    if (parameterCollection && parameterCollection.length > 0) {
      return (
        <Table columns={columns} dataSource={parameterCollection} rowClassName="parameter__table" />
      );
    } else {
      return null;
    }
  };

  const handleSubmitParameters = () => {
    setSubmitParameters(!submitParameters);
  };

  // HANDLE  PARAMETERS
  const handleParameters = (values) => {
    if (submitParameters !== true) {
      storeParameters(values);
      form.resetFields();
    }
    if (submitParameters === true) {
      setLoading(true);
      addParameters(parameterCollection, measurementId)
        .then((res) => {
          setLoading(false);
          message.success(`Parameters added successfully`);
          reload();
          closeModal();
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleParameters} hideRequiredMark={true}>
        <Row gutter={10}>
          <Col lg={12}>
            <Form.Item
              name="unit"
              label="Parameter Name"
              rules={[{ required: true, message: 'Please enter parameter name' }]}
            >
              <Input />
              
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              name="siUnit"
              label="SI Unit"
              rules={[{ required: true, message: 'SI Unit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              name="nesrea"
              label="NESREA Limit"
              rules={[{ required: true, message: 'NESREA limit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              name="world"
              label="World Bank Limit"
              rules={[{ required: true, message: 'World Bank limit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          {/* <Col lg={6}>
            <Form.Item
              name="nesreaLowerLimit"
              label="NESREA Limit (lower)"
              className="parameter__input"
              rules={[{ required: true, message: 'NESREA Lower limit is required' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              name="nesreaUpperLimit"
              label="NESREA Limit (upper)"
              className="parameter__input"
              rules={[{ required: true, message: 'NESREA Upper limit is required' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              name="worldLowerLimit"
              label="World Bank Limit (lower)"
              className="parameter__input"
              rules={[{ required: true, message: 'World Bank Lower limit is required' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              name="worldUpperLimit"
              label="World Bank Limit (upper)"
              className="parameter__input"
              rules={[{ required: true, message: 'World Bank Upper limit is required' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col> */}
          
        </Row>

        <Row gutter={10}></Row>

        <Form.Item className="text-right">
          <Button htmlType="submit" type="primary" appearance="default" loading={loading}>
            Add
          </Button>
        </Form.Item>
      </Form>

      <Divider />

      <div>
        <Button
          onClick={handleSubmitParameters}
          htmlType="button"
          type="primary"
          appearance="default"
          className="mr-15"
          loading={loading}
          disabled={!parameterCollection.length}
        >
          Save
        </Button>

        <Button onClick={closeModal} htmlType="button" type="secondary" loading={loading}>
          Cancel
        </Button>
      </div>
      <div className="preview__parameter mt-2rem">
        {previewParameter()}
        {/* <Table columns={columns} dataSource={parameters && parameters.length > 0 && parameters} /> */}
      </div>
    </>
  );
};

export default AddParameterForm;
