import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../../components/common/CloseIcon';
import { Row, Col } from 'antd';

const ModalHeader = ({ className, closeModal }) => {
  return (
    <div className={className}>
      <span>
        <CloseIcon onClick={closeModal} />
      </span>
      <h3 className="heading mb-10">Invite New User</h3>
      <Row>
        <Col span={16}>
          <p className="">
            Enter the email addresses of the users you'd like to invite, and choose the role they
            should have.
          </p>
        </Col>
      </Row>
      {/* <TagInput invite={invite} /> */}
    </div>
  );
};

export default styled(ModalHeader)`
  h3 {
    font-family: var(--font-family);
  }
  p {
    color: #828ea9;
    font-size: 14px;
    font-family: var(--font-family);
  }

  .invite-input {
    background: #fff;
    height: 39px;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 1rem;
    padding: 0 20px
        &:focus {
      border: 1px solid #007ace !important;
      border-radius: 5px;
    }
  }
`;
