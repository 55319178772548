import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, message } from 'antd';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/AntInput';
import LoadingContent from '../../../components/common/LoadingContent';
import { updateProfile } from '../../../helpers/api';
import { loadState } from '../../../store/localStorage';
import { openNotification } from '../../../helpers/notification';
import { UserContext } from '../../../store/UserContext';
import './profile.css';

const ChangeProfileForm = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { getUserData, userData } = useContext(UserContext);

  const formRef = useRef();

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (reload) {
      getUserData();
    }
  }, [reload]);

  useEffect(() => {
    console.log(initValues, '===');
  }, []);

  const initValues =
    userData !== null
      ? {
          firstname: userData.firstname,
          lastname: userData.lastname,
          phone: userData.phone,
          email: userData.email,
        }
      : null;

  const submitProfileForm = (values) => {
    const id = loadState().auth.result.data.userId;
    setLoading(true);
    console.log(values);
    updateProfile(values, id)
      .then((res) => {
        setLoading(false);
        message.success('Profile details updated successfully!');
        setReload(true);
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Error updating profile details',
          message: error,
        });
      });
  };
  return (
    <div className={className}>
      {userData === null ? (
        <LoadingContent modal={true} />
      ) : (
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={submitProfileForm}
          initialValues={initValues}
        >
          <Row justify="space-between" gutter={12}>
            <Col lg={{ span: 12 }}>
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const regex = /^[A-Za-z]+$/;
                      if (!value || (value && regex.test(value))) {
                        if (!value) return Promise.reject('First name cannot be empty');
                        return Promise.resolve();
                      }
                      return Promise.reject('First name should contain only characters');
                    },
                  }),
                ]}
              >
                <Input type="text" className="form-control" required />
              </Form.Item>
            </Col>
            <Col
              lg={{
                span: 12,
              }}
            >
              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[{ required: true, message: 'Last name cannot be empty' }]}
              >
                <Input className="form-control" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={12}>
            <Col
              md={{
                span: 12,
              }}
              lg={{
                span: 12,
              }}
            >
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const regex = /^([0-9]){11}$/;
                      if (!value || (value && regex.test(value))) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Phone Number should be 11 digits');
                    },
                  }),
                ]}
              >
                <Input className="form-control" type="text" />
              </Form.Item>
            </Col>
            <Col
              md={{
                span: 12,
              }}
              lg={{
                span: 12,
              }}
            >
              <Form.Item className="form-group email-form" name="email" label="Email Address">
                <Input type="email" className="form-control" disabled readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="form-group">
            <Button
              appearance="default"
              type="primary"
              htmlType="submit"
              className="save_btn"
              loading={loading}
            >
              {loading ? 'Saving ...' : 'Save Changes'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default styled(ChangeProfileForm)`
  font-family: var(--font-family);
  label {
    font-weight: 600;
    font-size: 14px;
  }
  .ant-form-item-label > label.ant-form-item-required {
    &:before {
      display: none;
    }
  }
  .ant-form-explain {
    margin-top: 0 !important;
  }
  .form-control {
    // width: 99%;
    padding: 0px 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: solid 1px #d8d8d8;
    border-radius: 5px;
    background: transparent;
    margin-bottom: unset !important;
    height: 41px;
    &:focus {
      border: 1px solid #0c5bab;
      outline: none !important;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      background: transparent;
      margin-bottom: var(--input-mb);
      &:focus {
        border: 1px solid #0c5bab;
        outline: none;
        box-shadow: none;
      }
    }
  }
`;
