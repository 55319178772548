import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Analytics from '../components/screens/analytics';

export default (props) => {
  return (
    <Layout headerTitle={'Analytics'}>
      <PageContainer>
        <Analytics props={props} id="analytics" />
      </PageContainer>
    </Layout>
  );
};
