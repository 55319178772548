import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Tabs, Menu, DatePicker, Divider } from 'antd';
import moment from 'moment';
import GeneralReportIcon from '../../../assets/img/general_reports.svg';
// import { DownOutlined } from '@ant-design/icons';
// import Select from 'components/common/Select';
// import Dropdown from 'components/common/Dropdown';
import Button from '../../common/Button';
import { adminReportMenu } from '../../../helpers/api';
import { generateReport } from '../../../helpers/utils';
import './style.css';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const currentDay = moment(new Date()).format(dateFormat);

const endOfYear = moment()
  .endOf('year')
  .format(dateFormat);

const initialDatePicker = [moment(currentDay), moment(endOfYear)];

const getUnixTime = (time) => {
  let returnTime = [];
  time && time.length && time.forEach((tm) => returnTime.push(new Date(tm).valueOf()));
  return returnTime;
};

// const getMomentTime = (unixT) => {
//   let returnTime = [];
//   unixT &&
//     unixT.length &&
//     unixT.forEach((tm) => returnTime.push(moment(moment(new Date(tm)).format(dateFormat))));
//   return returnTime;
// };

const Logs = () => {
  // const dateRef = useRef(null);
  const [selectedTime, setTabSelectedTime] = useState(getUnixTime(initialDatePicker));
  const tabPanes = [
    // {
    //   tabName: 'Quarterly Report',
    //   tabClass: 'compliance',
    //   tabKey: 'noncompliance',
    //   tabId: 'compliance__report',
    //   tabReportName: 'facilityreport',
    //   // tabParameter: {
    //   //   starttime: Math.round(selectedTime[0] / 1000),
    //   //   endtime: Math.round(selectedTime[1] / 1000),
    //   // },
    // },
    {
      tabName: 'Email Logs',
      tabClass: 'compliance',
      tabKey: 'sample',
      tabId: 'compliance__report',
      tabReportName: 'emailmessages',
      // tabParameter: {
      //   starttime: Math.round(selectedTime[0] / 1000),
      //   endtime: Math.round(selectedTime[1] / 1000),
      // },
    },
    {
      tabName: 'Payment Log',
      tabClass: 'compliance',
      tabKey: 'audit',
      tabId: 'compliance__report',
      tabReportName: 'paymentLog',
      // tabParameter: {
      //   starttime: Math.round(selectedTime[0] / 1000),
      //   endtime: Math.round(selectedTime[1] / 1000),
      // },
    },
    // {
    //   tabName: 'Sample Point Report',
    //   tabClass: 'compliance',
    //   tabKey: 'sample',
    //   tabId: 'compliance__report',
    //   tabReportName: 'samplepoint.trdp',
    //   tabParameter: {
    //     starttime: Math.round(selectedTime[0] / 1000),
    //     endtime: Math.round(selectedTime[1] / 1000),
    //   },
    // },
    // {
    //   tabName: 'Compliance Report',
    //   tabClass: 'compliance',
    //   tabKey: 'compliance',
    //   tabId: 'compliance__report',
    //   tabReportName: 'Compliance.trdp',
    //   tabParameter: {
    //     starttime: Math.round(selectedTime[0] / 1000),
    //     endtime: Math.round(selectedTime[1] / 1000),
    //   },
    // },
    // {
    //   tabName: 'Non Compliance Report',
    //   tabClass: 'compliance',
    //   tabKey: 'noncompliance',
    //   tabId: 'compliance__report',
    //   tabReportName: 'NonCompliance.trdp',
    //   tabParameter: {
    //     starttime: Math.round(selectedTime[0] / 1000),
    //     endtime: Math.round(selectedTime[1] / 1000),
    //   },
    // },
    // {
    //   tabName: 'Emission Stats',
    //   tabClass: 'compliance',
    //   tabKey: 'emission',
    //   tabId: 'compliance__report',
    //   tabReportName: 'EmissionStats.trdp',
    //   tabParameter: {
    //     starttime: Math.round(selectedTime[0] / 1000),
    //     endtime: Math.round(selectedTime[1] / 1000),
    //   },
    // },
    // {
    //   tabName: 'Density Grid',
    //   tabClass: 'compliance',
    //   tabKey: 'density',
    //   tabId: 'compliance__report',
    //   tabReportName: 'DensityGrid.trdp',
    //   tabParameter: {
    //     starttime: Math.round(selectedTime[0] / 1000),
    //     endtime: Math.round(selectedTime[1] / 1000),
    //   },
    // },
  ];

  const [tabSelected, setTabSelected] = useState(tabPanes[0]);
  const [generated, setGenerated] = useState({});

  // useEffect(() => {
  //   refreshReportMenu();

  //   if (generated && generated[tabSelected['tabKey']]) {
  //     const currentSelected = { ...tabSelected };
  //     const currentTabParameter = { ...tabSelected['tabParameter'] };
  //     currentTabParameter['starttime'] = Math.round(selectedTime[0] / 1000);
  //     currentTabParameter['endtime'] = Math.round(selectedTime[1] / 1000);
  //     currentSelected['tabParameter'] = currentTabParameter;

  //     generateReport(
  //       tabSelected.tabReportName,
  //       currentSelected.tabParameter,
  //       `#${tabSelected.tabKey}-${tabSelected.tabClass}__report`
  //     );
  //   }

  //   return () => {};
  // }, [tabSelected, generated]);

  // const refreshReportMenu = () => {
  //   adminReportMenu()
  //     .then((res) => {})
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };

  const changeReportTab = (selectdPane) => {
    //console.log(selectdPane)
    const selTab = tabPanes.filter((tbPane) => tbPane.tabKey === selectdPane);
    if (selTab && selTab[0]) {
      setTabSelected(selTab[0]);
    }
  };

  // const chooseTimePeriod = (_, timeP) => {
  //   setTabSelectedTime(getUnixTime(timeP));
  // };

  // function genReport() {
  //   const formerGenerated = { ...generateReport };
  //   console.log('generatedFormat', formerGenerated);
  //   console.log('generated', formerGenerated);
  //   const formerTabParameter = { ...tabSelected['tabParameter'] };
  //   formerTabParameter['starttime'] = Math.round(selectedTime[0] / 1000);
  //   formerTabParameter['endtime'] = Math.round(selectedTime[1] / 1000);
  //   formerGenerated[tabSelected['tabKey']] = formerTabParameter;
  //   formerGenerated['tabParameter'] = formerTabParameter;

  //   setGenerated(formerGenerated);
  // }

  // const isNewGenDate = () => {
  //   // dateRef.current.props.defaultValue
  //   let disable = true;
  //   if (selectedTime && generated && generated[tabSelected['tabKey']]) {
  //     const selTab = generated[tabSelected['tabKey']];
  //     const genStartTime = selTab.starttime;
  //     const genEndTime = selTab.endtime;
  //     if (
  //       Math.round(selectedTime[0] / 1000) !== genStartTime ||
  //       Math.round(selectedTime[1] / 1000) !== genEndTime
  //     ) {
  //       disable = false;
  //     }
  //   }

  //   return disable;
  // };

  return (
    <div
      className={`admin__reports ${
        !generated || !generated[tabSelected['tabKey']] ? 'generated' : ''
      }`}
    >
      <Tabs tabPosition="left" className="audit__tabs" onChange={changeReportTab}>
        {tabPanes.map((tbPane) => (
          <TabPane
            tab={tbPane.tabName}
            key={tbPane.tabKey}

            //className={`${!generated || !generated[tabSelected['tabKey']] ? 'text-center' : ''}`}
          >
            <div style={{ border: '0px solid gray', margin: '-30px 0 0 -32px', padding: 0 }}>
              <div style={{ height: '100vh', width: '100%', border: 'none' }}>
                <iframe
                  src={`${window.TELERIK_BASE_URL}/${tabSelected.tabReportName}`}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  title="Facility"
                />
              </div>
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Logs;
