import React, { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import { Tooltip, Row, Col, Checkbox } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib/icons';
import styled from 'styled-components';
import { json } from 'd3';
import LoadingContent from '../../../../components/common/LoadingContent';
import Filter from '../../../../components/common/Filter';
import { mergeArrays, parseString3, parseString4 } from '../../../../helpers/utils';
import googleMapStyles from './googleStyle';
import MarkerContent from './MarkerContent';
// import NigGeoJson from './nigeria_geojson.json';

const CheckboxGroup = Checkbox.Group;

// const avgOptions = ['fmEnv', 'worldBank'];
const avgOptions = ['fmEnvViolation', 'worldBankViolation'];

// const avgRadioOptions = [
//   { label: 'Fed. Ministry', value: avgOptions[0], name: 'envs2', className: 'envinput' },
//   { label: 'World Bank', value: avgOptions[1], name: 'envs2', className: 'envinput' },
// ];

function getArrayIndexes(arrayOptions, array) {
  const aChecked = [];
  array.map((dfc) => aChecked.push(arrayOptions.indexOf(dfc)));
  return aChecked;
}

const DashboardMap = (props) => {
  const { loading: initialLoading, className, error, currState, height, fetch, url } = props;
  const [data, setData] = useState();
  const [localError, setLocalError] = useState(error);
  const [loading, setLoading] = useState(initialLoading);
  const [mounted, setMounted] = useState(false);
  const [measurementOptions, setMeasurementOptions] = useState([]);
  const [map, setMap] = useState();
  const [theStates, setTheStates] = useState();
  const [chords, setChords] = useState({});
  const [markers, setMarkers] = useState();
  const [allMarkers, setAllMarkers] = useState();

  const mapRef = useRef(null);

  const defaultMCheckedList = measurementOptions.slice(0, 3);
  let msrementsOptions = [];
  if (data) msrementsOptions = Object.keys(data);

  const getAllMOptions = (data = {}) => {
    const reformatKeys = Object.values(data);

    const msrementsOptions = mergeArrays([...reformatKeys.map(Object.keys)]);
    const allMsrementsOptions = [];
    msrementsOptions.map((msre) =>
      allMsrementsOptions.push({
        mType: msre,
        options: Object.keys(reformatKeys.filter((rK) => Object.keys(rK).includes(msre))[0][msre]),
      })
    );

    return allMsrementsOptions;
  };

  const getCurrMoptions = (data, mType, allMData = false) => {
    let mCheckedOptionsFilter;
    if (allMData) {
      mCheckedOptionsFilter = data.filter((m) => m.mType === mType);
    } else {
      mCheckedOptionsFilter = getAllMOptions(data).filter((m) => m.mType === mType);
    }
    if (mCheckedOptionsFilter && mCheckedOptionsFilter[0]) {
      return mCheckedOptionsFilter[0]['options'];
    }
    return [];
  };

  const defaultMCheckedOption = msrementsOptions[0];

  let mCheckedOptions = getCurrMoptions(data, defaultMCheckedOption);

  const initialMeasurementsState = {
    checkedList: defaultMCheckedList,
    checkedListIndexes: getArrayIndexes(mCheckedOptions, defaultMCheckedList),
    currM: defaultMCheckedOption,
    states: theStates,
    chords,
    indeterminate:
      !!defaultMCheckedList.length && defaultMCheckedList.length < mCheckedOptions.length,
    checkAll: defaultMCheckedList.length === mCheckedOptions.length,
  };

  const [measurementsState, setMeasurementsState] = useState(initialMeasurementsState);
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);

  const names = {
    fmEnv: 'fmEnvViolation',
    worldBank: 'worldBankViolation',
  };

  // const labels = {
  //   fmEnv: 'fmEnvViolation',
  //   worldBank: 'worldBankViolation',
  // };

  // let { [avgEnv]: value } = names;

  const drawMap = (map, mapData, checkListState) => {
    if (map) {
      //setting overlay color, etc.
      map.data.setStyle({
        fillColor: 'white',
        strokeWeight: 2,
        strokeColor: '#448CCB',
        fillOpacity: 1,
      });

      // here is the magic
      // let geojsonURL =
      // 'https://gist.githubusercontent.com/conquext/af536204ee275040a54b4eabb4fc9484/raw/5c0803165c247a8f0967de8df5f91f202900ca60/nig_geojson.json';
      // 'https://gist.githubusercontent.com/conquext/9472d54011b55a9ed0e21afd6bb7a20c/raw/0d0faa511d1f35f62b8ade5d24ab4e80d2a3fcf8/nig_geojson.geojson';
      //   'https://raw.githubusercontent.com/deldersveld/topojson/master/countries/nigeria/nigeria-regions.json';
      // 'https://gist.githubusercontent.com/conquext/5b656f4306d78f7ba1e20bc0b2771402/raw/a0c087f54b21629c4d1ea247b4f1fa6a876e0c42/countries.geojson';
      // json(geojsonURL).then((resp) => {
      //   map.data.loadGeoJson((resp));
      //   // map.data.loadGeoJson(resp);
      //   setMap(map);
      //   setMarkersInitially(map, mapData, checkListState);
      // });
      setMap(map);
      setMarkersInitially(map, mapData, checkListState);
      // map.data.loadGeoJson(JSON.parse(NigGeoJson));
    }
  };

  const render = (map, data, checkListState = measurementsState) => {
    drawMap(map, data, checkListState);
  };

  const renderMap = () => {
    if (!url) setLoading(true);
    else if (data) render(map, data, measurementsState, measurementOptions);
    else if (!localError)
      fetch(url)
        .then(({ result }) => {
          const res = result.data;
          if (res && res.length > 0) {
            const reformat = {};
            let chords = {};
            const theStates = res.map((rs) => rs.stateName);
            res.map((rData) => {
              if (rData && rData.measurements && rData.measurements.length > 0) {
                rData.measurements.map((rDataL2) => {
                  let stateName = rData.stateName;
                  let msreName = rDataL2.longName;
                  let unitName = rDataL2.unit;
                  let coord = rDataL2.stateCordinates;
                  let fmEnvViolation = rDataL2.fmEnvViolation || 0;
                  let worldBankViolation = rDataL2.worldBankViolation || 0;

                  if (!chords[stateName])
                    chords[stateName] = coord
                      ? { lat: parseFloat(coord.latitude), long: parseFloat(coord.longitude) }
                      : { lat: 0, long: 0 };
                  if (!chords[unitName])
                    chords[unitName] = coord
                      ? { lat: parseFloat(coord.latitude), long: parseFloat(coord.longitude) }
                      : { lat: 0, long: 0 };
                  if (!reformat[stateName]) {
                    const theStateMeasr = {};
                    const theMeasPrm = {};
                    theMeasPrm[unitName] = { fmEnvViolation, worldBankViolation };
                    theStateMeasr[msreName] = theMeasPrm;
                    reformat[stateName] = theStateMeasr;
                  } else if (!reformat[stateName][msreName]) {
                    const theMeasPrm = {};
                    theMeasPrm[unitName] = {
                      fmEnvViolation,
                      worldBankViolation,
                    };
                    reformat[stateName][msreName] = theMeasPrm;
                  } else if (!reformat[stateName][msreName][unitName]) {
                    reformat[stateName][msreName][unitName] = {
                      fmEnvViolation,
                      worldBankViolation,
                    };
                  } else if (reformat[stateName][msreName][unitName]) {
                    const shortName = reformat[stateName][msreName][unitName];

                    reformat[stateName][msreName][unitName] = {
                      fmEnvViolation: fmEnvViolation + shortName.fmEnvViolation,
                      worldBankViolation: worldBankViolation + shortName.worldBankViolation,
                    };
                  }
                });
              }
            });

            const reformatKeys = Object.values(reformat);

            const msrementsOptions = mergeArrays([...reformatKeys.map(Object.keys)]);

            const allMsrementsOptions = [];
            msrementsOptions.map((msre) =>
              allMsrementsOptions.push({
                mType: msre,
                options: Object.keys(
                  reformatKeys.filter((rK) => Object.keys(rK).includes(msre))[0][msre]
                ),
              })
            );

            const defaultMCheckedOption = msrementsOptions[0];
            const mCheckedOptions = allMsrementsOptions.filter(
              (m) => m.mType === defaultMCheckedOption
            )[0]['options'];

            const defaultMCheckedList = mCheckedOptions.slice(0, 3);

            const initialMeasurementsState = {
              checkedList: defaultMCheckedList,
              checkedListIndexes: getArrayIndexes(mCheckedOptions, defaultMCheckedList),
              currM: defaultMCheckedOption,
              indeterminate:
                !!defaultMCheckedList.length && defaultMCheckedList.length < mCheckedOptions.length,
              states: theStates,
              chords,
              checkAll: defaultMCheckedList.length === mCheckedOptions.length,
            };

            setMeasurementsState(initialMeasurementsState);
            setMeasurementOptions(msrementsOptions);
            setTheStates(theStates);
            setChords(chords);
            setData(reformat);
            render(initMap(), reformat, initialMeasurementsState, msrementsOptions);
          }
          if (loading) setLoading(false);
        })
        .catch((err) => {
          console.log('error fetching', err);
          if (loading && mounted) setLoading(false);
          if (mounted) setLocalError(true);
        });
  };

  const refresh = () => {
    setLocalError(false);
    setLoading(true);
    renderMap();
  };

  const changeAvgEnv = (e) => {
    setAvgEnv(e.target.value);
  };

  function getGoogleMaps() {
    if (!window.googleMapsPromise) {
      window.googleMapsPromise = new Promise((resolve) => {
        window.resolveGoogleMapsPromise = () => {
          resolve(window.google);
        };

        let script = document.createElement('script');
        let API = 'AIzaSyAdQ0vynulnP5YimxfGsZPtskcOnOLuyfk';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      });
    }

    return window.googleMapsPromise;
  }

  function initMap(latN = 9, longN = 8) {
    let googleMapDomNode = ReactDom.findDOMNode(mapRef.current);
    if (googleMapDomNode) {
      let zoom;
      let lat = parseFloat(latN);
      let lng = parseFloat(longN);

      // let center = { lat, lng };

      if (window.matchMedia('(min-width: 600px)').matches) {
        // re-arranges map center for smaller screens (phones, tablets etc.)
        zoom = 6;
      } else {
        zoom = 4;
      }

      if (currState && currState.stateName !== 'all') {
        let theChord = getCoordinates(currState.stateName.toUpperCase());
        if (theChord.lat && theChord.long) {
          lat = theChord.lat;
          lng = theChord.long;
          zoom *= 1.5;
        }
      }

      const mapOptions = {
        center: new window.google.maps.LatLng(lat, lng),
        zoom,
        zoomControl: false,
        // zoomControlOptions: {
        //   style: window.google.maps.ZoomControlStyle.DEFAULT,
        // },
        draggable: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.TOP_LEFT,
          style: window.google.maps.ZoomControlStyle.LARGE,
        },
        gestureHandling: 'greedy',
        // zoomControl: false,
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: true,
        scrollwheel: true,
        panControl: true,
        streetViewControl: false,
        // draggable: true,
        overviewMapControl: true,
        overviewMapControlOptions: {
          opened: false,
        },
        mapTypeId: window.google.maps.MapTypeId.TERRAIN,
        // mapTypeId: window.google.maps.MapTypeId.HYBRID,
        styles: googleMapStyles,
        disableDefaultUI: true,
      };

      let map = new window.google.maps.Map(googleMapDomNode, mapOptions);

      return map;
    }
  }

  useEffect(() => {
    try {
      // if (!mounted && !data) setLoading(true);
      if (!mounted) setMounted(true);
      if (mounted) {
        getGoogleMaps().then(() => {
          renderMap();
        });
      } else {
        return;
      }
    } catch (err) {
      console.log('caught: ', err);
      setLocalError(true);
    }
    return () => {
      setData('');
      return setMounted(false);
    };
  }, [mounted, url, localError, loading]);

  function setMarkersOnMap(markers) {
    for (let marker of markers) {
      marker.setAnimation(window.google.maps.Animation.DROP);
      marker.setMap(map);
    }
  }

  function clearAllMarkers() {
    for (let marker of allMarkers) {
      marker.setMap(null);
    }
  }

  function setNewMarkersState(mapData = data, measurementsState = measurementsState) {
    clearAllMarkers();
    const markTgt = getMarkersTarget(mapData, measurementsState);
    let newMarkers = generateMarkers(null, mapData, markTgt);

    setMarkers(newMarkers);
    setAllMarkers(newMarkers);
    setMarkersOnMap(newMarkers);
  }

  function setMarkersInitially(map = map, mapData, measurementsState) {
    const markTgt = getMarkersTarget(mapData, measurementsState);

    let markers = generateMarkers(map, mapData, markTgt);

    setAllMarkers(markers);
    setMarkers(markers);
  }

  // sets the mouseover, mouseout and click events for all markers
  function addEventListenersToMarker(map, marker, infoWindow) {
    marker.addListener('mouseover', () => {
      infoWindow.open(map, marker);
    });

    marker.addListener('mouseout', () => {
      infoWindow.close();
    });

    marker.addListener('click', () => {
      // setCurrentMarker(marker);
      // openDialog();
    });
  }

  function generateNewMState(selectedVal, xdata = data) {
    if (measurementOptions.includes(selectedVal)) {
      let mCheckedOptions = getAllParamOptions(xdata, selectedVal);
      const checkedList = mCheckedOptions.slice(0, 3);

      return {
        checkedList: checkedList,
        checkedListIndexes: getArrayIndexes(mCheckedOptions, checkedList),
        currM: selectedVal,
        states: Object.keys(data),
        chords,
        indeterminate: !!checkedList.length && checkedList.length < mCheckedOptions.length,
        checkAll: checkedList.length === mCheckedOptions.length,
      };
    }
    return measurementsState;
  }

  const selectMType = (e) => {
    const selectedVal = e.target.value;
    if (measurementOptions.includes(selectedVal)) {
      let newMState = generateNewMState(selectedVal);
      setMeasurementsState(newMState);
      setNewMarkersState(data, newMState);
      // messenger.call('changeMeasurements', this, selectedVal);
    }
  };

  const onCheckAllChange = (e) => {
    const formerMState = measurementsState;
    const formerCurrM = formerMState.currM;
    let mCheckedOptions = getAllParamOptions(data, formerCurrM);
    // let mCheckedOptions = getCurrMoptions(data, formerCurrM);

    const currentCheck = e.target.checked ? mCheckedOptions : [mCheckedOptions[0]];

    const newMState = {
      checkedList: currentCheck,
      checkedListIndexes: getArrayIndexes(mCheckedOptions, currentCheck),
      currM: formerCurrM,
      states: theStates,
      chords,
      indeterminate: false,
      checkAll: e.target.checked,
    };
    setMeasurementsState(newMState);
    setNewMarkersState(data, newMState);

    // messenger.call('changeParams', this, getArrayIndexes(mCheckedOptions, currentCheck));
    // messenger.call('changeParams', this, newMState);
  };

  const onCheckChange = (checkedList) => {
    if (checkedList && checkedList.length > 0) {
      const formerMState = measurementsState;
      const formerCurrM = formerMState.currM;
      let mCheckedOptions = getAllParamOptions(data, formerCurrM);

      const newMState = {
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < mCheckedOptions.length,
        currM: formerCurrM,
        states: theStates,
        chords,
        checkedListIndexes: getArrayIndexes(mCheckedOptions, checkedList),
        checkAll: checkedList.length === mCheckedOptions.length,
      };
      setMeasurementsState(newMState);
      setNewMarkersState(data, newMState);
      // messenger.call('changeParams', this, newMState);
    }
  };

  const filterContent = (
    <>
      <div className="divide-under">
        <Checkbox
          indeterminate={measurementsState.indeterminate}
          onChange={onCheckAllChange}
          checked={measurementsState.checkAll}
        >
          Check all
        </Checkbox>
      </div>
      <CheckboxGroup
        // options={getAllParamOptions(data, measurementsState.currM, true)}
        value={measurementsState.checkedList}
        onChange={onCheckChange}
      >
        <Row style={{ display: 'flex', flexWrap: 'no-wrap', overflowX: 'hidden' }}>
          {getAllParamOptions(data, measurementsState.currM, true).map((chb, i) => (
            <Col span={24} style={{ overflowX: 'hidden' }} key={i}>
              <Checkbox value={chb.value}>{chb.name}</Checkbox>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>
    </>
  );

  function getAllParamOptions(xdata = data, mType = measurementsState.currM, withLabels = false) {
    const allMsrementsOptions = getAllMOptions(xdata);
    if (allMsrementsOptions) {
      const pList = allMsrementsOptions.filter((xd) => xd['mType'] === mType);
      let paramLst = [];
      if (pList && pList[0]) paramLst = pList[0]['options'];

      if (withLabels) {
        let arrR = [];
        paramLst.forEach((xd) => arrR.push({ name: parseString4(parseString3(xd)), value: xd }));
        return arrR;
      }
      return paramLst;
    }
    return [];
  }

  function getMarkersTarget(data, measurementsState) {
    let markTgt = [];
    Object.keys(data).forEach((dt) => {
      let markAggregator = { state: dt, coordinates: getCoordinates(dt) };
      Object.keys(data[dt]).map((dt2) => {
        if (measurementsState.currM === dt2) {
          Object.keys(data[dt][dt2]).map((dt3) => {
            if (measurementsState.checkedList.includes(dt3)) {
              if (!markAggregator['count']) {
                markAggregator['count'] = data[dt][dt2][dt3][avgEnv];
                markAggregator['params'] = { [dt3]: { count: 1 } };
              } else if (markAggregator['count']) {
                markAggregator['count'] = markAggregator['count'] + data[dt][dt2][dt3][avgEnv];
                if (!markAggregator['params'][dt3]) markAggregator['params'][dt3] = { count: 1 };
                else if (markAggregator['params'][dt3])
                  markAggregator['params'][dt3]['count'] = markAggregator['params'][dt3]['count'];
              }
            }
          });
        }
      });
      markTgt.push(markAggregator);
    });
    return markTgt;
  }

  function getCoordinates(state) {
    const themChordsLat = chords[state];
    if (
      themChordsLat &&
      (themChordsLat.lat || themChordsLat.lat === 0) &&
      (themChordsLat.long || themChordsLat.long === 0)
    )
      return chords[state];
    return { lng: 0, lat: 0 };
  }

  function generateMarkers(map = map, mapData, markTgt) {
    let markers = [];
    let cIcon = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: '#ff4e7c',
      // fillColor: '#f3ca63',
      strokeColor: '#963535',
      scale: 1,
      strokeWeight: 0.5,
      fillOpacity: 1,
    };
    let scalingFactor = 20;
    if (markTgt && markTgt.length > 0) {
      const availData = markTgt.filter((mt) => mt.count && mt.count > 0);
      if (availData) {
        scalingFactor = availData.length;
        scalingFactor = availData.length * (10 / availData.length);
      }
      if (scalingFactor > 20) scalingFactor = 30;
    }
    const maxScale = Math.max.apply(0, markTgt.map((mk) => (mk.count ? mk.count : 0))) || 1;
    for (let i = 0; i < markTgt.length; i++) {
      let sttes = markTgt[i];

      if (sttes['count']) {
        // max scale set at 20.
        cIcon.scale = (Math.sqrt(sttes['count']) * scalingFactor) / Math.sqrt(maxScale);
        // cIcon.scale = (sttes['count'] * scalingFactor) / maxScale;
        let marker = new window.google.maps.Marker({
          position: {
            lat: getCoordinates(sttes.state).lat,
            lng: getCoordinates(sttes.state).long,
          },
          map: map,
          icon: cIcon,
          animation: window.google.maps.Animation.DROP,
          // title: `Violations count: ${sttes.count}`,
          zIndex: i + 1,
        });

        var contentString =
          '<div id="map-info-content" style="width:fit-content; text-align: center; height: 25px; font-size: 10px; font-family: var(--font-family); background-color:#fefefe; border:1px solid #fefefe; padding: 10px;">' +
          `${sttes.state}: ${sttes.count}` +
          '</div>';

        // let contentString2 = <MarkerContent params={sttes.params} />;

        let infoWindow = new window.google.maps.InfoWindow({
          content: contentString,
          // content: <MarkerContent params={sttes.params} />,
        });

        addEventListenersToMarker(map, marker, infoWindow);

        markers.push(marker);
      }
    }
    return markers;
  }

  return (
    <>
      <div className={`dashboard-content-box ${className}`}>
        <div className="box-item-title">
          <h3 className="subheading bold">
            {data && (
              <div
                className="dashboard-map-controls"
                style={{ display: 'flex', justifyContent: 'space-between', marginRight: '20px' }}
              >
                {/* <p style={{ fontSize: '12px' }}>Change Measurement Type:</p> */}
                <select id="selectbox" onChange={selectMType}>
                  {measurementOptions.map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>

                <Filter
                  enableClear={false}
                  content={filterContent}
                  isFiltering={false}
                  enableHeader={false}
                  customClassName="custom dashboard-map-filter"
                  custom={true}
                />
              </div>
            )}
          </h3>
        </div>
        <div className="box-item-canvas real-time-map">
          {loading && <LoadingContent modal={true} />}
          {!loading && !localError && data && (
            <>
              <div
                id="dashboardmap"
                ref={mapRef}
                role="application"
                aria-label="Google Maps"
                style={{ width: '100%', height: '110%', minHeight: height }}
              />
            </>
          )}
          {/* {!loading && !localError && !data && <LoadingContent modal={true} />} */}
          {!loading && localError && (
            <>
              <span>Oops, we encountered some problems!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const generateSize = ({ width = '400px', height = '600px' }) => {
  return `width: ${width};height: ${height};`;
  // return `height: ${height};`;
};

export default styled(DashboardMap)`
  // ${(props) => generateSize(props)};
  box-shadow: -1px 2px 8px -2px rgba(67, 90, 111, 0.2);
  // box-shadow: 0px 8px 13px 4px rgba(67, 90, 111, 0.2);
  // box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: var(--font-family);
  border-radius: 5px;

  .real-time-map {
    overflow: unset !important;
  }

  .box-item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    .filter-popover {
      .ant-dropdown-link {
        font-family: var(--font-family);
        font-weight: normal;
        font-size: 14px
      }
    }

    .subheading {
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 5px;

      font-size: 18px;
      font-family: var(--font-family-bold);

      .ant-radio-group {
        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #1c81ce;
            background-color: #1c81ce;
            ::after {
              background-color: white;
              width: 5.5px;
              left: 4px;
              top: 3.3px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .box-item-canvas {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 5px;    
    margin-bottom: 14px;
    height: calc(100% - 30px);

    @media (max-width: 1200px) {
      {
        ${(props) =>
          props.error || props.loading
            ? `height: calc(100% - 30px);`
            : `height: ${props.height}`};      
      }
    }
    width: 100%;
    font-family: var(--font-family);

    svg {
      .g-box {
        transform: translate(9rem, 11rem);
      }
      .g-legend {
        transform: translate(22rem, 15rem);
      }
      .legend {
            font-size: 12px;
            font-family: var(--font-family);
        text {
          font-size: 12px;
        }
        rect {
          stroke-width: 2;
        }
      }
      @media (max-width: 2480px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1655px) {
        {
          .g-legend {
            transform: translate(65%, 15rem);
          }
        }
      }
      @media (max-width: 1400px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }
      @media (max-width: 1240px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1200px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }      
    }
  }
  
`;
