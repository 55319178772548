import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import LoadingContent from '../../../../components/common/LoadingContent';

const SummaryCard = ({ data, loading, showDetails }) => {
  const { stats, text, detailsAvailable } = data;

  return (
    <>
      <div className="dashboard-summary-box box-item-stat">
        {loading.summaryStat ? (
          <LoadingContent modal={true} />
        ) : (
          <span className="">
            <h3 className="bold mb-5">{stats}</h3>
          </span>
        )}
      </div>
      <div className="dashboard-summary-box box-item-label">
        <p className="">{text}</p>
        {detailsAvailable && (
          <Tooltip title="See list">
            <span className="box-item-view" onClick={() => showDetails(data)}>
              <EyeOutlined />
            </span>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default styled(SummaryCard)``;
