import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GraphSimplePieChart from '../../../common/GraphSimplePieChart';
import GraphSimpleBarChart from '../../../common/GraphSimpleBarChart';
import { Col, Row, Select, Spin } from 'antd';
import { getDashboardSectorAnalyticsByLga, getLgaLookup } from '../../../../helpers/api';
import { LoadingOutlined } from '@ant-design/icons';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import MapForLocation from '../../../common/MapForLocation';

export default function LgaSectorAnalyticsRegulatorView({ stateId, lgaList = [] }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lgaData, setLgaData] = useState([]);
  const [loadingLgaData, setLoadingLgaData] = useState(false);
  const [selectedLgaId, setSelectedLgaId] = useState('');
  const [locationCoordinate, setLocationCoordinate] = useState({ lat: 6.5244, lng: 3.3792 });

  // //lagos
  // const locationCordinate = {
  //   lat: 6.5244,
  //   lng: 3.3792,
  // };

  useEffect(() => {
    fetchData();
  }, [stateId, selectedLgaId]);

  useEffect(() => {
    handleLgaList();
  }, [lgaList]);

  const getLocationCoordinate = () => {};

  const fetchData = () => {
    setLoading(true);
    getDashboardSectorAnalyticsByLga('', stateId, selectedLgaId)
      .then((response) => {
        setLoading(false);
        const responseData = response.result.data;
        const m = responseData?.map((item) => ({
          name: item.sectorName,
          count: item.facilityCount,
        }));
        setData(m);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const fetchLgaData = () => {
    setLoadingLgaData(true);
    getLgaLookup('', stateId, '')
      .then((response) => {
        setLoadingLgaData(false);
        const responseData = response.result.data;
        const m = responseData?.map((item) => ({
          label: item.lgaName,
          value: item.lgaId,
        }));
        setLgaData(m);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleLgaList = () => {
    if (lgaList.length > 0) {
      const m = lgaList?.map((item) => ({
        label: item.lgaName,
        value: item.lgaId,
      }));
      setLgaData(m);
    } else {
      fetchLgaData();
    }
  };

  const renderLgaDropdown = () => {
    if (loadingLgaData) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Loading LGAs
        </div>
      );
    }

    if (!loadingLgaData && (lgaData?.length > 0 || lgaList?.length > 0)) {
      return (
        <Select
          showSearch
          allowClear
          placeholder="All Lga"
          options={lgaData}
          style={{
            width: 200,
          }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={(lgaId) => {
            if (lgaId === undefined) setSelectedLgaId('');
            else setSelectedLgaId(lgaId);
          }}
        />
      );
    }
  };

  const handleDisplay = () => {
    if (loading) {
      return <LoaderSkeleton />;
    }

    if (!loading && data.length > 0) {
      return <GraphSimpleBarChart data={data} />;
    }
  };
  return (
    <Card>
      <Header>
        <Title>
          <TitlePrimary>Industry type by LGA</TitlePrimary>
          <TitleSecondary>No. of facilities according to LGA </TitleSecondary>
        </Title>
        <div>{renderLgaDropdown()}</div>
      </Header>
      <Row gutter={0}>
        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <br />
          <br />
          <MapForLocation locationCordinate={locationCoordinate} />
        </Col>
        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          {handleDisplay()}
        </Col>
      </Row>
    </Card>
  );
}

const Card = styled.div`
  border: 1px solid #c4c4c4;
  padding: 0.5rem;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
`;

const Title = styled.div`
  color: #898282;
  flex-grow: 1;
`;

const TitlePrimary = styled.div`
  color: #5f6368;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
`;

const TitleSecondary = styled.div`
  margin-top: -5px;
`;
