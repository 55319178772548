import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Checkbox, Form } from 'antd';
import { withRouter, Redirect } from 'react-router-dom';
import ModalHeader from './ModalHeader';
import Button from '../../../../components/common/Button';

import Input from '../../../../components/common/AntInput';
import Select from '../../../../components/common/Select';
import ConfirmModal from './ConfirmModal';
import { getPrivileges, createRole } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';
import LoadingContent from '../../../../components/common/LoadingContent';
import './CreateRole.css';

const { Option } = Select;
const CreateRoleModal = withRouter(({ className, close, open }) => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [visible, setVisible] = useState(false);

  let permissionsList = [];

  useEffect(() => {
    refreshPrivileges();
  }, []);

  const refreshPrivileges = () => {
    setLoading(true);
    getPrivileges()
      .then((res) => {
        setPermissions(res.result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Error getting permissions',
          message: error.message,
        });
      });
  };

  const onChange = (e) => {
    if (e.target.checked) {
      permissionsList.push(e.target.id);
    } else {
      const filteredList = permissionsList.filter((permission) => permission !== e.target.id);
      permissionsList = filteredList;
    }
    console.log(permissionsList);
  };

  const handleSubmit = (values) => {
    // ev.preventDefault();
    console.log(values);
    setErrorMessage('');
    setLoading(true);
    console.log(permissionsList, 'submit');
    if (permissionsList.length === 0) {
      setErrorMessage('You have to select at least one permission');
      setLoading(false);
      return openNotification({
        type: 'error',
        title: 'You need to select at least one privilege',
      });
    } else {
      const payload = {
        roleName: values.roleName,
        privilegeId: permissionsList,
        userCategory: values.userCategory,
        roleDescription: values.roleDescription,
        scope: 1,
      };
      console.log(payload, 'payload');
      createRole(payload)
        .then((res) => {
          setLoading(false);
          setVisible(true);
        })
        .catch((error) => {
          setLoading(false);
          return openNotification({
            type: 'error',
            title: 'Error Creating Role',
            message: error.message,
          });
        });
    }
  };

  const displayPermissions = () => {
    if (permissions.length > 0) {
      return permissions.map((Permission) => {
        return (
          <div className="permission-box" key={Permission.permission}>
            <h3 className="permission-name">{Permission.permission.toLowerCase()}</h3>
            {Permission.privilege.map((privilege) => {
              return (
                <Checkbox
                  key={privilege.privilegeId}
                  id={privilege.privilegeId}
                  onChange={onChange}
                  className="permissions__name"
                  style={{ display: 'flex' }}
                >
                  {privilege.privilegeName.toLowerCase()}
                </Checkbox>
              );
            })}
          </div>
        );
      });
    } else {
      return (
        <div>
          <LoadingContent modal={true} />
          <p className="loading-permissions" style={{ fontSize: '10px' }}>
            Loading Permissions <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
        </div>
      );
    }
  };

  const closeModal = () => {
    setVisible(false);
    window.location.reload();
  };

  return (
    <Modal
      className="create__role__modal"
      visible={open}
      onCancel={close}
      title={<ModalHeader closeModal={close} />}
      footer={false}
    >
      <div className="oncreaterole-modal">
        <ConfirmModal open={visible} close={closeModal} />
        <Form layout="vertical" className="create-role-form" onFinish={handleSubmit}>
          <Form.Item
            name="roleName"
            label="Role Name"
            rules={[{ required: true, message: 'please enter a role name' }]}
          >
            <Input placeholder="Enter Role Name" />
          </Form.Item>
          <Form.Item
            name="roleDescription"
            label="Role Description"
            rules={[{ required: true, message: 'please enter a brief role description' }]}
          >
            <Input placeholder="Enter description here" />
          </Form.Item>
          <Form.Item
            name="userCategory"
            label="User Category"
            rules={[{ required: true, message: 'please select a category' }]}
          >
            <Select className="mb-20">
              <Option value={2}>EDM</Option>
              <Option value={1}>REGULATOR</Option>
              <Option value={3}>CONSULTANT</Option>
            </Select>
          </Form.Item>
          <div className="permissions-box">
            <p className="error-text">{errorMessage}</p>
            <div className="permissions-container">{displayPermissions()}</div>
          </div>
          <div className="form-group buttons">
            <Row>
              <Col span={8}>
                <Button
                  appearance="default"
                  style={{ marginRight: '10px' }}
                  type="primary"
                  loading={loading}
                >
                  Save
                </Button>
                <Button type="secondary" onClick={close} disabled={loading}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Modal>
  );
});

export default CreateRoleModal;
