import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { getUserEmail } from '../../helpers/api';
import SignupForm from '../../components/screens/signup/SignupForm';
import NavBrandDark from '../../components/common/NavbarBrandDark';
// import BackgroundImage from '../../assets/img/left.jpg';
import BackgroundImage from '../../assets/img/signin_sm.jpg';
import ErrorNotFound from '../../components/screens/signup/NotFound';

const Signup = ({ className }) => {
  const [verified, setVerified] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const location = window.location.hash.split('=')[1];
    getUserEmail(location)
      .then((res) => {
        setVerified(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setVerified(false);

        // if (error.status.code === '02' && error.status.desc === 'NOT FOUND') {
        //   console.log(error.status.desc, '');
        //   setVerified(false);
        // }
      });
  }, []);
  const displaySignup = () => {
    if (verified === null) {
      return null;
    } else if (verified === false) {
      return <ErrorNotFound />;
    } else {
      return (
        <div className={className}>
          <div className="left-column">
            <div className="banner-bg">
              <header>
                <NavBrandDark />
              </header>
            </div>
          </div>
          <div className="right-column">
            <div className="container">
              <SignupForm className="signup__form" />
            </div>
          </div>
        </div>
      );
    }
  };
  return loading ? (
    <div className="signup__loader">
      <Spin />{' '}
    </div>
  ) : (
    displaySignup()
  );
};
export default styled(Signup)`
  width: 100%;
  display: flex;
  font-family: var(--font-family);
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
  .signup__loader {
    padding: 4rem 0;
  }
  .right-column,
  .left-column {
    width: 50%;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  .left-column {
    .banner-bg {
      background-image: url(${BackgroundImage});
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @media screen and (max-width: 768px) {
        background-position: center;
        button {
          display: none;
        }
        header {
          background: #transparent;
          padding: 1rem 0;
        }
      }
      @media screen and (max-width: 575px) {
        button {
          display: none;
        }
        background-image: none;
        header {
          background: #0052cc;
          padding: 1rem 0;
        }
      }
    }
  }
  .right-column {
    display: flex;
    justify-content: center;
    align-self: center;
    padding-top: 97px;
    @media screen and (max-width: 768px) {
      padding: 4rem 1rem;
    }
    @media screen and (max-width: 575px) {
      padding: 2rem 1rem;
    }
  }
  .container {
    width: 400px;
  }
  header {
    display: flex;
    align-items: center;
  }

  button {
    border-radius: 4px;
    width: 100%;
  }
`;
