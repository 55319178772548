import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './guides.css';
import './index.css';
import './scrollfix.css';
import AppContextProvider from './store/AppContext';

ReactDOM.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
  document.getElementById('root')
);
