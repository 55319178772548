import React from 'react';
import { Modal, Row, Col, Result } from 'antd';
import Button from '../../../components/common/Button';
import CheckIcon from '../../../assets/img/checkmark.svg';
import { properCase } from '../../../helpers/utils';
import './ForgotPassword.css';

const ConfirmResetModal = ({ className, open, close, message }) => {
  console.log(message, 'message');
  const sent =
    message &&
    message.trim().toLowerCase() ===
      'RESET LINK HAS BEEN SENT TO YOUR EMAIL ADDRESS. THE LINK WILL NO LONGER BE VALID AFTER 15 MINUTES'
      ? 'success'
      : 'warning';
  return (
    <Modal className="forgotpassword__confirm-modal" visible={open} footer={null}>
      <Result
        status={sent ? 'success' : 'warning'}
        className="reset-form-result"
        title={sent ? 'Reset Link Generated Successfully' : 'There are some problems'}
        subTitle={
          sent
            ? `If the email provided is in our records, a link to reset your password has been to it.`
            : typeof message === 'string'
            ? properCase(message)
            : 'Please check your submission or try again.'
        }
        extra={
          <>
            <Button type="primary" appearance="default" key="console" onClick={close}>
              {sent ? 'Close' : 'Try Again'}
            </Button>
          </>
        }
      />
    </Modal>
  );
};

export default ConfirmResetModal;
