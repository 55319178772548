import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Logs from '../components/screens/logs';

export default (props) => {
  return (
    <Layout headerTitle={'Reports'}>
      <PageContainer>
        <Logs props={props} id="reports" />
      </PageContainer>
    </Layout>
  );
};
