import React, { useState } from 'react';
import { Form, message } from 'antd';
import Input from '../../../../components/common/AntInput';
import Button from '../../../../components/common/Button';
import { createMeasurements } from '../../../../helpers/api';
import '../style.css';

const AddMeasurementForm = ({ closeModal, reload }) => {
  const [loading, setLoading] = useState(false);

  const handleMeasurements = (values) => {
    setLoading(true);
    const payload = {
      measurementName: values.measurementName.trim().toLowerCase(),
      parameters: [],
    };
    createMeasurements(payload)
      .then((res) => {
        setLoading(false);
        message.success(`New Measurement Type ${values.measurementName} created sucessfully!`);
        reload();
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };
  return (
    <Form layout="vertical" onFinish={handleMeasurements} hideRequiredMark={true}>
      <Form.Item
        name="measurementName"
        label="Measurement Name"
        rules={[{ required: true, message: 'Please enter a measurement name' }]}
      >
        <Input placeholder="Enter measurement name here" />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          appearance="default"
          className="mr-10"
          loading={loading}
        >
          Save
        </Button>
        <Button htmlType="button" type="secondary" onClick={closeModal} loading={loading}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddMeasurementForm;
