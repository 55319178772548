import React from 'react';
import { Table, Button } from 'antd';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const data = [
  {
    Discontinued: false,
    OrderDate: new Date('2012-02-12'),
    ProductID: 1,
    ProductName: 'Chai',
    QuantityPerUnit: '10 boxes x 20 bags',
    ReorderLevel: 10,
    UnitPrice: 18.0,
    UnitsInStock: 39,
  },
  {
    Discontinued: false,
    OrderDate: new Date('2003-03-17'),
    ProductID: 2,
    ProductName: 'Chang',
    QuantityPerUnit: '24 - 12 oz bottles',
    ReorderLevel: 25,
    UnitPrice: 19.0,
    UnitsInStock: 17,
  },
  {
    Discontinued: false,
    OrderDate: new Date('2006-03-17'),
    ProductID: 3,
    ProductName: 'Aniseed Syrup',
    QuantityPerUnit: '12 - 550 ml bottles',
    ReorderLevel: 25,
    UnitPrice: 10.0,
    UnitsInStock: 13,
  },
  {
    Discontinued: false,
    OrderDate: new Date('2016-03-17'),
    ProductID: 4,
    ProductName: 'Chef Antony Cajun Seasoning',
    QuantityPerUnit: '48 - 6 oz jars',
    ReorderLevel: 0,
    UnitPrice: 22.0,
    UnitsInStock: 53,
  },
  {
    Discontinued: true,
    OrderDate: new Date('2011-11-11'),
    ProductID: 5,
    ProductName: 'Chef Antony Gumbo Mix',
    QuantityPerUnit: '36 boxes',
    ReorderLevel: 0,
    UnitPrice: 21.35,
    UnitsInStock: 0,
  },
];

const columns = [
  {
    title: 'Product ID',
    dataIndex: 'ProductID',
    key: 'ProductID',
  },
  {
    title: 'Product Name',
    dataIndex: 'ProductName',
    key: 'ProductName',
  },
  {
    title: 'Quantity Per Unit',
    dataIndex: 'QuantityPerUnit',
    key: 'QuantityPerUnit',
  },
  {
    title: 'Unit Price',
    dataIndex: 'UnitPrice',
    key: 'UnitPrice',
  },
  {
    title: 'Units In Stock',
    dataIndex: 'UnitsInStock',
    key: 'UnitsInStock',
  },
  {
    title: 'Discontinued',
    dataIndex: 'Discontinued',
    key: 'Discontinued',
    render: (text) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Order Date',
    dataIndex: 'OrderDate',
    key: 'OrderDate',
    render: (text) => new Date(text).toLocaleDateString(),
  },
];

function Test() {
  const ExportToExcel = (data, sheetName, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // Generate XLSX file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Save to file
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);
  };

  const ExportToPDF = (data, title) => {
    const doc = new jsPDF();
    const tableColumn = [
      'Product ID',
      'Product Name',
      'Quantity Per Unit',
      'Unit Price',
      'Units In Stock',
      'Discontinued',
      'Order Date'
    ];

    const tableRows = [];

    data.forEach(record => {
      const rowData = [
        record.ProductID,
        record.ProductName,
        record.QuantityPerUnit,
        record.UnitPrice,
        record.UnitsInStock,
        record.Discontinued ? 'Yes' : 'No',
        new Date(record.OrderDate).toLocaleDateString(),
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.text(title, 14, 15);
    doc.save(`${title}.pdf`);
  };

  const handlePrint = () => {
    window.print();
  };
  return (
    <div>
      <Button
        type="primary"
        onClick={() => ExportToExcel(data, 'TableData', 'TableData.xlsx')}
        style={{ marginBottom: 16 }}
      >
        Export to Excel
      </Button>
      <Button
        type="primary"
        onClick={() => ExportToPDF(data, 'TableData')}
        style={{ marginBottom: 16, marginLeft: 16 }}
      >
        Export to PDF
      </Button>
      <Button
        type="primary"
        onClick={handlePrint}
        style={{ marginBottom: 16, marginLeft: 16 }}
      >
        Print
      </Button>
      <Table columns={columns} dataSource={data} rowKey="ProductID" />
    </div>
  );
}

export default Test;
