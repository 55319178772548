import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Home from '../components/screens/home';
import EdmDashboard from '../components/screens/dashboard/EdmDashboard';
import UserCategory from '../components/screens/users/UserCategory';
import RegulatorDashboard from '../components/screens/dashboard/RegulatorDashboard';

export default (props) => {
  const userCategory = props.userCategory;
  //console.log(userCategory)
  return (
    <Layout headerTitle="Dashboard">
      <PageContainer>
        {userCategory === 'EDM' && <EdmDashboard />}
        {userCategory === 'REGULATOR' && <RegulatorDashboard/>}
        {!UserCategory === 'EDM' && <Home props={props} id="dashboard" />}
      </PageContainer>
    </Layout>
  );
};
