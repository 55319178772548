import React, { useState, useEffect } from 'react';
import { message, Popover } from 'antd';
import Table from '../../common/Table';
import Button from '../../common/Button';
import LoadingContent from '../../common/LoadingContent';
import { getScheduleFacility, scheduleFacility } from '../../../helpers/api';
import { UnlockFilled } from '@ant-design/icons';

const currentYear = new Date().getFullYear();

const FirstQuarter = (props) => {
  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstQuarterFacilities, setFirstQuarterFacilities] = useState([]);
  const [stateFacilities, setStateFacilities] = useState(null);
  const [locking, setLocking] = useState(false);

  const userPerm = props.userPerm;
  const canScheduleQuarter = userPerm && userPerm?.includes('schedule_quarter');

  useEffect(() => {
    getFirstQuarterFacilities();
  }, []);

  useEffect(() => {
    if (props.stateFilter.key !== undefined && props.stateFilter.key !== 'all') {
      getFirstQuarterFacilities();
    }
  }, [props.stateFilter]);

  const getFirstQuarterFacilities = () => {
    setLoading(true);
    if (props.stateFilter.key !== undefined && props.stateFilter.value !== 'all') {
      getScheduleFacility(1, props.stateFilter.value)
        .then((res) => {
          setStateFacilities(res.result.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(error);
          setLoading(false);
        });
    } else {
      getScheduleFacility(1)
        .then((res) => {
          setFirstQuarterFacilities(res.result.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(error);
          setLoading(false);
        });
    }
  };

  const closeScheduleModal = () => {
    setScheduleModalVisible(false);
  };

  const openScheduleModal = () => {
    if (selectedFacilities.length === 0) {
      message.error('No facility has been selected to lock');
    } else {
      setScheduleModalVisible(true);
    }
  };

  const popMe = (address) => {
    return <p>{address}</p>;
  };

  const columns = [
    {
      title: 'Facility Name',
      dataIndex: 'facilityName',
      key: 'facilityName',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'State',
      dataIndex: 'stateName',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (address) => (
        <Popover
          placement="topLeft"
          title={'Address'}
          content={() => popMe(address)}
          trigger="hover"
          className="facility-table__popover address__popover"
        >
          <span>{address}</span>
        </Popover>
      ),
      ellipsis: true,
    },
    {
      title: 'Sample Points',
      dataIndex: 'samplePoints',
      render: (samplePoints) => <>{samplePoints.length} sample points</>,
    },
    {
      title: '',
      dataIndex: 'facilityId',
      render: (facilityId) => (
        <>
          {canScheduleQuarter && (
            <Button
              size="small"
              className="unlock__btn"
              onClick={(e) => handleUnlockFacilities(facilityId)}
              disabled={locking}
            >
              <UnlockFilled style={{ marginRight: '5px', color: '#fa6400' }} />
              Unlock Facility
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleUnlockFacilities = (id) => {
    const facility = [];
    facility.push(id);

    if (facility && facility.length > 0) {
      unlockFacility(facility);
    }
  };

  const unlockFacility = (facilityId) => {
    const payload = {
      quarter: 0,
      year: currentYear,
      facilityIds: facilityId,
    };
    scheduleFacility(payload)
      .then((res) => {
        message.success('Facility unlocked successfully');
        getFirstQuarterFacilities();
        props.reload(true);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const handleBulkUnlock = () => {
    setLocking(true);
    const payload = {
      quarter: 0,
      year: 2020,
      facilityIds: selectedFacilities,
    };
    scheduleFacility(payload)
      .then((res) => {
        message.success(res.result.status.desc);
        getFirstQuarterFacilities();
        props.reload(true);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  // const getFilteredData = () => {
  //   if (firstQuarterFacilities && firstQuarterFacilities.length > 0) {
  //     const filteredFacilities = firstQuarterFacilities.filter(
  //       (facility) => facility.stateName === props.stateFilter
  //     );
  //     if (filteredFacilities && filteredFacilities.length > 0) {
  //       setStateFacilities(filteredFacilities);
  //     } else {
  //       setStateFacilities([]);
  //     }
  //   }
  // };

  const keyedData = firstQuarterFacilities;
  keyedData.forEach((dt) => (dt['key'] = dt.facilityId));

  const keyedStateData = stateFacilities;
  keyedStateData &&
    keyedStateData !== null &&
    keyedStateData.forEach((dt) => (dt['key'] = dt.facilityId));

  const onSelectChange = (selectedFacility) => {
    setSelectedFacilities(selectedFacility);
  };

  const facilitySelection = {
    selectedFacilities,
    onChange: onSelectChange,
  };

  return (
    <div className="quarter__container">
      <div style={{ marginBottom: '1rem' }}>
        {selectedFacilities.length > 0 ? (
          <Button className="unlock__btn" onClick={handleBulkUnlock} disabled={locking}>
            <UnlockFilled style={{ marginRight: '5px' }} />
            Unlock Facilities
          </Button>
        ) : null}
      </div>
      {loading ? (
        <LoadingContent pageLoading={true} />
      ) : (
        <Table
          className="quarter__table"
          rowSelection={facilitySelection}
          columns={columns}
          paddedTable={true}
          dataSource={
            props.stateFilter.key !== undefined && props.stateFilter.value !== 'all'
              ? stateFacilities
              : keyedData
          }
          pagination={{
            showSizeChanger: true,
          }}
        />
      )}
    </div>
  );
};

export default FirstQuarter;
