export default function TestEmbbedTelerik() {
  return (
    <div style={{ marginTop: '-40px' }}>
      <div style={{ height: '100vh', width: '100%', border: 'none' }}>
        <iframe
          src={`${window.TELERIK_BASE_URL}/facilityreport`}
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Embedded Webpage"
        />
      </div>
    </div>
  );
}
