import React from 'react';
import styled from 'styled-components';
import LoginForm from '../../components/screens/signin/Form';
import NavBrandDark from '../../components/common/NavbarBrandDark';
import BackgroundImage from '../../assets/img/signin_sm.jpg';
import fmEnvBackground from '../../assets/img/fmenvlogo.jpg';
import config from '../../../package.json';

let buildVersion = '0.1';
if (config && config.version) buildVersion = config.version;

const StyledLoginPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
  .right-column,
  .left-column {
    width: 50%;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  .left-column {
    .banner-bg {
      background-image: url(${BackgroundImage});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      @media screen and (max-width: 768px) {
        background-position: center;
        button {
          display: none;
        }
        header {
          background: transparent;
          padding: 1rem 0;
        }
      }
      @media screen and (max-width: 575px) {
        button {
          display: none;
        }
        background-image: none;
        header {
          background: #0052cc;
          padding: 1rem 0;
        }
      }
    }
  }
  .right-column {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 100%;
    @media screen and (max-width: 768px) {
      padding: 4rem 1rem;
    }
    @media screen and (max-width: 575px) {
      padding: 4rem 1rem;
    }
  }
  .container {
    /* width: 400px; */
    /* background: url(${fmEnvBackground}) center 100% no-repeat; */
    /* background: url(${fmEnvBackground}) center 1% no-repeat;*/
    /* background-repeat: no-repeat; */
    /* background-size: center auto; */
    display: flex;
    height: 100%;
    form {
      margin: 2rem;
    }
    @media screen and (max-width: 768px) {
      background-image: none;
      background-position: center;
      button {
        display: none;
      }
      header {
        background: transparent;
        padding: 1rem 0;
      }
    }
    @media screen and (max-width: 575px) {
      button {
        display: none;
      }
      background-image: none;
      header {
        background: #0052cc;
        padding: 1rem 0;
      }
    }
  }
  header {
    display: flex;
    align-items: center;
  }

  button {
    border-radius: 4px;
    width: 100%;
  }
  .versioning {
    position: absolute;
    bottom: 10px;
    right: 2rem;
    color: var(--dark);
    font-size: 12px;
  }
`;

export default () => {
  return (
    <StyledLoginPage>
      <div className="left-column">
        <div className="banner-bg">
          <header>
            <NavBrandDark />
          </header>
        </div>
      </div>
      <div className="right-column">
        <div className="container">
          <LoginForm />
        </div>
        <div className="versioning">v{buildVersion}</div>
      </div>
      <main />
    </StyledLoginPage>
  );
};
