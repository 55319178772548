import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../../../components/common/CloseIcon';
import './CreateRole.css';

const ModalHeader = ({ className, closeModal }) => {
  return (
    <div className="create__role__modal-header">
      <span>
        <CloseIcon onClick={closeModal} />
      </span>
      <h3 className="heading">Create New Role</h3>
      <p className="headingText">Roles define what permissions are granted to accounts</p>
    </div>
  );
};

export default ModalHeader;
