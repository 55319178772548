import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Popconfirm, Result, Modal, Divider, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import isEqual from 'lodash.isequal';
import Table from '../../components/common/Table';
import Input from '../../components/common/AntInput';
import Select from '../../components/common/Select';
import Button from '../../components/common/Button';
import LoadingContent from '../../components/common/LoadingContent';
import { openNotification } from '../../helpers/notification';
import { properCase, isEmpty } from '../../helpers/utils';
import { getConsultants } from '../../helpers/api';
import history from '../../history';

const StepThree = ({
  className,
  state: initialFormState = {},
  initialState,
  updateMasterForm,
  setFormStatus,
  formStatus,
  cancelEditing,
}) => {
  const { stepThreeState } = initialFormState;
  const {
    consultants: initConsultants = [],
    personnels: facPersonnels = [],
    consultantsLists: initConsultantsLists = [],
  } = stepThreeState;

  const modfiedInitConsLists = [...initConsultants];
  if (initConsultants[0] && initConsultants[0].facilityName) {
    modfiedInitConsLists[0].facilityName = initialFormState['stepOneState']['facilityName'];
  }

  const [personnels, setPersonnels] = useState(facPersonnels);
  const [consultants, setConsultants] = useState(modfiedInitConsLists);
  const [consultantsLists, setConsultantsLists] = useState(initConsultantsLists);
  const [modal, setModal] = useState(formStatus && formStatus.type);

  const [form] = Form.useForm();
  const consultantRef = useRef();
  const personnelFormRef = useRef();

  const deleteFacilityPersonnel = (recordKey) => {
    const newState = personnels.filter((samp) => samp.key !== recordKey);
    setPersonnels(newState);
  };
  const deleteFacilityConsultant = (recordKey) => {
    const newState = consultants.filter((samp) => samp.key !== recordKey);
    consultantRef.current.resetFields();
    setConsultants(newState);
  };

  const columnsConsultants = [
    {
      title: 'Consultant Full Name',
      dataIndex: 'consultantFullName',
      ellipsis: true,
      width: '25%',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      render: (_, record) => (
        <span
          className={
            record.status && record.status.trim().toLowerCase() === 'active'
              ? 'accredited'
              : 'not-accredited'
          }
        >
          {properCase(record.status)}
        </span>
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return (
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => deleteFacilityConsultant(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <a href="/#">
              <DeleteOutlined className="icon-delete-row" />
            </a>
          </Popconfirm>
        );
      },
    },
  ];

  const columnsFac = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      width: '30%',
      ellipsis: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      ellipsis: true,
      // width: '30%',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: '15%',
      ellipsis: true,
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return (
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => deleteFacilityPersonnel(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <a href="/#">
              <DeleteOutlined className="icon-delete-row" />
            </a>
          </Popconfirm>
        );
      },
    },
  ];

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 14 },
  };

  const { Option } = Select;

  function getFacilityConsultantsLists() {
    getConsultants()
      .then(({ result }) => {
        const cLists = [];
        result.data &&
          result.data.length > 0 &&
          result.data.forEach((res) =>
            cLists.push({
              userId: res.userId,
              username: res.username,
              firstname: res.firstname,
              lastname: res.lastname,
              phone: res.phone,
              email: res.email,
              status: res.status,
              userCategory: res.userCategory,
              name: `${res.firstname} ${res.lastname} `,
              consultantFullName:
                res.lastname && res.firstname ? `${res.firstname} ${res.lastname}` : res.fullName,
              id: res.userId,
              text:
                res.lastname && res.firstname ? `${res.firstname} ${res.lastname}` : res.fullName,
            })
          );
        setConsultantsLists(cLists);
      })
      .catch((err) => {
        return openNotification({
          type: 'error',
          title: 'Failed to get consultants',
          message: `We encountered an error while trying to get list of consultants`,
        });
      });
  }

  useEffect(() => {
    getFacilityConsultantsLists();
    if (!formStatus) {
      setModal('');
    } else {
      setModal('loading');
      if (formStatus.type === 'success') {
        setModal('success');
      }
      if (formStatus.type === 'error') setModal('error');
    }
  }, [initialFormState, formStatus]);

  const hasStateUpdated = () => {
    const initialState2 = { ...initialState };
    initialState2['stepOneState']['regionLists'] = initialFormState['stepOneState']['regionLists'];
    initialState2['stepOneState']['sectorLists'] = initialFormState['stepOneState']['sectorLists'];
    initialState2['stepOneState']['statesLists'] = initialFormState['stepOneState']['statesLists'];
    initialState2['stepOneState']['lgaLists'] = initialFormState['stepOneState']['lgaLists'];

    return !(
      (
        isEqual(initialState2['stepOneState'], initialFormState['stepOneState']) &&
        isEqual(
          initialState['stepTwoState']['samplePoints'],
          initialFormState['stepTwoState']['samplePoints']
        ) &&
        isEqual(initialState['stepThreeState'], initialFormState['stepThreeState']) &&
        isEqual(initialFormState['stepThreeState']['consultants'], consultants) &&
        isEqual(initialFormState['stepThreeState']['personnels'], personnels)
      )

      // isEqual(initialFormState['stepThreeState']['consultants'], consultants) &&
      // isEqual(initialFormState['stepThreeState']['personnels'], personnels)
    );
  };

  const updateHasHappened = hasStateUpdated();

  const updateFacility = () => {
    if (!updateHasHappened) return cancelEditing();
    const GOTONEXT = 3;
    try {
      const stepThreePayload = {
        consultants,
        personnels,
      };
      updateMasterForm(stepThreePayload, 'stepThreeState', GOTONEXT);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  const saveAndPrev = () => {
    const GOTOPREV = 1;
    try {
      const stepThreePayload = {
        consultants,
        personnels,
        consultantsLists,
      };
      updateMasterForm(stepThreePayload, 'stepThreeState', GOTOPREV);
    } catch (e) {
      // console.log('Something went wrong', e);
    }
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };

  const addConsultant = (record) => {
    const cl = consultantsLists.filter((cls) => cls.id === record.key);
    const existing = consultants.filter((cls) => cls.id === record.key);

    let getAGoodKey = consultants.length;
    if (consultants.length) {
      if (!isEmpty(consultants.filter((cl) => cl.key === getAGoodKey))) {
        let intKeys = consultants.filter((cl) => !isNaN(cl.key));
        if (!isEmpty(intKeys)) getAGoodKey = Math.max(...intKeys.map((cl) => cl.key)) + 1;
      }
    }

    if (cl.length > 0 && !existing[0]) {
      const newConsultant = {
        ...cl[0],
        key: getAGoodKey,
        facilityName: initialFormState.stepOneState.facilityName || 'Sample Facility',
        consultantFullName: record.children || record.consultantFullName,
        name: record.children || record.consultantFullName,
        text: record.children || record.consultantFullName,
      };

      // consultantRef.current.setFieldsValue({ consultantFullName: '' });
      consultantRef.current.resetFields();

      setConsultants([...consultants, newConsultant]);
      message.success('Facility consultant added');
    }
  };

  const addPersonnel = (record) => {
    const currPersonnel = personnels;
    if (
      personnels.some(
        (personel) => personel.email.trim().toLowerCase() === record.email.trim().toLowerCase()
      )
    ) {
      message.error('Failed to add record. Email already exists');
      return;
    }

    let getAGoodKey = personnels.length;
    if (personnels.length) {
      if (!isEmpty(personnels.filter((cl) => cl.key === getAGoodKey))) {
        let intKeys = personnels.filter((cl) => !isNaN(cl.key));
        if (!isEmpty(intKeys)) getAGoodKey = Math.max(...intKeys.map((cl) => cl.key)) + 1;
      }
    }

    const newPersonnel = {
      key: getAGoodKey,
      fullName: properCase(record.fullName),
      email: record.email.trim().toLowerCase(),
      phone: record.phone ? `+${record.prefix}${record.phone}` : null,
      designation: properCase(record.designation),
    };

    currPersonnel.push(newPersonnel);

    setPersonnels([]);

    personnelFormRef.current.setFieldsValue({ fullName: '' });
    personnelFormRef.current.setFieldsValue({ email: '' });
    personnelFormRef.current.setFieldsValue({ phone: '' });
    personnelFormRef.current.setFieldsValue({ designation: '' });
    message.success('Facility personnel added');
    setPersonnels(currPersonnel);
  };

  const displayOptions = (list) => {
    let key = list;
    let { [key]: name } = { consultantsLists };

    if (name.length > 0) {
      return name.map((item) => {
        return (
          <Option value={item.id} key={item.id}>
            {item.text}
          </Option>
        );
      });
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle className="form-control-addon">
      <Select>
        <Option value="234">+234</Option>
        <Option value="233">+233</Option>
        {Array.from({ length: 1000 }, (v, k) => k).map(
          (vl) =>
            vl !== 234 &&
            vl !== 233 && (
              <Option value={`${String(vl).padStart(3, '0')}`} key={vl}>{`+${String(vl).padStart(
                3,
                '0'
              )}`}</Option>
            )
        )}
      </Select>
    </Form.Item>
  );

  const formSuccess = () => {
    history.push('/#/facilities');
    history.go('/#/facilities');
  };

  const formError = () => {
    // setModal('');
    setFormStatus('');
  };

  function formResult(status) {
    if (status && status.type === 'loading') return <LoadingContent modal={true} />;
    const passed = status && status.type === 'success';
    const { stepOneState, stepTwoState } = initialFormState;
    const faciltName = stepOneState.facilityName || '{Facility Name}';
    const noOfPoints = stepTwoState.samplePoints ? stepTwoState.samplePoints.length : '0';
    return (
      <Result
        status={passed ? 'success' : 'warning'}
        className="facility-form-result"
        title={passed ? 'Facility Updated' : 'There are some problems'}
        subTitle={
          passed
            ? `${faciltName} with ${noOfPoints} ${
                noOfPoints > 0 ? 'sample points' : 'sample point'
              } has been updated`
            : typeof status.msg === 'string'
            ? properCase(status.msg)
            : 'Please check your submission or try again.'
        }
        extra={
          <>
            <Button
              type="primary"
              appearance="default"
              key="console"
              onClick={passed ? formSuccess : formError}
            >
              {passed ? 'Go To Facilities' : 'Back to Update Facility'}
            </Button>
          </>
        }
      />
    );
  }

  return (
    <>
      <Form
        className={`${className} step-form`}
        {...formItemLayout}
        layout="vertical"
        ref={consultantRef}
      >
        <span className="step-3-header">Consultant</span>
        <Row gutter={10}>
          {' '}
          <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="consultantFullName"
              label="Facility Consultant"
              rules={[{ required: true, message: 'Facility Consultant is required!' }]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Select
                placeholder="Select Facility Consultant"
                className="form-controlxx"
                onChange={(e, record) => addConsultant(record)}
              >
                {displayOptions('consultantsLists')}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form
        className={`${className} step-form step-form-table`}
        {...formItemLayout}
        layout="horizontal"
      >
        <Row gutter={10}>
          <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Table columns={columnsConsultants} dataSource={consultants} scroll={{ y: 200 }} />
          </Col>
        </Row>
      </Form>

      <br />
      <br />

      <Form
        className={`${className} step-form`}
        {...formItemLayout}
        form={form}
        ref={personnelFormRef}
        layout="vertical"
        initialValues={{
          prefix: '234',
        }}
        scrollToFirstError
        onFinish={addPersonnel}
        onFinishFailed={onFinishFailed}
      >
        <span className="step-3-header">Facility Personnel</span>
        <Row gutter={10}>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="fullName"
              label="Full Name"
              rules={[{ required: true, message: 'Personnel name is required!' }]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Input
                placeholder="Enter Full Name"
                className="form-controlx"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not a valid email!',
                },
                {
                  required: true,
                  message: 'Personnel email is required!',
                },
              ]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Input placeholder="Enter Email Address" className="form-controlx" />
            </Form.Item>
          </Col>

          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                () => ({
                  validator(rule, value) {
                    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                    if (!value || (value && regex.test(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The input is not a valid phone number!');
                  },
                }),
                // {
                //   required: true,
                //   message: 'Personnel phone number is required!',
                // },
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                placeholder="Enter Phone Number"
                className="form-controlx input-addon"
                style={{ width: '97%' }}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="designation"
              label="Designation"
              rules={[{ required: true, message: 'Personnel designation is required!' }]}
              style={{ marginBottom: 0, display: 'block' }}
            >
              <Input
                placeholder="Enter Personnel Designation"
                className="form-controlx"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              {...tailLayout}
              style={{ marginTop: '1em', marginBottom: '1em' }}
              shouldUpdate={(prevValues, currentValues) => prevValues.email !== currentValues.email}
            >
              {({ getFieldValue }) => {
                return (
                  <Button
                    type="primary"
                    appearance="default"
                    htmlType="submit"
                    disabled={
                      !getFieldValue('fullName') || (formStatus && formStatus.type === 'loading')
                    }
                  >
                    Add Contact Person
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Form
        className={`${className} step-form step-form-table`}
        {...formItemLayout}
        layout="horizontal"
      >
        {/* <Row> */}
        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Table columns={columnsFac} dataSource={personnels} scroll={{ y: 200 }} />
        </Col>
      </Form>
      <Form
        className={`${className} step-form`}
        {...formItemLayout}
        layout="horizontal"
        onFinish={updateFacility}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          {...tailLayout}
          style={{ marginTop: '1rem', marginBottom: '2em', display: 'block' }}
        >
          <Button type="secondary" onClick={saveAndPrev} disabled={formStatus === 'loading'}>
            Back
          </Button>
          <Button
            type="primary"
            appearance={updateHasHappened ? 'default' : 'danger'}
            htmlType="submit"
            loading={formStatus === 'loading'}
            style={{ margin: 8 }}
            disabled={personnels.length === 0 || (formStatus && formStatus === 'loading')}
          >
            {/* {formStatus === 'loading' ? <Spin /> : 'Update Facility'} */}
            {updateHasHappened ? 'Update Facility' : 'Cancel'}
            {/* Update Facility */}
          </Button>
        </Form.Item>
      </Form>
      {modal && (
        <Modal visible={true} footer={null}>
          {formResult(formStatus)}
        </Modal>
      )}
    </>
  );
};

export default styled(StepThree)`
  min-width: 735px !important;
  max-width: 90% !important;
  max-height: max-content;
  overflow-y: hidden;
  border-radius: 5px;

  .ant-modal-header {
    padding: 27px 40px 29px !important;
  }

  .ant-modal-body {
    padding: 27px 40px !important;
    max-height: 700px;
    overflow-y: auto;
  }

  .ant-modal-body,
  .ant-collapse {
    background-color: #fff;
  }
  label {
    font-family: var(--font-family);
    font-size: var(--form-label-fs);
    font-weight: var(--form-label-fw);
    margin-bottom: var(--form-label-mb);
    letter-spacing: normal;
    color: var(--dark);
  }
  .pl-3 {
    padding-left: 19px;
  }
  .ant-select-selection {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-select-selection--single {
    height: 41px;
  }
  .ant-select-selection__rendered {
    margin-top: 3px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .form-control {
    width: 100%;
    height: 41px;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(245, 247, 255, 0.2);
    &:focus {
      outline: 0;
      border: 0.5px solid #007ace;
    }
  }
`;
